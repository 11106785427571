const BRANCH_ACTION_TYPES = {
  RESET_BRANCH_REDUCER: "branch/RESET_BRANCH_REDUCER",

  SET_BRANCHES: "branch/SET_BRANCHES",
  SET_BRANCH: "branch/SET_BRANCH",

  SET_IS_BRANCHES_HAS_MORE: "branch/SET_IS_BRANCHES_HAS_MORE",

  SET_FETCH_BRANCHES_SEARCH: "branch/SET_FETCH_BRANCHES_SEARCH",
  SET_FETCH_BRANCHES_KEY_BY: "branch/SET_FETCH_BRANCHES_KEY_BY",
  SET_FETCH_BRANCHES_PAGE: "branch/SET_FETCH_BRANCHES_PAGE",
  SET_FETCH_BRANCHES_PER_PAGE: "branch/SET_FETCH_BRANCHES_PER_PAGE",
  SET_FETCH_BRANCHES_INCLUDES: "branch/SET_FETCH_BRANCHES_INCLUDES",
  SET_FETCH_BRANCHES_FILTER_MARKET_ID:
    "branch/SET_FETCH_BRANCHES_FILTER_MARKET_ID",
  SET_FETCH_BRANCHES_FILTER_IS_ACTIVE:
    "branch/SET_FETCH_BRANCHES_FILTER_IS_ACTIVE",
  SET_FETCH_BRANCHES_LOADING: "branch/SET_FETCH_BRANCHES_LOADING",
  SET_FETCH_BRANCHES_SUCCESS: "branch/SET_FETCH_BRANCHES_SUCCESS",
  SET_FETCH_BRANCHES_FAILED: "branch/SET_FETCH_BRANCHES_FAILED",

  SET_FETCH_BRANCH_LOADING: "branch/SET_FETCH_BRANCH_LOADING",
  SET_FETCH_BRANCH_SUCCESS: "branch/SET_FETCH_BRANCH_SUCCESS",
  SET_FETCH_BRANCH_FAILED: "branch/SET_FETCH_BRANCH_FAILED",

  APPEND_BRANCHES: "branch/APPEND_BRANCHES",

  SET_IS_FETCH_BRANCHES_HITTED: "branch/SET_IS_FETCH_BRANCHES_HITTED",
  SET_IS_FETCH_BRANCH_HITTED: "branch/SET_IS_FETCH_BRANCH_HITTED",

  FETCH_BRANCHES_START: "branch/FETCH_BRANCHES_START",
  FETCH_BRANCH_START: "branch/FETCH_BRANCH_START",
};

export default BRANCH_ACTION_TYPES;
