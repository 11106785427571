import { DISCOUNT_TYPES, DISCOUNT_VALUE_TYPES } from "../../constants/discount.constant";

import DISCOUNT_ACTION_TYPES from "./discount.type";

export const DISCOUNT_INITIAL_STATE = {
  discountTypes: [
    {
      singularLabel: "Coupon",
      pluralLabel: "Coupons",
      value: DISCOUNT_TYPES.COUPON,
    },
    {
      singularLabel: "Offer",
      pluralLabel: "Offers",
      value: DISCOUNT_TYPES.OFFER,
    },
    {
      singularLabel: "Promotion",
      pluralLabel: "Promotions",
      value: DISCOUNT_TYPES.PROMOTION,
    },
  ],
  discountValueTypes: [
    {
      label: "Percentage %",
      value: DISCOUNT_VALUE_TYPES.PERCENT,
    },
    {
      label: "Fixed Price",
      value: DISCOUNT_VALUE_TYPES.NOMINAL,
    },
  ],
  discountTab: DISCOUNT_TYPES.COUPON,
  discounts: [],
  discount: null,
  verifyCouponDiscount: null,
  isDiscountsHasMore: true,
  fetchDiscountsSearch: null,
  fetchDiscountsPage: 1,
  fetchDiscountsPerPage: null,
  fetchDiscountsIncludes: null,
  fetchDiscountsFilterCustomerId: null,
  fetchDiscountsFilterTypes: null,
  fetchDiscountsFilterExpiredAt: null,
  fetchDiscountsLoading: false,
  fetchDiscountsSuccess: null,
  fetchDiscountsFailed: null,
  fetchDiscountLoading: false,
  fetchDiscountSuccess: null,
  fetchDiscountFailed: null,
  verifyCouponDiscountLoading: false,
  verifyCouponDiscountSuccess: null,
  verifyCouponDiscountFailed: null,
};

export const discountReducer = (state = DISCOUNT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT_TAB:
      return { ...state, discountTab: payload };
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNTS:
      return { ...state, discounts: payload };
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT:
      return { ...state, discount: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT:
      return { ...state, verifyCouponDiscount: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_DISCOUNTS_HAS_MORE:
      return { ...state, isDiscountsHasMore: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SEARCH:
      return { ...state, fetchDiscountsSearch: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PAGE:
      return { ...state, fetchDiscountsPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PER_PAGE:
      return { ...state, fetchDiscountsPerPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_INCLUDES:
      return { ...state, fetchDiscountsIncludes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_CUSTOMER_ID:
      return { ...state, fetchDiscountsFilterCustomerId: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_TYPES:
      return { ...state, fetchDiscountsFilterTypes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT:
      return { ...state, fetchDiscountsFilterExpiredAt: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_LOADING:
      return { ...state, fetchDiscountsLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SUCCESS:
      return { ...state, fetchDiscountsSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FAILED:
      return { ...state, fetchDiscountsFailed: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_LOADING:
      return { ...state, fetchDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_SUCCESS:
      return { ...state, fetchDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_FAILED:
      return { ...state, fetchDiscountFailed: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_LOADING:
      return { ...state, verifyCouponDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_SUCCESS:
      return { ...state, verifyCouponDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_FAILED:
      return { ...state, verifyCouponDiscountFailed: payload };
    case DISCOUNT_ACTION_TYPES.APPEND_DISCOUNTS:
      return { ...state, discounts: [...state.discounts, ...payload] };
    case DISCOUNT_ACTION_TYPES.RESET_DISCOUNT_REDUCER:
      return DISCOUNT_INITIAL_STATE;
    default:
      return state;
  }
};
