const MARKET_ACTION_TYPES = {
  RESET_MARKET_REDUCER: "market/RESET_MARKET_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "market/SET_IS_MODAL_AUTO_CLOSE",
  SET_MARKETS: "market/SET_MARKETS",
  SET_MARKET: "market/SET_MARKET",

  SET_IS_MARKETS_HAS_MORE: "market/SET_IS_MARKETS_HAS_MORE",

  SET_FETCH_MARKETS_SEARCH: "market/SET_FETCH_MARKETS_SEARCH",
  SET_FETCH_MARKETS_PAGE: "market/SET_FETCH_MARKETS_PAGE",
  SET_FETCH_MARKETS_PER_PAGE: "market/SET_FETCH_MARKETS_PER_PAGE",
  SET_FETCH_MARKETS_INCLUDES: "market/SET_FETCH_MARKETS_INCLUDES",
  SET_FETCH_MARKETS_FILTER_MARKET_ID: "market/SET_FETCH_MARKETS_FILTER_MARKET_ID",
  SET_FETCH_MARKETS_LOADING: "market/SET_FETCH_MARKETS_LOADING",
  SET_FETCH_MARKETS_SUCCESS: "market/SET_FETCH_MARKETS_SUCCESS",
  SET_FETCH_MARKETS_FAILED: "market/SET_FETCH_MARKETS_FAILED",

  SET_FETCH_MARKET_LOADING: "market/SET_FETCH_MARKET_LOADING",
  SET_FETCH_MARKET_SUCCESS: "market/SET_FETCH_MARKET_SUCCESS",
  SET_FETCH_MARKET_FAILED: "market/SET_FETCH_MARKET_FAILED",

  APPEND_MARKETS: "market/APPEND_MARKETS",

  FETCH_MARKETS_START: "market/FETCH_MARKETS_START",
  FETCH_MARKET_START: "market/FETCH_MARKET_START",
};

export default MARKET_ACTION_TYPES;
