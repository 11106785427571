const ORDER_PRODUCT_RATING_ACTION_TYPES = {
  RESET_ORDER_PRODUCT_RATING_REDUCER: "order-product-rating/RESET_ORDER_PRODUCT_RATING_REDUCER",

  SET_ORDER_PRODUCT_RATINGS: "order-product-rating/SET_ORDER_PRODUCT_RATINGS",

  SET_IS_ORDER_PRODUCT_RATINGS_HAS_MORE: "order-product-rating/SET_IS_ORDER_PRODUCT_RATINGS_HAS_MORE",

  SET_FETCH_ORDER_PRODUCT_RATINGS_SEARCH: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_SEARCH",
  SET_FETCH_ORDER_PRODUCT_RATINGS_SORT: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_SORT",
  SET_FETCH_ORDER_PRODUCT_RATINGS_PAGE: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_PAGE",
  SET_FETCH_ORDER_PRODUCT_RATINGS_PER_PAGE: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_PER_PAGE",
  SET_FETCH_ORDER_PRODUCT_RATINGS_INCLUDES: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_INCLUDES",
  SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_ORDER_ID:
    "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_ORDER_ID",
  SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_PRODUCT_ID:
    "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_PRODUCT_ID",
  SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_RATING: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_RATING",
  SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_IS_SHOW: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_IS_SHOW",
  SET_FETCH_ORDER_PRODUCT_RATINGS_LOADING: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_LOADING",
  SET_FETCH_ORDER_PRODUCT_RATINGS_SUCCESS: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_SUCCESS",
  SET_FETCH_ORDER_PRODUCT_RATINGS_FAILED: "order-product-rating/SET_FETCH_ORDER_PRODUCT_RATINGS_FAILED",

  SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_LOADING:
    "order-product-rating/SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_LOADING",
  SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_SUCCESS:
    "order-product-rating/SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_SUCCESS",
  SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_FAILED:
    "order-product-rating/SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_FAILED",

  APPEND_ORDER_PRODUCT_RATINGS: "order-product-rating/APPEND_ORDER_PRODUCT_RATINGS",

  FETCH_ORDER_PRODUCT_RATINGS_START: "order-product-rating/FETCH_ORDER_PRODUCT_RATINGS_START",

  CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_START: "order-product-rating/CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_START",
};

export default ORDER_PRODUCT_RATING_ACTION_TYPES;
