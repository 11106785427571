import { useCallback, useRef } from "react";

import { getLazyRetry, setLazyRetry } from "./storage.utils";

export const useCombinedRefs = (...refs) => {
  return useCallback((node) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  }, refs);
};

export const useIntersectionObserver = (onIntersect) => {
  const observer = useRef(null);
  const observe = (node) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) onIntersect?.();
    });

    if (node) observer.current.observe(node);
  };

  return observe;
};

export const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(getLazyRetry() || "false");
    componentImport()
      .then((component) => {
        setLazyRetry("false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          setLazyRetry("true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};
