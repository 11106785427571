import { takeLatest, put, all, call, select } from "redux-saga/effects";

import BRANCH_ACTION_TYPES from "./branch.type";

import {
  appendBranches,
  setBranch,
  setBranches,
  setFetchBranchesFailed,
  setFetchBranchesLoading,
  setFetchBranchesSuccess,
  setFetchBranchFailed,
  setFetchBranchLoading,
  setFetchBranchSuccess,
  setIsBranchesHasMore,
  setIsFetchBranchesHitted,
  setIsFetchBranchHitted,
} from "./branch.action";
import {
  getFetchBranchesFilterIsActive,
  getFetchBranchesFilterMarketId,
  getFetchBranchesIncludes,
  getFetchBranchesKeyBy,
  getFetchBranchesPage,
  getFetchBranchesPerPage,
  getFetchBranchesSearch,
} from "./branch.selector";

import { getBranches, getBranch } from "../../api/branch.api";

export function* _getBranches() {
  try {
    yield put(setFetchBranchesLoading(true));

    const search = yield select(getFetchBranchesSearch);
    const key_by = yield select(getFetchBranchesKeyBy);
    const page = yield select(getFetchBranchesPage);
    const per_page = yield select(getFetchBranchesPerPage);
    const includes = yield select(getFetchBranchesIncludes);
    const market_id = yield select(getFetchBranchesFilterMarketId);
    const is_active = yield select(getFetchBranchesFilterIsActive);

    const parameters = {
      search,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchBranchesHitted(true));
    yield put(setIsBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendBranches(branches));
    } else {
      yield put(setBranches(branches));
    }

    yield put(setFetchBranchesSuccess(message));
    yield put(setFetchBranchesLoading(false));
  } catch (error) {
    yield put(setFetchBranchesFailed(error));
    yield put(setFetchBranchesLoading(false));
  }
}
export function* _getBranch({ payload: branchId }) {
  try {
    yield put(setFetchBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchBranchHitted(true));
    yield put(setBranch(branch));

    yield put(setFetchBranchSuccess(message));
    yield put(setFetchBranchLoading(false));
  } catch (error) {
    yield put(setFetchBranchFailed(error));
    yield put(setFetchBranchLoading(false));
  }
}

export function* onFetchBranchesStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START, _getBranches);
}
export function* onFetchBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, _getBranch);
}

export function* branchSaga() {
  yield all([call(onFetchBranchesStart), call(onFetchBranchStart)]);
}
