import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";

import {
  appendPaymentMethodSettings,
  setFetchPaymentMethodSettingsFailed,
  setFetchPaymentMethodSettingsLoading,
  setFetchPaymentMethodSettingsSuccess,
  setIsPaymentMethodSettingsHasMore,
  setPaymentMethodSettings,
} from "./payment-method-setting.action";
import {
  getFetchPaymentMethodSettingsFilterIsActive,
  getFetchPaymentMethodSettingsFilterIsPriority,
  getFetchPaymentMethodSettingsFilterMarketId,
  getFetchPaymentMethodSettingsFilterPaymentMethodKeys,
  getFetchPaymentMethodSettingsIncludes,
  getFetchPaymentMethodSettingsKeyBy,
  getFetchPaymentMethodSettingsPage,
  getFetchPaymentMethodSettingsPerPage,
  getFetchPaymentMethodSettingsSearch,
  getFetchPaymentMethodSettingsSort,
} from "./payment-method-setting.selector";

import { getPaymentMethodSettings } from "../../api/payment-method-setting.api";

export function* _getPaymentMethodSettings() {
  try {
    yield put(setFetchPaymentMethodSettingsLoading(true));

    const search = yield select(getFetchPaymentMethodSettingsSearch);
    const sort = yield select(getFetchPaymentMethodSettingsSort);
    const key_by = yield select(getFetchPaymentMethodSettingsKeyBy);
    const page = yield select(getFetchPaymentMethodSettingsPage);
    const per_page = yield select(getFetchPaymentMethodSettingsPerPage);
    const includes = yield select(getFetchPaymentMethodSettingsIncludes);
    const market_id = yield select(getFetchPaymentMethodSettingsFilterMarketId);
    const payment_method_keys = yield select(
      getFetchPaymentMethodSettingsFilterPaymentMethodKeys
    );
    const is_active = yield select(getFetchPaymentMethodSettingsFilterIsActive);
    const is_priority = yield select(
      getFetchPaymentMethodSettingsFilterIsPriority
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        payment_method_keys,
        is_active,
        is_priority,
      },
    };

    const {
      meta: { message },
      data: paymentMethodSettings,
    } = yield call(getPaymentMethodSettings, parameters);

    yield put(
      setIsPaymentMethodSettingsHasMore(
        Object.keys(paymentMethodSettings).length > 0
      )
    );

    if (page > 1) {
      yield put(appendPaymentMethodSettings(paymentMethodSettings));
    } else {
      yield put(setPaymentMethodSettings(paymentMethodSettings));
    }

    yield put(setFetchPaymentMethodSettingsSuccess(message));
    yield put(setFetchPaymentMethodSettingsLoading(false));
  } catch (error) {
    yield put(setFetchPaymentMethodSettingsFailed(error));
    yield put(setFetchPaymentMethodSettingsLoading(false));
  }
}

export function* onFetchPaymentMethodSettingsStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTINGS_START,
    _getPaymentMethodSettings
  );
}

export function* paymentMethodSettingSaga() {
  yield all([call(onFetchPaymentMethodSettingsStart)]);
}
