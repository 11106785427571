import apiService from "./api";

export const calculateOrder = async (request) =>
  (await apiService.post("/v2/public/orders/calc", request)).data;

export const getOrders = async (parameters) =>
  (await apiService.get("/v2/orders", parameters)).data;
export const getOrder = async (orderId) =>
  (await apiService.get(`/v2/orders/${orderId}`)).data;
export const createOrder = async (request) =>
  (await apiService.post("/v2/orders", request)).data;
export const updateOrder = async (orderId, request) =>
  (await apiService.post(`/v2/orders/${orderId}`, request)).data;
export const deleteOrder = async (orderId) =>
  (await apiService.delete(`/v2/orders/${orderId}`)).data;
