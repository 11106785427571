import { takeLatest, put, all, call, select } from "redux-saga/effects";

import TABLE_ACTION_TYPES from "./table.type";

import {
  setFetchTableByCodeFailed,
  setFetchTableByCodeLoading,
  setFetchTableByCodeSuccess,
  setFetchTableFailed,
  setFetchTableLoading,
  setFetchTableSuccess,
  setIsFetchTableByCodeHitted,
  setIsFetchTableHitted,
  setTable,
  setTableByCode,
} from "./table.action";
import {
  getFetchTableByCodeBranchId,
  getFetchTableByCodeMarketId,
} from "./table.selector";

import { getTable, getTableByCode } from "../../api/table.api";

export function* _getTable({ payload: tableId }) {
  try {
    yield put(setFetchTableLoading(true));

    const {
      meta: { message },
      data: table,
    } = yield call(getTable, tableId);

    yield put(setIsFetchTableHitted(true));
    yield put(setTable(table));

    yield put(setFetchTableSuccess(message));
    yield put(setFetchTableLoading(false));
  } catch (error) {
    yield put(setFetchTableFailed(error));
    yield put(setFetchTableLoading(false));
  }
}
export function* _getTableByCode({ payload: tableCode }) {
  try {
    yield put(setFetchTableByCodeLoading(true));

    const market_id = yield select(getFetchTableByCodeMarketId);
    const branch_id = yield select(getFetchTableByCodeBranchId);

    const parameters = { market_id, branch_id };

    const {
      meta: { message },
      data: table,
    } = yield call(getTableByCode, tableCode, parameters);

    yield put(setIsFetchTableByCodeHitted(true));
    yield put(setTableByCode(table));

    yield put(setFetchTableByCodeSuccess(message));
    yield put(setFetchTableByCodeLoading(false));
  } catch (error) {
    yield put(setFetchTableByCodeFailed(error));
    yield put(setFetchTableByCodeLoading(false));
  }
}

export function* onFetchTableStart() {
  yield takeLatest(TABLE_ACTION_TYPES.FETCH_TABLE_START, _getTable);
}
export function* onFetchTableByCodeStart() {
  yield takeLatest(
    TABLE_ACTION_TYPES.FETCH_TABLE_BY_CODE_START,
    _getTableByCode
  );
}

export function* tableSaga() {
  yield all([call(onFetchTableStart), call(onFetchTableByCodeStart)]);
}
