import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

import {
  appendProductCategories,
  setFetchProductCategoriesFailed,
  setFetchProductCategoriesLoading,
  setFetchProductCategoriesSuccess,
  setFetchProductCategoryFailed,
  setFetchProductCategoryLoading,
  setFetchProductCategorySuccess,
  setIsProductCategoriesHasMore,
  setProductCategories,
  setProductCategory,
} from "./product-category.action";
import {
  getFetchProductCategoriesFilterBranchId,
  getFetchProductCategoriesFilterMarketId,
  getFetchProductCategoriesFilterSectorId,
  getFetchProductCategoriesIncludes,
  getFetchProductCategoriesPage,
  getFetchProductCategoriesPerPage,
  getFetchProductCategoriesSearch,
  getFetchProductCategoriesSort,
} from "./product-category.selector";

import { getProductCategories, getProductCategory } from "../../api/product-category.api";

export function* _getProductCategories() {
  try {
    yield put(setFetchProductCategoriesLoading(true));

    const search = yield select(getFetchProductCategoriesSearch);
    const sort = yield select(getFetchProductCategoriesSort);
    const page = yield select(getFetchProductCategoriesPage);
    const per_page = yield select(getFetchProductCategoriesPerPage);
    const includes = yield select(getFetchProductCategoriesIncludes);
    const sector_id = yield select(getFetchProductCategoriesFilterSectorId);
    const market_id = yield select(getFetchProductCategoriesFilterMarketId);
    const branch_id = yield select(getFetchProductCategoriesFilterBranchId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: productCategories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsProductCategoriesHasMore(productCategories.length > 0));

    if (page > 1) {
      yield put(appendProductCategories(productCategories));
    } else {
      yield put(setProductCategories(productCategories));
    }

    yield put(setFetchProductCategoriesSuccess(message));
    yield put(setFetchProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoriesFailed(error));
    yield put(setFetchProductCategoriesLoading(false));
  }
}

export function* _getProductCategory({ payload: id }) {
  try {
    yield put(setFetchProductCategoryLoading(true));

    const {
      meta: { message },
      data: productCategory,
    } = yield call(getProductCategory, id);

    yield put(setProductCategory(productCategory));

    yield put(setFetchProductCategorySuccess(message));
    yield put(setFetchProductCategoryLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoryFailed(error));
    yield put(setFetchProductCategoryLoading(false));
  }
}

export function* onFetchProductCategoriesStart() {
  yield takeLatest(PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START, _getProductCategories);
}

export function* onFetchProductCategoryStart() {
  yield takeLatest(PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORY_START, _getProductCategory);
}

export function* productCategorySaga() {
  yield all([call(onFetchProductCategoriesStart), call(onFetchProductCategoryStart)]);
}
