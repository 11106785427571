import { isNumberInvalid } from "./validation.utils";

export const parseToString = (value) => `${value ?? ""}`;

export const parseToInteger = (value) => {
  let parsedValue = parseInt(value ?? 0);
  if (isNumberInvalid(parsedValue)) parsedValue = 0;

  return parsedValue;
};

export const parseToFloat = (value) => {
  let parsedValue = parseFloat(value ?? 0);
  if (isNumberInvalid(parsedValue)) parsedValue = 0;

  return parsedValue;
};

export const parseToBoolean = (value) => [1, "1", true, "true"].includes(value);
