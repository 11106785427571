import { ReactComponent as CheckSvg } from "../assets/icons/Check.svg";
import { ReactComponent as ClockSvg } from "../assets/icons/Clock.svg";
import { ReactComponent as CloseSvg } from "../assets/icons/Close.svg";
import { ReactComponent as MoreCircleSvg } from "../assets/icons/MoreCircle.svg";
import { ReactComponent as SendSvg } from "../assets/icons/Send.svg";

export const ORDER_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  CUSTOMER: "customer",
  CASHIER: "cashier",
  TRANSACTION: "transaction",
  PRODUCTS: "products",
  RETURN_PRODUCTS: "returnProducts",
  CHANGED_PRODUCTS: "changedProducts",
  PRODUCT_FEES: "productFees",
  PRODUCT_DISCOUNT_FEES: "productDiscountFees",
  PRODUCT_TAX_FEES: "productTaxFees",
  PRODUCT_RATINGS: "productRatings",
};

export const ORDER_SOURCES = {
  ADMIN_APP: "ADMIN_APP",
  ADMIN_WEB: "ADMIN_WEB",
  LANDING_APP: "LANDING_APP",
  LANDING_WEB: "LANDING_WEB",
};

export const ORDER_SERVICE_TYPES = {
  TAKE_AWAY: "TAKE_AWAY",
  DINING: "DINING",
  DRIVE_THROUGH: "DRIVE_THROUGH",
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

export const ORDER_STATUSES = {
  WAITING: "WAITING",
  PROCESS: "PROCESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  ON_DELIVERY: "ON_DELIVERY",
  DELIVERED: "DELIVERED",
};

export const getSourceName = (source) =>
  ({
    [ORDER_SOURCES.ADMIN_APP]: "Cashier App",
    [ORDER_SOURCES.ADMIN_WEB]: "Cashier Web",
    [ORDER_SOURCES.LANDING_APP]: "Flavours App",
    [ORDER_SOURCES.LANDING_WEB]: "Flavours Web",
  }?.[source] ?? "-");

export const getServiceTypeName = (serviceType) =>
  ({
    [ORDER_SERVICE_TYPES.DINING]: "Dining",
    [ORDER_SERVICE_TYPES.TAKE_AWAY]: "Take Away",
    [ORDER_SERVICE_TYPES.DELIVERY]: "Delivery",
    [ORDER_SERVICE_TYPES.PICKUP]: "Pickup",
  }?.[serviceType] ?? "-");

export const getStatusIcon = (status) =>
  ({
    [ORDER_STATUSES.WAITING]: MoreCircleSvg,
    [ORDER_STATUSES.PROCESS]: ClockSvg,
    [ORDER_STATUSES.COMPLETED]: CheckSvg,
    [ORDER_STATUSES.CANCELED]: CloseSvg,
    [ORDER_STATUSES.ON_DELIVERY]: SendSvg,
    [ORDER_STATUSES.DELIVERED]: CheckSvg,
  }?.[status] ?? MoreCircleSvg);
