const CUSTOMER_ACTION_TYPES = {
  SET_CUSTOMERS: "customer/SET_CUSTOMERS",
  SET_CUSTOMER: "customer/SET_CUSTOMER",
  SET_FOUND_CUSTOMER: "customer/SET_FOUND_CUSTOMER",
  SET_SELECT_CUSTOMER: "customer/SET_SELECT_CUSTOMER",
  SET_IS_CUSTOMERS_HAS_MORE: "customer/SET_IS_CUSTOMERS_HAS_MORE",
  SET_FETCH_CUSTOMERS_SEARCH: "customer/SET_FETCH_CUSTOMERS_SEARCH",
  SET_FETCH_CUSTOMERS_PAGE: "customer/SET_FETCH_CUSTOMERS_PAGE",
  SET_FETCH_CUSTOMERS_PER_PAGE: "customer/SET_FETCH_CUSTOMERS_PER_PAGE",
  SET_FETCH_CUSTOMERS_INCLUDES: "customer/SET_FETCH_CUSTOMERS_INCLUDES",
  SET_FETCH_CUSTOMERS_FILTER_MARKET_ID: "customer/SET_FETCH_CUSTOMERS_FILTER_MARKET_ID",
  SET_FETCH_CUSTOMERS_LOADING: "customer/SET_FETCH_CUSTOMERS_LOADING",
  SET_FETCH_CUSTOMERS_SUCCESS: "customer/SET_FETCH_CUSTOMERS_SUCCESS",
  SET_FETCH_CUSTOMERS_FAILED: "customer/SET_FETCH_CUSTOMERS_FAILED",
  SET_FETCH_CUSTOMER_LOADING: "customer/SET_FETCH_CUSTOMER_LOADING",
  SET_FETCH_CUSTOMER_SUCCESS: "customer/SET_FETCH_CUSTOMER_SUCCESS",
  SET_FETCH_CUSTOMER_FAILED: "customer/SET_FETCH_CUSTOMER_FAILED",
  SET_FETCH_FIND_CUSTOMER_LOADING: "customer/SET_FETCH_FIND_CUSTOMER_LOADING",
  SET_FETCH_FIND_CUSTOMER_SUCCESS: "customer/SET_FETCH_FIND_CUSTOMER_SUCCESS",
  SET_FETCH_FIND_CUSTOMER_FAILED: "customer/SET_FETCH_FIND_CUSTOMER_FAILED",
  SET_CREATE_CUSTOMER_LOADING: "customer/SET_CREATE_CUSTOMER_LOADING",
  SET_CREATE_CUSTOMER_SUCCESS: "customer/SET_CREATE_CUSTOMER_SUCCESS",
  SET_CREATE_CUSTOMER_FAILED: "customer/SET_CREATE_CUSTOMER_FAILED",
  SET_UPDATE_CUSTOMER_LOADING: "customer/SET_UPDATE_CUSTOMER_LOADING",
  SET_UPDATE_CUSTOMER_SUCCESS: "customer/SET_UPDATE_CUSTOMER_SUCCESS",
  SET_UPDATE_CUSTOMER_FAILED: "customer/SET_UPDATE_CUSTOMER_FAILED",
  APPEND_CUSTOMERS: "customer/APPEND_CUSTOMERS",
  FETCH_CUSTOMERS_START: "customer/FETCH_CUSTOMERS_START",
  FETCH_CUSTOMER_START: "customer/FETCH_CUSTOMER_START",
  FETCH_FIND_CUSTOMER_START: "customer/FETCH_FIND_CUSTOMER_START",
  CREATE_CUSTOMER_START: "customer/CREATE_CUSTOMER_START",
  UPDATE_CUSTOMER_START: "customer/UPDATE_CUSTOMER_START",
};

export default CUSTOMER_ACTION_TYPES;
