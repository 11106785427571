import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DISCOUNT_ACTION_TYPES from "./discount.type";

import {
  appendDiscounts,
  setDiscount,
  setDiscounts,
  setFetchDiscountFailed,
  setFetchDiscountLoading,
  setFetchDiscountsFailed,
  setFetchDiscountsLoading,
  setFetchDiscountsSuccess,
  setFetchDiscountSuccess,
  setIsDiscountsHasMore,
  setVerifyCouponDiscount,
  setVerifyCouponDiscountFailed,
  setVerifyCouponDiscountLoading,
  setVerifyCouponDiscountSuccess,
} from "./discount.action";
import {
  getFetchDiscountsFilterCustomerId,
  getFetchDiscountsFilterExpiredAt,
  getFetchDiscountsFilterTypes,
  getFetchDiscountsIncludes,
  getFetchDiscountsPage,
  getFetchDiscountsPerPage,
  getFetchDiscountsSearch,
} from "./discount.selector";

import { getDiscounts, getDiscount, verifyCouponDiscount } from "../../api/discount.api";

export function* _getDiscounts() {
  try {
    yield put(setFetchDiscountsLoading(true));

    const search = yield select(getFetchDiscountsSearch);
    const page = yield select(getFetchDiscountsPage);
    const per_page = yield select(getFetchDiscountsPerPage);
    const includes = yield select(getFetchDiscountsIncludes);
    const customer_id = yield select(getFetchDiscountsFilterCustomerId);
    const types = yield select(getFetchDiscountsFilterTypes);
    const expired_at = yield select(getFetchDiscountsFilterExpiredAt);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        customer_id,
        types,
        expired_at,
      },
    };

    const {
      meta: { message },
      data: { data: discounts },
    } = yield call(getDiscounts, parameters);

    yield put(setIsDiscountsHasMore(discounts.length > 0));

    if (page > 1) {
      yield put(appendDiscounts(discounts));
    } else {
      yield put(setDiscounts(discounts));
    }

    yield put(setFetchDiscountsSuccess(message));
    yield put(setFetchDiscountsLoading(false));
  } catch (error) {
    yield put(setFetchDiscountsFailed(error));
    yield put(setFetchDiscountsLoading(false));
  }
}

export function* _getDiscount({ payload: id }) {
  try {
    yield put(setFetchDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(getDiscount, id);

    yield put(setDiscount(discount));

    yield put(setFetchDiscountSuccess(message));
    yield put(setFetchDiscountLoading(false));
  } catch (error) {
    yield put(setFetchDiscountFailed(error));
    yield put(setFetchDiscountLoading(false));
  }
}

export function* _verifyCouponDiscount({ payload: request }) {
  try {
    yield put(setVerifyCouponDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(verifyCouponDiscount, request);

    yield put(setVerifyCouponDiscount(discount));

    yield put(setVerifyCouponDiscountSuccess(message));
    yield put(setVerifyCouponDiscountLoading(false));
  } catch (error) {
    yield put(setVerifyCouponDiscountFailed(error));
    yield put(setVerifyCouponDiscountLoading(false));
  }
}

export function* onFetchDiscountsStart() {
  yield takeLatest(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNTS_START, _getDiscounts);
}

export function* onFetchDiscountStart() {
  yield takeLatest(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNT_START, _getDiscount);
}

export function* onVerifyCouponDiscountStart() {
  yield takeLatest(DISCOUNT_ACTION_TYPES.VERIFY_COUPON_DISCOUNT_START, _verifyCouponDiscount);
}

export function* discountSaga() {
  yield all([call(onFetchDiscountsStart), call(onFetchDiscountStart), call(onVerifyCouponDiscountStart)]);
}
