import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_ACTION_TYPES from "./product.type";

import { _getRelatedProductCategories } from "../product-category/product-category.saga";

import {
  appendProducts,
  setFetchProductFailed,
  setFetchProductLoading,
  setFetchProductsFailed,
  setFetchProductsLoading,
  setFetchProductsSuccess,
  setFetchProductSuccess,
  setIsFetchProductHitted,
  setIsFetchProductsHitted,
  setIsProductsHasMore,
  setProduct,
  setProducts,
} from "./product.action";
import {
  getFetchProductsFilterBranchId,
  getFetchProductsFilterIsActive,
  getFetchProductsFilterMarketId,
  getFetchProductsFilterProductCategoryId,
  getFetchProductsFilterProductsIds,
  getFetchProductsIncludes,
  getFetchProductsKeyBy,
  getFetchProductsPage,
  getFetchProductsPerPage,
  getFetchProductsSearch,
  getFetchProductsSort,
} from "./product.selector";

import { getProducts, getProduct } from "../../api/product.api";

export function* _getProducts() {
  try {
    yield put(setFetchProductsLoading(true));

    const search = yield select(getFetchProductsSearch);
    const key_by = yield select(getFetchProductsKeyBy);
    const sort = yield select(getFetchProductsSort);
    const page = yield select(getFetchProductsPage);
    const per_page = yield select(getFetchProductsPerPage);
    const includes = yield select(getFetchProductsIncludes);
    const market_id = yield select(getFetchProductsFilterMarketId);
    const branch_id = yield select(getFetchProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchProductsFilterProductsIds);
    const is_active = yield select(getFetchProductsFilterIsActive);

    const parameters = {
      search,
      key_by,
      sort,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsFetchProductsHitted(true));
    yield put(setIsProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendProducts(products));
    } else {
      yield put(setProducts(products));
    }

    yield put(setFetchProductsSuccess(message));
    yield put(setFetchProductsLoading(false));
  } catch (error) {
    yield put(setFetchProductsFailed(error));
    yield put(setFetchProductsLoading(false));
  }
}
export function* _getProduct({ payload: productId }) {
  try {
    yield put(setFetchProductLoading(true));

    const {
      meta: { message },
      data: product,
    } = yield call(getProduct, productId);

    yield put(setIsFetchProductHitted(true));
    yield put(setProduct(product));

    yield put(setFetchProductSuccess(message));
    yield put(setFetchProductLoading(false));
  } catch (error) {
    yield put(setFetchProductFailed(error));
    yield put(setFetchProductLoading(false));
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START, _getProducts);
}
export function* onFetchProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCT_START, _getProduct);
}

export function* productSaga() {
  yield all([call(onFetchProductsStart), call(onFetchProductStart)]);
}
