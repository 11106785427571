import { createSelector } from "reselect";

const branchSelector = ({ branch }) => branch;

export const getBranches = createSelector(
  [branchSelector],
  ({ branches }) => branches
);
export const getBranch = createSelector(
  [branchSelector],
  ({ branch }) => branch
);

export const getIsBranchesHasMore = createSelector(
  [branchSelector],
  ({ isBranchesHasMore }) => isBranchesHasMore
);

export const getFetchBranchesSearch = createSelector(
  [branchSelector],
  ({ fetchBranchesSearch }) => fetchBranchesSearch
);
export const getFetchBranchesKeyBy = createSelector(
  [branchSelector],
  ({ fetchBranchesKeyBy }) => fetchBranchesKeyBy
);
export const getFetchBranchesPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPage }) => fetchBranchesPage
);
export const getFetchBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPerPage }) => fetchBranchesPerPage
);
export const getFetchBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchBranchesIncludes }) => fetchBranchesIncludes
);
export const getFetchBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterMarketId }) => fetchBranchesFilterMarketId
);
export const getFetchBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterIsActive }) => fetchBranchesFilterIsActive
);
export const getFetchBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchBranchesLoading }) => fetchBranchesLoading
);
export const getFetchBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchesSuccess }) => fetchBranchesSuccess
);
export const getFetchBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchBranchesFailed }) => fetchBranchesFailed
);

export const getFetchBranchLoading = createSelector(
  [branchSelector],
  ({ fetchBranchLoading }) => fetchBranchLoading
);
export const getFetchBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchSuccess }) => fetchBranchSuccess
);
export const getFetchBranchFailed = createSelector(
  [branchSelector],
  ({ fetchBranchFailed }) => fetchBranchFailed
);

export const getIsFetchBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchesHitted }) => isFetchBranchesHitted
);
export const getIsFetchBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchHitted }) => isFetchBranchHitted
);
