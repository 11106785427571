const VERIFICATION_ACTION_TYPES = {
  RESET_VERIFICATION_REDUCER: "verification/RESET_VERIFICATION_REDUCER",

  SET_SEND_VERIFICATION_RESULT: "verification/SET_SEND_VERIFICATION_RESULT",
  SET_VERIFY_VERIFICATION_RESULT: "verification/SET_VERIFY_VERIFICATION_RESULT",

  SET_SEND_VERIFICATION_LOADING: "verification/SET_SEND_VERIFICATION_LOADING",
  SET_SEND_VERIFICATION_SUCCESS: "verification/SET_SEND_VERIFICATION_SUCCESS",
  SET_SEND_VERIFICATION_FAILED: "verification/SET_SEND_VERIFICATION_FAILED",

  SET_VERIFY_VERIFICATION_LOADING: "verification/SET_VERIFY_VERIFICATION_LOADING",
  SET_VERIFY_VERIFICATION_SUCCESS: "verification/SET_VERIFY_VERIFICATION_SUCCESS",
  SET_VERIFY_VERIFICATION_FAILED: "verification/SET_VERIFY_VERIFICATION_FAILED",

  SET_IS_SEND_VERIFICATION_HITTED: "verification/SET_IS_SEND_VERIFICATION_HITTED",
  SET_IS_VERIFY_VERIFICATION_HITTED: "verification/SET_IS_VERIFY_VERIFICATION_HITTED",

  SEND_VERIFICATION_START: "verification/SEND_VERIFICATION_START",
  VERIFY_VERIFICATION_START: "verification/VERIFY_VERIFICATION_START",
};

export default VERIFICATION_ACTION_TYPES;
