import VERIFICATION_ACTION_TYPES from "./verification.type";

export const VERIFICATION_INITIAL_STATE = {
  sendVerificationResult: null,
  verifyVerificationResult: null,

  sendVerificationLoading: false,
  sendVerificationSuccess: null,
  sendVerificationFailed: null,

  verifyVerificationLoading: false,
  verifyVerificationSuccess: null,
  verifyVerificationFailed: null,

  isSendVerificationHitted: false,
  isVerifyVerificationHitted: false,
};

export const verificationReducer = (state = VERIFICATION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_RESULT:
      return { ...state, sendVerificationResult: payload };
    case VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_RESULT:
      return { ...state, verifyVerificationResult: payload };

    case VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_LOADING:
      return { ...state, sendVerificationLoading: payload };
    case VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_SUCCESS:
      return { ...state, sendVerificationSuccess: payload };
    case VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_FAILED:
      return { ...state, sendVerificationFailed: payload };

    case VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_LOADING:
      return { ...state, verifyVerificationLoading: payload };
    case VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_SUCCESS:
      return { ...state, verifyVerificationSuccess: payload };
    case VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_FAILED:
      return { ...state, verifyVerificationFailed: payload };

    case VERIFICATION_ACTION_TYPES.SET_IS_SEND_VERIFICATION_HITTED:
      return { ...state, isSendVerificationHitted: payload };
    case VERIFICATION_ACTION_TYPES.SET_IS_VERIFY_VERIFICATION_HITTED:
      return { ...state, isVerifyVerificationHitted: payload };

    case VERIFICATION_ACTION_TYPES.RESET_VERIFICATION_REDUCER:
      return VERIFICATION_INITIAL_STATE;
    default:
      return state;
  }
};
