import apiService from "./api";

export const getCustomers = async (parameters) => (await apiService.get("/customer", parameters)).data;

export const getCustomer = async (id) => (await apiService.get(`/customer/${id}`)).data;

export const findCustomer = async (phone) => (await apiService.get(`/customer/find/${phone}`)).data;

export const createCustomer = async (request) => (await apiService.post("/customer", request)).data;

export const updateCustomer = async (id, request) => (await apiService.post(`/customer/${id}`, request)).data;
