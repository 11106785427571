import { createSelector } from "reselect";

const scheduleSelector = ({ schedule }) => schedule;

export const getSchedules = createSelector([scheduleSelector], ({ schedules }) => schedules);

export const getFetchSchedulesIncludes = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesIncludes }) => fetchSchedulesIncludes
);

export const getFetchSchedulesFilterMarketId = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFilterMarketId }) => fetchSchedulesFilterMarketId
);

export const getFetchSchedulesFilterBranchId = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFilterBranchId }) => fetchSchedulesFilterBranchId
);

export const getFetchSchedulesLoading = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesLoading }) => fetchSchedulesLoading
);

export const getFetchSchedulesSuccess = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesSuccess }) => fetchSchedulesSuccess
);

export const getFetchSchedulesFailed = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFailed }) => fetchSchedulesFailed
);
