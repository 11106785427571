import { takeLatest, put, all, call, select } from "redux-saga/effects";

import VERIFICATION_ACTION_TYPES from "./verification.type";

import {
  setIsSendVerificationHitted,
  setIsVerifyVerificationHitted,
  setSendVerificationFailed,
  setSendVerificationLoading,
  setSendVerificationResult,
  setSendVerificationSuccess,
  setVerifyVerificationFailed,
  setVerifyVerificationLoading,
  setVerifyVerificationResult,
  setVerifyVerificationSuccess,
} from "./verification.action";

import { sendVerification, verifyVerification } from "../../api/verification.api";

import { setItem } from "../../utils/storage.utils";

export function* _sendVerification({ payload: request }) {
  try {
    yield put(setSendVerificationLoading(true));

    const {
      meta: { message },
      data: sendVerificationResult,
    } = yield call(sendVerification, request);

    yield put(setIsSendVerificationHitted(true));
    yield put(setSendVerificationResult(sendVerificationResult));
    yield call(setItem, `${request?.type}_RESEND_AT`, sendVerificationResult.resend_at);

    yield put(setSendVerificationSuccess(message));
    yield put(setSendVerificationLoading(false));
  } catch (error) {
    if (error?.data?.resend_at !== null) {
      yield call(setItem, `${request?.type}_RESEND_AT`, error?.data?.resend_at);
    }

    yield put(setSendVerificationFailed(error));
    yield put(setSendVerificationLoading(false));
  }
}

export function* _verifyVerification({ payload: request }) {
  try {
    yield put(setVerifyVerificationLoading(true));

    const {
      meta: { message },
      data: verifyVerificationResult,
    } = yield call(verifyVerification, request);

    yield put(setIsVerifyVerificationHitted(true));
    yield put(setVerifyVerificationResult(verifyVerificationResult));

    yield put(setVerifyVerificationSuccess(message));
    yield put(setVerifyVerificationLoading(false));
  } catch (error) {
    yield put(setVerifyVerificationFailed(error));
    yield put(setVerifyVerificationLoading(false));
  }
}

export function* onSendVerificationStart() {
  yield takeLatest(VERIFICATION_ACTION_TYPES.SEND_VERIFICATION_START, _sendVerification);
}
export function* onVerifyVerificationStart() {
  yield takeLatest(VERIFICATION_ACTION_TYPES.VERIFY_VERIFICATION_START, _verifyVerification);
}

export function* verificationSaga() {
  yield all([call(onSendVerificationStart), call(onVerifyVerificationStart)]);
}
