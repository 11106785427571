import DISCOUNT_ACTION_TYPES from "./discount.type";
import { createAction } from "../../utils/store.utils";

export const setDiscountTab = (discountTab) => createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNT_TAB, discountTab);

export const setDiscounts = (discounts) => createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNTS, discounts);

export const setDiscount = (discount) => createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNT, discount);

export const setVerifyCouponDiscount = (verifyCouponDiscount) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT, verifyCouponDiscount);

export const setIsDiscountsHasMore = (isDiscountsHasMore) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_IS_DISCOUNTS_HAS_MORE, isDiscountsHasMore);

export const setFetchDiscountsSearch = (fetchDiscountsSearch) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SEARCH, fetchDiscountsSearch);

export const setFetchDiscountsPage = (fetchDiscountsPage) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PAGE, fetchDiscountsPage);

export const setFetchDiscountsPerPage = (fetchDiscountsPerPage) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PER_PAGE, fetchDiscountsPerPage);

export const setFetchDiscountsIncludes = (fetchDiscountsIncludes) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_INCLUDES, fetchDiscountsIncludes);

export const setFetchDiscountsFilterCustomerId = (fetchDiscountsFilterCustomerId) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_CUSTOMER_ID, fetchDiscountsFilterCustomerId);

export const setFetchDiscountsFilterTypes = (fetchDiscountsFilterTypes) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_TYPES, fetchDiscountsFilterTypes);

export const setFetchDiscountsFilterExpiredAt = (fetchDiscountsFilterExpiredAt) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT, fetchDiscountsFilterExpiredAt);

export const setFetchDiscountsLoading = (fetchDiscountsLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_LOADING, fetchDiscountsLoading);

export const setFetchDiscountsSuccess = (fetchDiscountsSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SUCCESS, fetchDiscountsSuccess);

export const setFetchDiscountsFailed = (fetchDiscountsFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FAILED, fetchDiscountsFailed);

export const setFetchDiscountLoading = (fetchDiscountLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_LOADING, fetchDiscountLoading);

export const setFetchDiscountSuccess = (fetchDiscountSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_SUCCESS, fetchDiscountSuccess);

export const setFetchDiscountFailed = (fetchDiscountFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_FAILED, fetchDiscountFailed);

export const setCreateDiscountLoading = (createDiscountLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_LOADING, createDiscountLoading);

export const setCreateDiscountSuccess = (createDiscountSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_SUCCESS, createDiscountSuccess);

export const setCreateDiscountFailed = (createDiscountFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_FAILED, createDiscountFailed);

export const setUpdateDiscountLoading = (updateDiscountLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_LOADING, updateDiscountLoading);

export const setUpdateDiscountSuccess = (updateDiscountSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_SUCCESS, updateDiscountSuccess);

export const setUpdateDiscountFailed = (updateDiscountFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_FAILED, updateDiscountFailed);

export const setDeleteDiscountLoading = (deleteDiscountLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_LOADING, deleteDiscountLoading);

export const setDeleteDiscountSuccess = (deleteDiscountSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_SUCCESS, deleteDiscountSuccess);

export const setDeleteDiscountFailed = (deleteDiscountFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_FAILED, deleteDiscountFailed);

export const setVerifyCouponDiscountLoading = (verifyCouponDiscountLoading) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_LOADING, verifyCouponDiscountLoading);

export const setVerifyCouponDiscountSuccess = (verifyCouponDiscountSuccess) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_SUCCESS, verifyCouponDiscountSuccess);

export const setVerifyCouponDiscountFailed = (verifyCouponDiscountFailed) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_FAILED, verifyCouponDiscountFailed);

export const appendDiscounts = (discounts) => createAction(DISCOUNT_ACTION_TYPES.APPEND_DISCOUNTS, discounts);

export const fetchDiscountsStart = () => createAction(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNTS_START);

export const fetchDiscountStart = (id) => createAction(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNT_START, id);

export const createDiscountStart = (request) => createAction(DISCOUNT_ACTION_TYPES.CREATE_DISCOUNT_START, request);

export const updateDiscountStart = (id, request) =>
  createAction(DISCOUNT_ACTION_TYPES.UPDATE_DISCOUNT_START, { id, request });

export const deleteDiscountStart = (id) => createAction(DISCOUNT_ACTION_TYPES.DELETE_DISCOUNT_START, id);

export const verifyCouponDiscountStart = (request) =>
  createAction(DISCOUNT_ACTION_TYPES.VERIFY_COUPON_DISCOUNT_START, request);

export const resetDiscountReducer = () => createAction(DISCOUNT_ACTION_TYPES.RESET_DISCOUNT_REDUCER);
