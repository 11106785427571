import { ORDER_SERVICE_TYPES } from "../../constants/order.constant";

import ORDER_ACTION_TYPES from "./order.type";

export const ORDER_INITIAL_STATE = {
  cartInputValues: null,

  orderServiceTypes: [
    {
      label: "Dining",
      value: ORDER_SERVICE_TYPES.DINING,
    },
    {
      label: "Take Away",
      value: ORDER_SERVICE_TYPES.TAKE_AWAY,
    },
  ],

  orders: [],
  order: null,
  createOrder: null,
  updateOrder: null,
  calculateOrder: null,

  isOrdersHasMore: true,

  fetchOrdersSearch: null,
  fetchOrdersKeyBy: null,
  fetchOrdersPage: 1,
  fetchOrdersPerPage: null,
  fetchOrdersIncludes: null,
  fetchOrdersFilterMarketId: null,
  fetchOrdersFilterBranchId: null,
  fetchOrdersFilterCustomerId: null,
  fetchOrdersFilterSources: null,
  fetchOrdersFilterTypes: null,
  fetchOrdersFilterStatuses: null,
  fetchOrdersFilterCreatedAtBefore: null,
  fetchOrdersFilterCreatedAtAfter: null,
  fetchOrdersLoading: false,
  fetchOrdersSuccess: null,
  fetchOrdersFailed: null,

  fetchOrderLoading: false,
  fetchOrderSuccess: null,
  fetchOrderFailed: null,

  calculateOrderLoading: false,
  calculateOrderSuccess: null,
  calculateOrderFailed: null,

  createOrderLoading: false,
  createOrderSuccess: null,
  createOrderFailed: null,

  updateOrderLoading: false,
  updateOrderSuccess: null,
  updateOrderFailed: null,

  isFetchOrdersHitted: false,
  isFetchOrderHitted: false,
  isCalculateOrderHitted: false,
  isCreateOrderHitted: false,
  isUpdateOrderHitted: false,
};

export const orderReducer = (state = ORDER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_ACTION_TYPES.SET_CART_INPUT_VALUES:
      return { ...state, cartInputValues: payload };

    case ORDER_ACTION_TYPES.SET_ORDERS:
      return { ...state, orders: payload };
    case ORDER_ACTION_TYPES.SET_ORDER:
      return { ...state, order: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER:
      return { ...state, createOrder: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER:
      return { ...state, updateOrder: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER:
      return { ...state, calculateOrder: payload };

    case ORDER_ACTION_TYPES.SET_IS_ORDERS_HAS_MORE:
      return { ...state, isOrdersHasMore: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SEARCH:
      return { ...state, fetchOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_KEY_BY:
      return { ...state, fetchOrdersKeyBy: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PAGE:
      return { ...state, fetchOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PER_PAGE:
      return { ...state, fetchOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_INCLUDES:
      return { ...state, fetchOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CUSTOMER_ID:
      return { ...state, fetchOrdersFilterCustomerId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_SOURCES:
      return { ...state, fetchOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TYPES:
      return { ...state, fetchOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_STATUSES:
      return { ...state, fetchOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_LOADING:
      return { ...state, fetchOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SUCCESS:
      return { ...state, fetchOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FAILED:
      return { ...state, fetchOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_LOADING:
      return { ...state, fetchOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_SUCCESS:
      return { ...state, fetchOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_FAILED:
      return { ...state, fetchOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_LOADING:
      return { ...state, calculateOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_SUCCESS:
      return { ...state, calculateOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_FAILED:
      return { ...state, calculateOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_LOADING:
      return { ...state, createOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_SUCCESS:
      return { ...state, createOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_FAILED:
      return { ...state, createOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_LOADING:
      return { ...state, updateOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_SUCCESS:
      return { ...state, updateOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_FAILED:
      return { ...state, updateOrderFailed: payload };

    case ORDER_ACTION_TYPES.APPEND_ORDERS:
      return { ...state, orders: [...state.orders, ...payload] };

    case ORDER_ACTION_TYPES.SET_IS_FETCH_ORDERS_HITTED:
      return { ...state, isFetchOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_ORDER_HITTED:
      return { ...state, isFetchOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CALCULATE_ORDER_HITTED:
      return { ...state, isCalculateOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CREATE_ORDER_HITTED:
      return { ...state, isCreateOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_UPDATE_ORDER_HITTED:
      return { ...state, isUpdateOrderHitted: payload };

    default:
      return state;
  }
};
