import CUSTOMER_ACTION_TYPES from "./customer.type";

export const CUSTOMER_INITIAL_STATE = {
  customers: [],
  customer: null,
  foundCustomer: null,
  selectCustomer: null,
  isCustomersHasMore: true,
  fetchCustomersSearch: null,
  fetchCustomersPage: 1,
  fetchCustomersPerPage: null,
  fetchCustomersIncludes: null,
  fetchCustomersFilterMarketId: null,
  fetchCustomersLoading: false,
  fetchCustomersSuccess: null,
  fetchCustomersFailed: null,
  fetchCustomerLoading: false,
  fetchCustomerSuccess: null,
  fetchCustomerFailed: null,
  fetchFindCustomerLoading: false,
  fetchFindCustomerSuccess: null,
  fetchFindCustomerFailed: null,
  createCustomerLoading: false,
  createCustomerSuccess: null,
  createCustomerFailed: null,
  updateCustomerLoading: false,
  updateCustomerSuccess: null,
  updateCustomerFailed: null,
};

export const customerReducer = (state = CUSTOMER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMERS:
      return { ...state, customers: payload };
    case CUSTOMER_ACTION_TYPES.SET_FOUND_CUSTOMER:
      return { ...state, foundCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_SELECT_CUSTOMER:
      return { ...state, selectCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMER:
      return { ...state, customer: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE:
      return { ...state, isCustomersHasMore: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SEARCH:
      return { ...state, fetchCustomersSearch: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PAGE:
      return { ...state, fetchCustomersPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PER_PAGE:
      return { ...state, fetchCustomersPerPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_INCLUDES:
      return { ...state, fetchCustomersIncludes: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchCustomersFilterMarketId: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING:
      return { ...state, fetchCustomersLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS:
      return { ...state, fetchCustomersSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED:
      return { ...state, fetchCustomersFailed: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_LOADING:
      return { ...state, fetchCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_SUCCESS:
      return { ...state, fetchCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_FAILED:
      return { ...state, fetchCustomerFailed: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_LOADING:
      return { ...state, fetchFindCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_SUCCESS:
      return { ...state, fetchFindCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_FAILED:
      return { ...state, fetchFindCustomerFailed: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_LOADING:
      return { ...state, createCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_SUCCESS:
      return { ...state, createCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_FAILED:
      return { ...state, createCustomerFailed: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_LOADING:
      return { ...state, updateCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_SUCCESS:
      return { ...state, updateCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_FAILED:
      return { ...state, updateCustomerFailed: payload };
    case CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS:
      return { ...state, customers: [...state.customers, ...payload] };
    default:
      return state;
  }
};
