import SYNC_ACTION_TYPES from "./synchronize.type";

export const SYNC_INITIAL_STATE = {
  syncGateway: null,

  syncGatewayLoading: false,
  syncGatewaySuccess: null,
  syncGatewayFailed: null,
};

export const syncReducer = (state = SYNC_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SYNC_ACTION_TYPES.SET_SYNC_GATEWAY:
      return { ...state, syncGateway: payload };

    case SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_LOADING:
      return { ...state, syncGatewayLoading: payload };
    case SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_SUCCESS:
      return { ...state, syncGatewaySuccess: payload };
    case SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_FAILED:
      return { ...state, syncGatewayFailed: payload };

    case SYNC_ACTION_TYPES.RESET_SYNC_REDUCER:
      return SYNC_INITIAL_STATE;
    default:
      return state;
  }
};
