import PRODUCT_ACTION_TYPES from "./product.type";
import { createAction } from "../../utils/store.utils";

export const setProducts = (products) =>
  createAction(PRODUCT_ACTION_TYPES.SET_PRODUCTS, products);
export const setProduct = (product) =>
  createAction(PRODUCT_ACTION_TYPES.SET_PRODUCT, product);

export const setIsProductsHasMore = (isProductsHasMore) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE,
    isProductsHasMore
  );

export const setFetchProductsSearch = (fetchProductsSearch) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SEARCH,
    fetchProductsSearch
  );
export const setFetchProductsKeyBy = (fetchProductsKeyBy) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_KEY_BY,
    fetchProductsKeyBy
  );
export const setFetchProductsSort = (fetchProductsSort) =>
  createAction(PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SORT, fetchProductsSort);
export const setFetchProductsPage = (fetchProductsPage) =>
  createAction(PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PAGE, fetchProductsPage);
export const setFetchProductsPerPage = (fetchProductsPerPage) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PER_PAGE,
    fetchProductsPerPage
  );
export const setFetchProductsIncludes = (fetchProductsIncludes) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_INCLUDES,
    fetchProductsIncludes
  );
export const setFetchProductsFilterMarketId = (fetchProductsFilterMarketId) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_MARKET_ID,
    fetchProductsFilterMarketId
  );
export const setFetchProductsFilterBranchId = (fetchProductsFilterBranchId) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_BRANCH_ID,
    fetchProductsFilterBranchId
  );
export const setFetchProductsFilterProductCategoryId = (
  fetchProductsFilterProductCategoryId
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID,
    fetchProductsFilterProductCategoryId
  );
export const setFetchProductsFilterProductsIds = (
  fetchProductsFilterProductsIds
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS,
    fetchProductsFilterProductsIds
  );
export const setFetchProductsFilterIsActive = (fetchProductsFilterIsActive) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE,
    fetchProductsFilterIsActive
  );
export const setFetchProductsLoading = (fetchProductsLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING,
    fetchProductsLoading
  );
export const setFetchProductsSuccess = (fetchProductsSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS,
    fetchProductsSuccess
  );
export const setFetchProductsFailed = (fetchProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED,
    fetchProductsFailed
  );

export const setFetchProductLoading = (fetchProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_LOADING,
    fetchProductLoading
  );
export const setFetchProductSuccess = (fetchProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_SUCCESS,
    fetchProductSuccess
  );
export const setFetchProductFailed = (fetchProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_FAILED,
    fetchProductFailed
  );

export const appendProducts = (products) =>
  createAction(PRODUCT_ACTION_TYPES.APPEND_PRODUCTS, products);

export const fetchProductsStart = () =>
  createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START);
export const fetchProductStart = (productId) =>
  createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCT_START, productId);

export const setIsFetchProductsHitted = (isFetchProductsHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED,
    isFetchProductsHitted
  );
export const setIsFetchProductHitted = (isFetchProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCT_HITTED,
    isFetchProductHitted
  );

export const resetProductReducer = () =>
  createAction(PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER);
