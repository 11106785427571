import SYNC_ACTION_TYPES from "./synchronize.type";
import { createAction } from "../../utils/store.utils";

export const setSyncGateway = (syncGateway) => createAction(SYNC_ACTION_TYPES.SET_SYNC_GATEWAY, syncGateway);

export const setSyncGatewayLoading = (syncGatewayLoading) =>
  createAction(SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_LOADING, syncGatewayLoading);

export const setSyncGatewaySuccess = (syncGatewaySuccess) =>
  createAction(SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_SUCCESS, syncGatewaySuccess);

export const setSyncGatewayFailed = (syncGatewayFailed) =>
  createAction(SYNC_ACTION_TYPES.SET_SYNC_GATEWAY_FAILED, syncGatewayFailed);

export const syncGatewayStart = (gatewayId) => createAction(SYNC_ACTION_TYPES.SYNC_GATEWAY_START, gatewayId);

export const resetSyncReducer = () => createAction(SYNC_ACTION_TYPES.RESET_SYNC_REDUCER);
