import { createSelector } from "reselect";

const tableSelector = ({ table }) => table;

export const getTable = createSelector([tableSelector], ({ table }) => table);
export const getTableByCode = createSelector(
  [tableSelector],
  ({ tableByCode }) => tableByCode
);

export const getFetchTableLoading = createSelector(
  [tableSelector],
  ({ fetchTableLoading }) => fetchTableLoading
);
export const getFetchTableSuccess = createSelector(
  [tableSelector],
  ({ fetchTableSuccess }) => fetchTableSuccess
);
export const getFetchTableFailed = createSelector(
  [tableSelector],
  ({ fetchTableFailed }) => fetchTableFailed
);

export const getFetchTableByCodeMarketId = createSelector(
  [tableSelector],
  ({ fetchTableByCodeMarketId }) => fetchTableByCodeMarketId
);
export const getFetchTableByCodeBranchId = createSelector(
  [tableSelector],
  ({ fetchTableByCodeBranchId }) => fetchTableByCodeBranchId
);
export const getFetchTableByCodeLoading = createSelector(
  [tableSelector],
  ({ fetchTableByCodeLoading }) => fetchTableByCodeLoading
);
export const getFetchTableByCodeSuccess = createSelector(
  [tableSelector],
  ({ fetchTableByCodeSuccess }) => fetchTableByCodeSuccess
);
export const getFetchTableByCodeFailed = createSelector(
  [tableSelector],
  ({ fetchTableByCodeFailed }) => fetchTableByCodeFailed
);

export const getIsFetchTableHitted = createSelector(
  [tableSelector],
  ({ isFetchTableHitted }) => isFetchTableHitted
);
export const getIsFetchTableByCodeHitted = createSelector(
  [tableSelector],
  ({ isFetchTableByCodeHitted }) => isFetchTableByCodeHitted
);
