import { takeLatest, put, all, call } from "redux-saga/effects";

import SYNC_ACTION_TYPES from "./synchronize.type";

import {
  setSyncGateway,
  setSyncGatewayFailed,
  setSyncGatewayLoading,
  setSyncGatewaySuccess,
} from "./synchronize.action";

import { syncGateway } from "../../api/synchronize.api";

export function* _getSyncGateway({ payload: gatewayId }) {
  try {
    yield put(setSyncGatewayLoading(true));

    const {
      meta: { message },
      data: gateway,
    } = yield call(syncGateway, gatewayId);

    yield put(setSyncGateway(gateway));

    yield put(setSyncGatewaySuccess(message));
    yield put(setSyncGatewayLoading(false));
  } catch (error) {
    yield put(setSyncGatewayFailed(error));
    yield put(setSyncGatewayLoading(false));
  }
}

export function* onSyncGatewayStart() {
  yield takeLatest(SYNC_ACTION_TYPES.SYNC_GATEWAY_START, _getSyncGateway);
}

export function* syncSaga() {
  yield all([call(onSyncGatewayStart)]);
}
