import AUTHENTICATION_ACTION_TYPES from "./authentication.type";

export const AUTHENTICATION_INITIAL_STATE = {
  user: null,
  updateMe: null,

  authCheckLoading: false,
  authCheckSuccess: null,
  authCheckFailed: null,

  fetchMeLoading: false,
  fetchMeSuccess: null,
  fetchMeFailed: null,

  updateMeLoading: false,
  updateMeSuccess: null,
  updateMeFailed: null,

  signInLoading: false,
  signInSuccess: null,
  signInFailed: null,

  signUpLoading: false,
  signUpSuccess: null,
  signUpFailed: null,

  signOutLoading: false,
  signOutSuccess: null,
  signOutFailed: null,
};

export const authenticationReducer = (state = AUTHENTICATION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTHENTICATION_ACTION_TYPES.SET_USER:
      return { ...state, user: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME:
      return { ...state, updateMe: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_LOADING:
      return { ...state, authCheckLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_SUCCESS:
      return { ...state, authCheckSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_FAILED:
      return { ...state, authCheckFailed: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_LOADING:
      return { ...state, fetchMeLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_SUCCESS:
      return { ...state, fetchMeSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_FAILED:
      return { ...state, fetchMeFailed: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_LOADING:
      return { ...state, updateMeLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_SUCCESS:
      return { ...state, updateMeSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_FAILED:
      return { ...state, updateMeFailed: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_LOADING:
      return { ...state, signInLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_SUCCESS:
      return { ...state, signInSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_FAILED:
      return { ...state, signInFailed: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_LOADING:
      return { ...state, signUpLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_SUCCESS:
      return { ...state, signUpSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_FAILED:
      return { ...state, signUpFailed: payload };

    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_LOADING:
      return { ...state, signOutLoading: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_SUCCESS:
      return { ...state, signOutSuccess: payload };
    case AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_FAILED:
      return { ...state, signOutFailed: payload };

    default:
      return state;
  }
};
