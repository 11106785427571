import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

export const PRODUCT_CATEGORY_INITIAL_STATE = {
  productCategories: [],
  productCategory: null,

  isProductCategoriesHasMore: true,

  fetchProductCategoriesSearch: null,
  fetchProductCategoriesSort: null,
  fetchProductCategoriesPage: 1,
  fetchProductCategoriesPerPage: null,
  fetchProductCategoriesIncludes: null,
  fetchProductCategoriesFilterSectorId: null,
  fetchProductCategoriesFilterMarketId: null,
  fetchProductCategoriesFilterBranchId: null,
  fetchProductCategoriesLoading: false,
  fetchProductCategoriesSuccess: null,
  fetchProductCategoriesFailed: null,

  fetchProductCategoryLoading: false,
  fetchProductCategorySuccess: null,
  fetchProductCategoryFailed: null,
};

export const productCategoryReducer = (state = PRODUCT_CATEGORY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORY:
      return { ...state, productCategory: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isProductCategoriesHasMore: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_LOADING:
      return { ...state, fetchProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, fetchProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_FAILED:
      return { ...state, fetchProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES:
      return { ...state, productCategories: [...state.productCategories, ...payload] };

    case PRODUCT_CATEGORY_ACTION_TYPES.RESET_PRODUCT_CATEGORY_REDUCER:
      return PRODUCT_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
