import { takeLatest, put, all, call, select } from "redux-saga/effects";

import MARKET_ACTION_TYPES from "./market.type";

import {
  appendMarkets,
  setFetchMarketFailed,
  setFetchMarketLoading,
  setFetchMarketsFailed,
  setFetchMarketsLoading,
  setFetchMarketsSuccess,
  setFetchMarketSuccess,
  setIsMarketsHasMore,
  setMarket,
  setMarkets,
} from "./market.action";
import {
  getFetchMarketsFilterMarketId,
  getFetchMarketsIncludes,
  getFetchMarketsPage,
  getFetchMarketsPerPage,
  getFetchMarketsSearch,
} from "./market.selector";

import { getMarkets, getMarket } from "../../api/market.api";

export function* _getMarkets() {
  try {
    yield put(setFetchMarketsLoading(true));

    const search = yield select(getFetchMarketsSearch);
    const page = yield select(getFetchMarketsPage);
    const per_page = yield select(getFetchMarketsPerPage);
    const includes = yield select(getFetchMarketsIncludes);
    const market_id = yield select(getFetchMarketsFilterMarketId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: markets },
    } = yield call(getMarkets, parameters);

    yield put(setIsMarketsHasMore(markets.length > 0));

    if (page > 1) {
      yield put(appendMarkets(markets));
    } else {
      yield put(setMarkets(markets));
    }

    yield put(setFetchMarketsSuccess(message));
    yield put(setFetchMarketsLoading(false));
  } catch (error) {
    yield put(setFetchMarketsFailed(error));
    yield put(setFetchMarketsLoading(false));
  }
}

export function* _getMarket({ payload: id }) {
  try {
    yield put(setFetchMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, id);

    yield put(setMarket(market));

    yield put(setFetchMarketSuccess(message));
    yield put(setFetchMarketLoading(false));
  } catch (error) {
    yield put(setFetchMarketFailed(error));
    yield put(setFetchMarketLoading(false));
  }
}

export function* onFetchMarketsStart() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKETS_START, _getMarkets);
}

export function* onFetchMarketStart() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKET_START, _getMarket);
}

export function* marketSaga() {
  yield all([call(onFetchMarketsStart), call(onFetchMarketStart)]);
}
