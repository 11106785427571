import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SCHEDULE_ACTION_TYPES from "./schedule.type";

import {
  setFetchSchedulesFailed,
  setFetchSchedulesLoading,
  setFetchSchedulesSuccess,
  setSchedules,
} from "./schedule.action";
import {
  getFetchSchedulesFilterBranchId,
  getFetchSchedulesFilterMarketId,
  getFetchSchedulesIncludes,
} from "./schedule.selector";

import { getSchedules } from "../../api/schedule.api";

export function* _getSchedules() {
  try {
    yield put(setFetchSchedulesLoading(true));

    const includes = yield select(getFetchSchedulesIncludes);
    const market_id = yield select(getFetchSchedulesFilterMarketId);
    const branch_id = yield select(getFetchSchedulesFilterBranchId);

    const parameters = {
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: schedules,
    } = yield call(getSchedules, parameters);

    yield put(setSchedules(schedules));

    yield put(setFetchSchedulesSuccess(message));
    yield put(setFetchSchedulesLoading(false));
  } catch (error) {
    yield put(setFetchSchedulesFailed(error));
    yield put(setFetchSchedulesLoading(false));
  }
}

export function* onFetchSchedulesStart() {
  yield takeLatest(SCHEDULE_ACTION_TYPES.FETCH_SCHEDULES_START, _getSchedules);
}

export function* scheduleSaga() {
  yield all([call(onFetchSchedulesStart)]);
}
