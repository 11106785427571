const TABLE_ACTION_TYPES = {
  RESET_TABLE_REDUCER: "table/RESET_TABLE_REDUCER",

  SET_TABLE: "table/SET_TABLE",
  SET_TABLE_BY_CODE: "table/SET_TABLE_BY_CODE",

  SET_FETCH_TABLE_LOADING: "table/SET_FETCH_TABLE_LOADING",
  SET_FETCH_TABLE_SUCCESS: "table/SET_FETCH_TABLE_SUCCESS",
  SET_FETCH_TABLE_FAILED: "table/SET_FETCH_TABLE_FAILED",

  SET_FETCH_TABLE_BY_CODE_MARKET_ID: "table/SET_FETCH_TABLE_BY_CODE_MARKET_ID",
  SET_FETCH_TABLE_BY_CODE_BRANCH_ID: "table/SET_FETCH_TABLE_BY_CODE_BRANCH_ID",
  SET_FETCH_TABLE_BY_CODE_LOADING: "table/SET_FETCH_TABLE_BY_CODE_LOADING",
  SET_FETCH_TABLE_BY_CODE_SUCCESS: "table/SET_FETCH_TABLE_BY_CODE_SUCCESS",
  SET_FETCH_TABLE_BY_CODE_FAILED: "table/SET_FETCH_TABLE_BY_CODE_FAILED",

  SET_IS_FETCH_TABLE_HITTED: "table/SET_IS_FETCH_TABLE_HITTED",
  SET_IS_FETCH_TABLE_BY_CODE_HITTED: "table/SET_IS_FETCH_TABLE_BY_CODE_HITTED",

  FETCH_TABLE_START: "table/FETCH_TABLE_START",
  FETCH_TABLE_BY_CODE_START: "table/FETCH_TABLE_BY_CODE_START",
};

export default TABLE_ACTION_TYPES;
