import MARKET_ACTION_TYPES from "./market.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(MARKET_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setMarkets = (markets) => createAction(MARKET_ACTION_TYPES.SET_MARKETS, markets);
export const setMarket = (market) => createAction(MARKET_ACTION_TYPES.SET_MARKET, market);

export const setIsMarketsHasMore = (isMarketsHasMore) =>
  createAction(MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE, isMarketsHasMore);

export const setFetchMarketsSearch = (fetchMarketsSearch) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SEARCH, fetchMarketsSearch);
export const setFetchMarketsPage = (fetchMarketsPage) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PAGE, fetchMarketsPage);
export const setFetchMarketsPerPage = (fetchMarketsPerPage) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PER_PAGE, fetchMarketsPerPage);
export const setFetchMarketsIncludes = (fetchMarketsIncludes) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_INCLUDES, fetchMarketsIncludes);
export const setFetchMarketsFilterMarketId = (fetchMarketsFilterMarketId) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MARKET_ID, fetchMarketsFilterMarketId);
export const setFetchMarketsLoading = (fetchMarketsLoading) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING, fetchMarketsLoading);
export const setFetchMarketsSuccess = (fetchMarketsSuccess) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS, fetchMarketsSuccess);
export const setFetchMarketsFailed = (fetchMarketsFailed) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED, fetchMarketsFailed);

export const setFetchMarketLoading = (fetchMarketLoading) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKET_LOADING, fetchMarketLoading);
export const setFetchMarketSuccess = (fetchMarketSuccess) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKET_SUCCESS, fetchMarketSuccess);
export const setFetchMarketFailed = (fetchMarketFailed) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKET_FAILED, fetchMarketFailed);

export const appendMarkets = (markets) => createAction(MARKET_ACTION_TYPES.APPEND_MARKETS, markets);

export const fetchMarketsStart = () => createAction(MARKET_ACTION_TYPES.FETCH_MARKETS_START);
export const fetchMarketStart = (id) => createAction(MARKET_ACTION_TYPES.FETCH_MARKET_START, id);

export const resetMarketReducer = () => createAction(MARKET_ACTION_TYPES.RESET_MARKET_REDUCER);
