const SHORT_URL_ACTION_TYPES = {
  SET_SHORT_URL: "short-url/SET_SHORT_URL",
  SET_CREATE_SHORT_URL: "short-url/SET_CREATE_SHORT_URL",

  SET_FETCH_SHORT_URL_LOADING: "short-url/SET_FETCH_SHORT_URL_LOADING",
  SET_FETCH_SHORT_URL_SUCCESS: "short-url/SET_FETCH_SHORT_URL_SUCCESS",
  SET_FETCH_SHORT_URL_FAILED: "short-url/SET_FETCH_SHORT_URL_FAILED",

  SET_CREATE_SHORT_URL_LOADING: "short-url/SET_CREATE_SHORT_URL_LOADING",
  SET_CREATE_SHORT_URL_SUCCESS: "short-url/SET_CREATE_SHORT_URL_SUCCESS",
  SET_CREATE_SHORT_URL_FAILED: "short-url/SET_CREATE_SHORT_URL_FAILED",

  FETCH_SHORT_URL_START: "short-url/FETCH_SHORT_URL_START",
  CREATE_SHORT_URL_START: "short-url/CREATE_SHORT_URL_START",
};

export default SHORT_URL_ACTION_TYPES;
