const PRODUCT_CATEGORY_ACTION_TYPES = {
  RESET_PRODUCT_CATEGORY_REDUCER: "product-category/RESET_PRODUCT_CATEGORY_REDUCER",

  SET_PRODUCT_CATEGORIES: "product-category/SET_PRODUCT_CATEGORIES",
  SET_PRODUCT_CATEGORY: "product-category/SET_PRODUCT_CATEGORY",

  SET_IS_PRODUCT_CATEGORIES_HAS_MORE: "product-category/SET_IS_PRODUCT_CATEGORIES_HAS_MORE",

  SET_FETCH_PRODUCT_CATEGORIES_SEARCH: "product-category/SET_FETCH_PRODUCT_CATEGORIES_SEARCH",
  SET_FETCH_PRODUCT_CATEGORIES_SORT: "product-category/SET_FETCH_PRODUCT_CATEGORIES_SORT",
  SET_FETCH_PRODUCT_CATEGORIES_PAGE: "product-category/SET_FETCH_PRODUCT_CATEGORIES_PAGE",
  SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE: "product-category/SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE",
  SET_FETCH_PRODUCT_CATEGORIES_INCLUDES: "product-category/SET_FETCH_PRODUCT_CATEGORIES_INCLUDES",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID: "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID: "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID: "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_FETCH_PRODUCT_CATEGORIES_LOADING: "product-category/SET_FETCH_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_PRODUCT_CATEGORIES_SUCCESS: "product-category/SET_FETCH_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_PRODUCT_CATEGORIES_FAILED: "product-category/SET_FETCH_PRODUCT_CATEGORIES_FAILED",

  SET_FETCH_PRODUCT_CATEGORY_LOADING: "product-category/SET_FETCH_PRODUCT_CATEGORY_LOADING",
  SET_FETCH_PRODUCT_CATEGORY_SUCCESS: "product-category/SET_FETCH_PRODUCT_CATEGORY_SUCCESS",
  SET_FETCH_PRODUCT_CATEGORY_FAILED: "product-category/SET_FETCH_PRODUCT_CATEGORY_FAILED",

  APPEND_PRODUCT_CATEGORIES: "product-category/APPEND_PRODUCT_CATEGORIES",

  FETCH_PRODUCT_CATEGORIES_START: "product-category/FETCH_PRODUCT_CATEGORIES_START",
  FETCH_PRODUCT_CATEGORY_START: "product-category/FETCH_PRODUCT_CATEGORY_START",
};

export default PRODUCT_CATEGORY_ACTION_TYPES;
