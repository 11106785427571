const SUMMARY_ACTION_TYPES = {
  RESET_SUMMARY_REDUCER: "summary/RESET_SUMMARY_REDUCER",

  SET_SUMMARY_FINANCIAL_TOTAL: "summary/SET_SUMMARY_FINANCIAL_TOTAL",

  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CUSTOMER_ID: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CUSTOMER_ID",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_LOADING: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_LOADING",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_SUCCESS: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_SUCCESS",
  SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FAILED: "summary/SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FAILED",

  FETCH_SUMMARY_FINANCIAL_TOTAL_START: "summary/FETCH_SUMMARY_FINANCIAL_TOTAL_START",
};

export default SUMMARY_ACTION_TYPES;
