const PAYMENT_METHOD_SETTING_ACTION_TYPES = {
  RESET_PAYMENT_METHOD_SETTING_REDUCER:
    "payment-method-setting/RESET_PAYMENT_METHOD_SETTING_REDUCER",

  SET_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/SET_PAYMENT_METHOD_SETTINGS",

  SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE:
    "payment-method-setting/SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE",

  SET_FETCH_PAYMENT_METHOD_SETTINGS_SEARCH:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_SEARCH",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_KEY_BY:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_KEY_BY",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_PAYMENT_METHOD_KEYS:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_PAYMENT_METHOD_KEYS",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_PRIORITY:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_PRIORITY",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED",

  APPEND_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/APPEND_PAYMENT_METHOD_SETTINGS",

  FETCH_PAYMENT_METHOD_SETTINGS_START:
    "payment-method-setting/FETCH_PAYMENT_METHOD_SETTINGS_START",
};

export default PAYMENT_METHOD_SETTING_ACTION_TYPES;
