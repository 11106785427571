const PRODUCT_ACTION_TYPES = {
  RESET_PRODUCT_REDUCER: "product/RESET_PRODUCT_REDUCER",

  SET_PRODUCTS: "product/SET_PRODUCTS",
  SET_PRODUCT: "product/SET_PRODUCT",

  SET_IS_PRODUCTS_HAS_MORE: "product/SET_IS_PRODUCTS_HAS_MORE",

  SET_FETCH_PRODUCTS_SEARCH: "product/SET_FETCH_PRODUCTS_SEARCH",
  SET_FETCH_PRODUCTS_KEY_BY: "product/SET_FETCH_PRODUCTS_KEY_BY",
  SET_FETCH_PRODUCTS_SORT: "product/SET_FETCH_PRODUCTS_SORT",
  SET_FETCH_PRODUCTS_PAGE: "product/SET_FETCH_PRODUCTS_PAGE",
  SET_FETCH_PRODUCTS_PER_PAGE: "product/SET_FETCH_PRODUCTS_PER_PAGE",
  SET_FETCH_PRODUCTS_INCLUDES: "product/SET_FETCH_PRODUCTS_INCLUDES",
  SET_FETCH_PRODUCTS_FILTER_MARKET_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_MARKET_ID",
  SET_FETCH_PRODUCTS_FILTER_BRANCH_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_BRANCH_ID",
  SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID",
  SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS:
    "product/SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS",
  SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE:
    "product/SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE",
  SET_FETCH_PRODUCTS_LOADING: "product/SET_FETCH_PRODUCTS_LOADING",
  SET_FETCH_PRODUCTS_SUCCESS: "product/SET_FETCH_PRODUCTS_SUCCESS",
  SET_FETCH_PRODUCTS_FAILED: "product/SET_FETCH_PRODUCTS_FAILED",

  SET_FETCH_PRODUCT_LOADING: "product/SET_FETCH_PRODUCT_LOADING",
  SET_FETCH_PRODUCT_SUCCESS: "product/SET_FETCH_PRODUCT_SUCCESS",
  SET_FETCH_PRODUCT_FAILED: "product/SET_FETCH_PRODUCT_FAILED",

  APPEND_PRODUCTS: "product/APPEND_PRODUCTS",

  SET_IS_FETCH_PRODUCTS_HITTED: "product/SET_IS_FETCH_PRODUCTS_HITTED",
  SET_IS_FETCH_PRODUCT_HITTED: "product/SET_IS_FETCH_PRODUCT_HITTED",

  FETCH_PRODUCTS_START: "product/FETCH_PRODUCTS_START",
  FETCH_PRODUCT_START: "product/FETCH_PRODUCT_START",
};

export default PRODUCT_ACTION_TYPES;
