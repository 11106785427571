import { createSelector } from "reselect";

const summarySelector = ({ summary }) => summary;

export const getSummaryFinancialTotal = createSelector(
  [summarySelector],
  ({ summaryFinancialTotal }) => summaryFinancialTotal
);

export const getFetchSummaryFinancialTotalFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterPeriodType }) => fetchSummaryFinancialTotalFilterPeriodType
);

export const getFetchSummaryFinancialTotalFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterStartAt }) => fetchSummaryFinancialTotalFilterStartAt
);

export const getFetchSummaryFinancialTotalFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterEndAt }) => fetchSummaryFinancialTotalFilterEndAt
);

export const getFetchSummaryFinancialTotalFilterCustomerId = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterCustomerId }) => fetchSummaryFinancialTotalFilterCustomerId
);

export const getFetchSummaryFinancialTotalFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterPaymentMethodKey }) => fetchSummaryFinancialTotalFilterPaymentMethodKey
);

export const getFetchSummaryFinancialTotalFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFilterCashierId }) => fetchSummaryFinancialTotalFilterCashierId
);

export const getFetchSummaryFinancialTotalLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalLoading }) => fetchSummaryFinancialTotalLoading
);

export const getFetchSummaryFinancialTotalSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalSuccess }) => fetchSummaryFinancialTotalSuccess
);

export const getFetchSummaryFinancialTotalFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialTotalFailed }) => fetchSummaryFinancialTotalFailed
);
