import { createSelector } from "reselect";

const orderSelector = ({ order }) => order;

export const getCartInputValues = createSelector(
  [orderSelector],
  ({ cartInputValues }) => cartInputValues
);
export const getOrderServiceTypes = createSelector(
  [orderSelector],
  ({ orderServiceTypes }) => orderServiceTypes
);

export const getOrders = createSelector(
  [orderSelector],
  ({ orders }) => orders
);
export const getOrder = createSelector([orderSelector], ({ order }) => order);
export const getCreateOrder = createSelector(
  [orderSelector],
  ({ createOrder }) => createOrder
);
export const getUpdateOrder = createSelector(
  [orderSelector],
  ({ updateOrder }) => updateOrder
);
export const getCalculateOrder = createSelector(
  [orderSelector],
  ({ calculateOrder }) => calculateOrder
);

export const getIsOrdersHasMore = createSelector(
  [orderSelector],
  ({ isOrdersHasMore }) => isOrdersHasMore
);

export const getFetchOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchOrdersSearch }) => fetchOrdersSearch
);
export const getFetchOrdersKeyBy = createSelector(
  [orderSelector],
  ({ fetchOrdersKeyBy }) => fetchOrdersKeyBy
);
export const getFetchOrdersPage = createSelector(
  [orderSelector],
  ({ fetchOrdersPage }) => fetchOrdersPage
);
export const getFetchOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchOrdersPerPage }) => fetchOrdersPerPage
);
export const getFetchOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchOrdersIncludes }) => fetchOrdersIncludes
);
export const getFetchOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterMarketId }) => fetchOrdersFilterMarketId
);
export const getFetchOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterBranchId }) => fetchOrdersFilterBranchId
);
export const getFetchOrdersFilterCustomerId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCustomerId }) => fetchOrdersFilterCustomerId
);
export const getFetchOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterSources }) => fetchOrdersFilterSources
);
export const getFetchOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterTypes }) => fetchOrdersFilterTypes
);
export const getFetchOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterStatuses }) => fetchOrdersFilterStatuses
);
export const getFetchOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCreatedAtBefore }) => fetchOrdersFilterCreatedAtBefore
);
export const getFetchOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCreatedAtAfter }) => fetchOrdersFilterCreatedAtAfter
);
export const getFetchOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchOrdersLoading }) => fetchOrdersLoading
);
export const getFetchOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchOrdersSuccess }) => fetchOrdersSuccess
);
export const getFetchOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchOrdersFailed }) => fetchOrdersFailed
);

export const getFetchOrderLoading = createSelector(
  [orderSelector],
  ({ fetchOrderLoading }) => fetchOrderLoading
);
export const getFetchOrderSuccess = createSelector(
  [orderSelector],
  ({ fetchOrderSuccess }) => fetchOrderSuccess
);
export const getFetchOrderFailed = createSelector(
  [orderSelector],
  ({ fetchOrderFailed }) => fetchOrderFailed
);

export const getCalculateOrderLoading = createSelector(
  [orderSelector],
  ({ calculateOrderLoading }) => calculateOrderLoading
);
export const getCalculateOrderSuccess = createSelector(
  [orderSelector],
  ({ calculateOrderSuccess }) => calculateOrderSuccess
);
export const getCalculateOrderFailed = createSelector(
  [orderSelector],
  ({ calculateOrderFailed }) => calculateOrderFailed
);

export const getCreateOrderLoading = createSelector(
  [orderSelector],
  ({ createOrderLoading }) => createOrderLoading
);
export const getCreateOrderSuccess = createSelector(
  [orderSelector],
  ({ createOrderSuccess }) => createOrderSuccess
);
export const getCreateOrderFailed = createSelector(
  [orderSelector],
  ({ createOrderFailed }) => createOrderFailed
);

export const getUpdateOrderLoading = createSelector(
  [orderSelector],
  ({ updateOrderLoading }) => updateOrderLoading
);
export const getUpdateOrderSuccess = createSelector(
  [orderSelector],
  ({ updateOrderSuccess }) => updateOrderSuccess
);
export const getUpdateOrderFailed = createSelector(
  [orderSelector],
  ({ updateOrderFailed }) => updateOrderFailed
);

export const getIsFetchOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchOrdersHitted }) => isFetchOrdersHitted
);
export const getIsFetchOrderHitted = createSelector(
  [orderSelector],
  ({ isFetchOrderHitted }) => isFetchOrderHitted
);
export const getIsCalculateOrderHitted = createSelector(
  [orderSelector],
  ({ isCalculateOrderHitted }) => isCalculateOrderHitted
);
export const getIsCreateOrderHitted = createSelector(
  [orderSelector],
  ({ isCreateOrderHitted }) => isCreateOrderHitted
);
export const getIsUpdateOrderHitted = createSelector(
  [orderSelector],
  ({ isUpdateOrderHitted }) => isUpdateOrderHitted
);
