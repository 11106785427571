const SYNC_ACTION_TYPES = {
  RESET_SYNC_REDUCER: "sync/RESET_SYNC_REDUCER",

  SET_SYNC_GATEWAY: "sync/SET_SYNC_GATEWAY",

  SET_SYNC_GATEWAY_LOADING: "sync/SET_SYNC_GATEWAY_LOADING",
  SET_SYNC_GATEWAY_SUCCESS: "sync/SET_SYNC_GATEWAY_SUCCESS",
  SET_SYNC_GATEWAY_FAILED: "sync/SET_SYNC_GATEWAY_FAILED",

  SYNC_GATEWAY_START: "sync/SYNC_GATEWAY_START",
};

export default SYNC_ACTION_TYPES;
