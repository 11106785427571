import { isEmpty } from "./validation.utils";

export const createAction = (type, payload) => ({ type, payload });

export const getParamsWithDefault = (params = {}) => {
  if (isEmpty(params?.page)) {
    params.page = 1;
  }
  if (isEmpty(params?.per_page)) {
    params.per_page = 20;
  }

  return params;
};
