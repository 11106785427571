import GATEWAY_ACTION_TYPES from "./gateway.type";
import { createAction } from "../../utils/store.utils";

export const setGateway = (gateway) => createAction(GATEWAY_ACTION_TYPES.SET_GATEWAY, gateway);

export const setCreateGateway = (createGateway) => createAction(GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY, createGateway);

export const setCheckoutGateway = (checkoutGateway) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY, checkoutGateway);

export const setFetchGatewayLoading = (fetchGatewayLoading) =>
  createAction(GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_LOADING, fetchGatewayLoading);

export const setFetchGatewaySuccess = (fetchGatewaySuccess) =>
  createAction(GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_SUCCESS, fetchGatewaySuccess);

export const setFetchGatewayFailed = (fetchGatewayFailed) =>
  createAction(GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_FAILED, fetchGatewayFailed);

export const setCreateGatewayLoading = (createGatewayLoading) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_LOADING, createGatewayLoading);

export const setCreateGatewaySuccess = (createGatewaySuccess) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_SUCCESS, createGatewaySuccess);

export const setCreateGatewayFailed = (createGatewayFailed) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_FAILED, createGatewayFailed);

export const setCheckoutGatewayLoading = (checkoutGatewayLoading) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_LOADING, checkoutGatewayLoading);

export const setCheckoutGatewaySuccess = (checkoutGatewaySuccess) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_SUCCESS, checkoutGatewaySuccess);

export const setCheckoutGatewayFailed = (checkoutGatewayFailed) =>
  createAction(GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_FAILED, checkoutGatewayFailed);

export const fetchGatewayStart = (id) => createAction(GATEWAY_ACTION_TYPES.FETCH_GATEWAY_START, id);

export const createGatewayStart = (request) => createAction(GATEWAY_ACTION_TYPES.CREATE_GATEWAY_START, request);

export const checkoutGatewayStart = (id, request) =>
  createAction(GATEWAY_ACTION_TYPES.CHECKOUT_GATEWAY_START, { id, request });

export const resetGatewayReducer = () => createAction(GATEWAY_ACTION_TYPES.RESET_GATEWAY_REDUCER);
