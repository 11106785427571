import ORDER_PRODUCT_RATING_ACTION_TYPES from "./order-product-rating.type";

export const ORDER_PRODUCT_RATING_INITIAL_STATE = {
  orderProductRatings: [],

  isOrderProductRatingsHasMore: true,

  fetchOrderProductRatingsSearch: null,
  fetchOrderProductRatingsSort: null,
  fetchOrderProductRatingsPage: 1,
  fetchOrderProductRatingsPerPage: null,
  fetchOrderProductRatingsIncludes: null,
  fetchOrderProductRatingsFilterOrderId: null,
  fetchOrderProductRatingsFilterProductId: null,
  fetchOrderProductRatingsFilterRating: null,
  fetchOrderProductRatingsFilterIsShow: null,
  fetchOrderProductRatingsLoading: false,
  fetchOrderProductRatingsSuccess: null,
  fetchOrderProductRatingsFailed: null,

  createOrUpdateOrderProductRatingLoading: false,
  createOrUpdateOrderProductRatingSuccess: null,
  createOrUpdateOrderProductRatingFailed: null,
};

export const orderProductRatingReducer = (state = ORDER_PRODUCT_RATING_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_ORDER_PRODUCT_RATINGS:
      return { ...state, orderProductRatings: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_IS_ORDER_PRODUCT_RATINGS_HAS_MORE:
      return { ...state, isOrderProductRatingsHasMore: payload };

    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SEARCH:
      return { ...state, fetchOrderProductRatingsSearch: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SORT:
      return { ...state, fetchOrderProductRatingsSort: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_PAGE:
      return { ...state, fetchOrderProductRatingsPage: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_PER_PAGE:
      return { ...state, fetchOrderProductRatingsPerPage: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_INCLUDES:
      return { ...state, fetchOrderProductRatingsIncludes: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_ORDER_ID:
      return { ...state, fetchOrderProductRatingsFilterOrderId: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_PRODUCT_ID:
      return { ...state, fetchOrderProductRatingsFilterProductId: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_RATING:
      return { ...state, fetchOrderProductRatingsFilterRating: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_IS_SHOW:
      return { ...state, fetchOrderProductRatingsFilterIsShow: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_LOADING:
      return { ...state, fetchOrderProductRatingsLoading: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SUCCESS:
      return { ...state, fetchOrderProductRatingsSuccess: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FAILED:
      return { ...state, fetchOrderProductRatingsFailed: payload };

    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_LOADING:
      return { ...state, createOrUpdateOrderProductRatingLoading: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_SUCCESS:
      return { ...state, createOrUpdateOrderProductRatingSuccess: payload };
    case ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_FAILED:
      return { ...state, createOrUpdateOrderProductRatingFailed: payload };

    case ORDER_PRODUCT_RATING_ACTION_TYPES.APPEND_ORDER_PRODUCT_RATINGS:
      return { ...state, orderProductRatings: [...state.orderProductRatings, ...payload] };

    case ORDER_PRODUCT_RATING_ACTION_TYPES.RESET_ORDER_PRODUCT_RATING_REDUCER:
      return ORDER_PRODUCT_RATING_INITIAL_STATE;
    default:
      return state;
  }
};
