import { createSelector } from "reselect";

const productSelector = ({ product }) => product;

export const getProducts = createSelector(
  [productSelector],
  ({ products }) => products
);
export const getProduct = createSelector(
  [productSelector],
  ({ product }) => product
);

export const getIsProductsHasMore = createSelector(
  [productSelector],
  ({ isProductsHasMore }) => isProductsHasMore
);

export const getFetchProductsSearch = createSelector(
  [productSelector],
  ({ fetchProductsSearch }) => fetchProductsSearch
);
export const getFetchProductsKeyBy = createSelector(
  [productSelector],
  ({ fetchProductsKeyBy }) => fetchProductsKeyBy
);
export const getFetchProductsSort = createSelector(
  [productSelector],
  ({ fetchProductsSort }) => fetchProductsSort
);
export const getFetchProductsPage = createSelector(
  [productSelector],
  ({ fetchProductsPage }) => fetchProductsPage
);
export const getFetchProductsPerPage = createSelector(
  [productSelector],
  ({ fetchProductsPerPage }) => fetchProductsPerPage
);
export const getFetchProductsIncludes = createSelector(
  [productSelector],
  ({ fetchProductsIncludes }) => fetchProductsIncludes
);
export const getFetchProductsFilterMarketId = createSelector(
  [productSelector],
  ({ fetchProductsFilterMarketId }) => fetchProductsFilterMarketId
);
export const getFetchProductsFilterBranchId = createSelector(
  [productSelector],
  ({ fetchProductsFilterBranchId }) => fetchProductsFilterBranchId
);
export const getFetchProductsFilterProductCategoryId = createSelector(
  [productSelector],
  ({ fetchProductsFilterProductCategoryId }) =>
    fetchProductsFilterProductCategoryId
);
export const getFetchProductsFilterProductsIds = createSelector(
  [productSelector],
  ({ fetchProductsFilterProductsIds }) => fetchProductsFilterProductsIds
);
export const getFetchProductsFilterIsActive = createSelector(
  [productSelector],
  ({ fetchProductsFilterIsActive }) => fetchProductsFilterIsActive
);
export const getFetchProductsLoading = createSelector(
  [productSelector],
  ({ fetchProductsLoading }) => fetchProductsLoading
);
export const getFetchProductsSuccess = createSelector(
  [productSelector],
  ({ fetchProductsSuccess }) => fetchProductsSuccess
);
export const getFetchProductsFailed = createSelector(
  [productSelector],
  ({ fetchProductsFailed }) => fetchProductsFailed
);

export const getFetchProductLoading = createSelector(
  [productSelector],
  ({ fetchProductLoading }) => fetchProductLoading
);
export const getFetchProductSuccess = createSelector(
  [productSelector],
  ({ fetchProductSuccess }) => fetchProductSuccess
);
export const getFetchProductFailed = createSelector(
  [productSelector],
  ({ fetchProductFailed }) => fetchProductFailed
);

export const getIsFetchProductsHitted = createSelector(
  [productSelector],
  ({ isFetchProductsHitted }) => isFetchProductsHitted
);
export const getIsFetchProductHitted = createSelector(
  [productSelector],
  ({ isFetchProductHitted }) => isFetchProductHitted
);
