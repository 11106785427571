const CART_ACTION_TYPES = {
  RESET_CART_REDUCER: "cart/RESET_CART_REDUCER",

  SET_CART_MARKET_ID: "cart/SET_CART_MARKET_ID",
  SET_CART_BRANCH_ID: "cart/SET_CART_BRANCH_ID",
  SET_CART_SOURCE: "cart/SET_CART_SOURCE",
  SET_CART_TYPE: "cart/SET_CART_TYPE",
  SET_CART_STATUS: "cart/SET_CART_STATUS",
  SET_CART_TABLE_ID: "cart/SET_CART_TABLE_ID",
  SET_CART_CUSTOMER_ID: "cart/SET_CART_CUSTOMER_ID",
  SET_CART_DISCOUNT_ID: "cart/SET_CART_DISCOUNT_ID",
  SET_CART_NOTES: "cart/SET_CART_NOTES",
  SET_CART_PRODUCTS: "cart/SET_CART_PRODUCTS",
  SET_CART_IS_IGNORE_INGREDIENT: "cart/SET_CART_IS_IGNORE_INGREDIENT",

  INCREASE_PRODUCT_QTY: "cart/INCREASE_PRODUCT_QTY",
  DECREASE_PRODUCT_QTY: "cart/DECREASE_PRODUCT_QTY",
};

export default CART_ACTION_TYPES;
