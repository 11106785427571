import { createSelector } from "reselect";

const productCategorySelector = ({ productCategory }) => productCategory;

export const getProductCategories = createSelector(
  [productCategorySelector],
  ({ productCategories }) => productCategories
);

export const getProductCategory = createSelector([productCategorySelector], ({ productCategory }) => productCategory);

export const getIsProductCategoriesHasMore = createSelector(
  [productCategorySelector],
  ({ isProductCategoriesHasMore }) => isProductCategoriesHasMore
);

export const getFetchProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSearch }) => fetchProductCategoriesSearch
);

export const getFetchProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSort }) => fetchProductCategoriesSort
);

export const getFetchProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesPage }) => fetchProductCategoriesPage
);

export const getFetchProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesPerPage }) => fetchProductCategoriesPerPage
);

export const getFetchProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesIncludes }) => fetchProductCategoriesIncludes
);

export const getFetchProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterSectorId }) => fetchProductCategoriesFilterSectorId
);

export const getFetchProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterMarketId }) => fetchProductCategoriesFilterMarketId
);

export const getFetchProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterBranchId }) => fetchProductCategoriesFilterBranchId
);

export const getFetchProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesLoading }) => fetchProductCategoriesLoading
);

export const getFetchProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSuccess }) => fetchProductCategoriesSuccess
);

export const getFetchProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFailed }) => fetchProductCategoriesFailed
);

export const getFetchProductCategoryLoading = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoryLoading }) => fetchProductCategoryLoading
);

export const getFetchProductCategorySuccess = createSelector(
  [productCategorySelector],
  ({ fetchProductCategorySuccess }) => fetchProductCategorySuccess
);

export const getFetchProductCategoryFailed = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoryFailed }) => fetchProductCategoryFailed
);
