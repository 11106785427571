import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SUMMARY_ACTION_TYPES from "./summary.type";

import {
  setSummaryFinancialTotal,
  setFetchSummaryFinancialTotalFailed,
  setFetchSummaryFinancialTotalLoading,
  setFetchSummaryFinancialTotalSuccess,
} from "./summary.action";
import {
  getFetchSummaryFinancialTotalFilterCashierId,
  getFetchSummaryFinancialTotalFilterCustomerId,
  getFetchSummaryFinancialTotalFilterEndAt,
  getFetchSummaryFinancialTotalFilterPaymentMethodKey,
  getFetchSummaryFinancialTotalFilterPeriodType,
  getFetchSummaryFinancialTotalFilterStartAt,
} from "./summary.selector";

import { getSummaryFinancialTotal } from "../../api/summary.api";

export function* _getSummaryFinancialTotal() {
  try {
    yield put(setFetchSummaryFinancialTotalLoading(true));

    const period_type = yield select(getFetchSummaryFinancialTotalFilterPeriodType);
    const customer_id = yield select(getFetchSummaryFinancialTotalFilterCustomerId);
    const payment_method_key = yield select(getFetchSummaryFinancialTotalFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummaryFinancialTotalFilterCashierId);
    const start_at = yield select(getFetchSummaryFinancialTotalFilterStartAt);
    const end_at = yield select(getFetchSummaryFinancialTotalFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        customer_id,
        payment_method_key,
        cashier_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: total,
    } = yield call(getSummaryFinancialTotal, parameters);

    yield put(setSummaryFinancialTotal(total));

    yield put(setFetchSummaryFinancialTotalSuccess(message));
    yield put(setFetchSummaryFinancialTotalLoading(false));
  } catch (error) {
    yield put(setFetchSummaryFinancialTotalFailed(error));
    yield put(setFetchSummaryFinancialTotalLoading(false));
  }
}

export function* onFetchSummaryFinancialTotal() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_FINANCIAL_TOTAL_START, _getSummaryFinancialTotal);
}

export function* summarySaga() {
  yield all([call(onFetchSummaryFinancialTotal)]);
}
