import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ORDER_ACTION_TYPES from "./order.type";

import {
  appendOrders,
  setCalculateOrder,
  setCalculateOrderFailed,
  setCalculateOrderLoading,
  setCalculateOrderSuccess,
  setCreateOrder,
  setCreateOrderFailed,
  setCreateOrderLoading,
  setCreateOrderSuccess,
  setFetchOrderFailed,
  setFetchOrderLoading,
  setFetchOrdersFailed,
  setFetchOrdersLoading,
  setFetchOrdersPage,
  setFetchOrdersSuccess,
  setFetchOrderSuccess,
  setIsCalculateOrderHitted,
  setIsCreateOrderHitted,
  setIsFetchOrderHitted,
  setIsFetchOrdersHitted,
  setIsOrdersHasMore,
  setIsUpdateOrderHitted,
  setOrder,
  setOrders,
  setUpdateOrder,
  setUpdateOrderFailed,
  setUpdateOrderLoading,
  setUpdateOrderSuccess,
} from "./order.action";
import {
  getFetchOrdersFilterBranchId,
  getFetchOrdersFilterCustomerId,
  getFetchOrdersFilterMarketId,
  getFetchOrdersFilterSources,
  getFetchOrdersFilterStatuses,
  getFetchOrdersFilterTypes,
  getFetchOrdersIncludes,
  getFetchOrdersKeyBy,
  getFetchOrdersPage,
  getFetchOrdersPerPage,
  getFetchOrdersSearch,
  getIsFetchOrdersHitted,
} from "./order.selector";

import {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  calculateOrder,
} from "../../api/order.api";

export function* _getOrders() {
  try {
    yield put(setFetchOrdersLoading(true));

    const search = yield select(getFetchOrdersSearch);
    const key_by = yield select(getFetchOrdersKeyBy);
    const page = yield select(getFetchOrdersPage);
    const per_page = yield select(getFetchOrdersPerPage);
    const includes = yield select(getFetchOrdersIncludes);
    const market_id = yield select(getFetchOrdersFilterMarketId);
    const branch_id = yield select(getFetchOrdersFilterBranchId);
    const customer_id = yield select(getFetchOrdersFilterCustomerId);
    const sources = yield select(getFetchOrdersFilterSources);
    const types = yield select(getFetchOrdersFilterTypes);
    const statuses = yield select(getFetchOrdersFilterStatuses);

    const parameters = {
      search,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        customer_id,
        sources,
        types,
        statuses,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchOrdersHitted(true));
    yield put(setIsOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendOrders(orders));
    } else {
      yield put(setOrders(orders));
    }

    yield put(setFetchOrdersSuccess(message));
    yield put(setFetchOrdersLoading(false));
  } catch (error) {
    yield put(setFetchOrdersFailed(error));
    yield put(setFetchOrdersLoading(false));
  }
}
export function* _getOrder({ payload: orderId }) {
  try {
    yield put(setFetchOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(getOrder, orderId);

    yield put(setIsFetchOrderHitted(true));
    yield put(setOrder(order));

    yield put(setFetchOrderSuccess(message));
    yield put(setFetchOrderLoading(false));
  } catch (error) {
    yield put(setFetchOrderFailed(error));
    yield put(setFetchOrderLoading(false));
  }
}
export function* _calculateOrder({ payload: request }) {
  try {
    yield put(setCalculateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(calculateOrder, request);

    yield put(setIsCalculateOrderHitted(true));
    yield put(setCalculateOrder(order));

    yield put(setCalculateOrderSuccess(message));
    yield put(setCalculateOrderLoading(false));
  } catch (error) {
    yield put(setCalculateOrderFailed(error));
    yield put(setCalculateOrderLoading(false));
  }
}

export function* _createOrder({ payload: request }) {
  try {
    yield put(setCreateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(createOrder, request);

    yield put(setIsCreateOrderHitted(true));
    yield put(setCreateOrder(order));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }

    yield put(setCreateOrderSuccess(message));
    yield put(setCreateOrderLoading(false));
  } catch (error) {
    yield put(setCreateOrderFailed(error));
    yield put(setCreateOrderLoading(false));
  }
}

export function* _updateOrder({ payload: { orderId, request } }) {
  try {
    yield put(setUpdateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(updateOrder, orderId, request);

    yield put(setIsUpdateOrderHitted(true));
    yield put(setUpdateOrder(order));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }

    yield put(setUpdateOrderSuccess(message));
    yield put(setUpdateOrderLoading(false));
  } catch (error) {
    yield put(setUpdateOrderFailed(error));
    yield put(setUpdateOrderLoading(false));
  }
}

export function* onFetchOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_ORDERS_START, _getOrders);
}
export function* onFetchOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_ORDER_START, _getOrder);
}
export function* onCalculateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CALCULATE_ORDER_START, _calculateOrder);
}
export function* onCreateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CREATE_ORDER_START, _createOrder);
}
export function* onUpdateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_ORDER_START, _updateOrder);
}

export function* orderSaga() {
  yield all([
    call(onFetchOrdersStart),
    call(onFetchOrderStart),
    call(onCalculateOrderStart),
    call(onCreateOrderStart),
    call(onUpdateOrderStart),
  ]);
}
