import { takeLatest, put, all, call, select } from "redux-saga/effects";

import CUSTOMER_ACTION_TYPES from "./customer.type";

import {
  appendCustomers,
  setCreateCustomerFailed,
  setCreateCustomerLoading,
  setCreateCustomerSuccess,
  setCustomer,
  setCustomers,
  setFetchCustomerFailed,
  setFetchCustomerLoading,
  setFetchCustomersFailed,
  setFetchCustomersLoading,
  setFetchCustomersPage,
  setFetchCustomersSuccess,
  setFetchCustomerSuccess,
  setFetchFindCustomerFailed,
  setFetchFindCustomerLoading,
  setFetchFindCustomerSuccess,
  setFoundCustomer,
  setIsCustomersHasMore,
  setSelectCustomer,
  setUpdateCustomerFailed,
  setUpdateCustomerLoading,
  setUpdateCustomerSuccess,
} from "./customer.action";
import {
  getFetchCustomersFilterMarketId,
  getFetchCustomersIncludes,
  getFetchCustomersPage,
  getFetchCustomersPerPage,
  getFetchCustomersSearch,
} from "./customer.selector";

import { getCustomers, getCustomer, findCustomer, createCustomer, updateCustomer } from "../../api/customer.api";

export function* _getCustomers() {
  try {
    yield put(setFetchCustomersLoading(true));

    const search = yield select(getFetchCustomersSearch);
    const page = yield select(getFetchCustomersPage);
    const per_page = yield select(getFetchCustomersPerPage);
    const includes = yield select(getFetchCustomersIncludes);
    const market_id = yield select(getFetchCustomersFilterMarketId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: customers },
    } = yield call(getCustomers, parameters);

    yield put(setIsCustomersHasMore(customers.length > 0));

    if (page > 1) {
      yield put(appendCustomers(customers));
    } else {
      yield put(setCustomers(customers));
    }

    yield put(setFetchCustomersSuccess(message));
    yield put(setFetchCustomersLoading(false));
  } catch (error) {
    yield put(setFetchCustomersFailed(error));
    yield put(setFetchCustomersLoading(false));
  }
}

export function* _getCustomer({ payload: id }) {
  try {
    yield put(setFetchCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(getCustomer, id);

    yield put(setCustomer(customer));

    yield put(setFetchCustomerSuccess(message));
    yield put(setFetchCustomerLoading(false));
  } catch (error) {
    yield put(setFetchCustomerFailed(error));
    yield put(setFetchCustomerLoading(false));
  }
}

export function* _findCustomer({ payload: phone }) {
  try {
    yield put(setFetchFindCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(findCustomer, phone);

    yield put(setFoundCustomer(customer));

    yield put(setFetchFindCustomerSuccess(message));
    yield put(setFetchFindCustomerLoading(false));
  } catch (error) {
    yield put(setFetchFindCustomerFailed(error));
    yield put(setFetchFindCustomerLoading(false));
  }
}

export function* _createCustomer({ payload: request }) {
  try {
    yield put(setCreateCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(createCustomer, request);

    yield put(setSelectCustomer(customer));

    yield put(setCreateCustomerSuccess(message));
    yield put(setCreateCustomerLoading(false));

    yield put(setFetchCustomersPage(1));
    yield call(_getCustomers);
  } catch (error) {
    yield put(setCreateCustomerFailed(error));
    yield put(setCreateCustomerLoading(false));
  }
}

export function* _updateCustomer({ payload: { id, request } }) {
  try {
    yield put(setUpdateCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(updateCustomer, id, request);

    yield put(setSelectCustomer(customer));

    yield put(setUpdateCustomerSuccess(message));
    yield put(setUpdateCustomerLoading(false));

    yield put(setFetchCustomersPage(1));
    yield call(_getCustomers);
  } catch (error) {
    yield put(setUpdateCustomerFailed(error));
    yield put(setUpdateCustomerLoading(false));
  }
}

export function* onFetchCustomersStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START, _getCustomers);
}

export function* onFetchCustomerStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, _getCustomer);
}

export function* onFetchFindCustomerStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.FETCH_FIND_CUSTOMER_START, _findCustomer);
}

export function* onCreateCustomerStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.CREATE_CUSTOMER_START, _createCustomer);
}

export function* onUpdateCustomerStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START, _updateCustomer);
}

export function* customerSaga() {
  yield all([
    call(onFetchCustomersStart),
    call(onFetchCustomerStart),
    call(onFetchFindCustomerStart),
    call(onCreateCustomerStart),
    call(onUpdateCustomerStart),
  ]);
}
