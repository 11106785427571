const DISCOUNT_ACTION_TYPES = {
  RESET_DISCOUNT_REDUCER: "discount/RESET_DISCOUNT_REDUCER",

  SET_DISCOUNT_TAB: "discount/SET_DISCOUNT_TAB",
  SET_DISCOUNTS: "discount/SET_DISCOUNTS",
  SET_DISCOUNT: "discount/SET_DISCOUNT",
  SET_VERIFY_COUPON_DISCOUNT: "discount/SET_VERIFY_COUPON_DISCOUNT",

  SET_IS_DISCOUNTS_HAS_MORE: "discount/SET_IS_DISCOUNTS_HAS_MORE",

  SET_FETCH_DISCOUNTS_SEARCH: "discount/SET_FETCH_DISCOUNTS_SEARCH",
  SET_FETCH_DISCOUNTS_PAGE: "discount/SET_FETCH_DISCOUNTS_PAGE",
  SET_FETCH_DISCOUNTS_PER_PAGE: "discount/SET_FETCH_DISCOUNTS_PER_PAGE",
  SET_FETCH_DISCOUNTS_INCLUDES: "discount/SET_FETCH_DISCOUNTS_INCLUDES",
  SET_FETCH_DISCOUNTS_FILTER_CUSTOMER_ID: "discount/SET_FETCH_DISCOUNTS_FILTER_CUSTOMER_ID",
  SET_FETCH_DISCOUNTS_FILTER_TYPES: "discount/SET_FETCH_DISCOUNTS_FILTER_TYPES",
  SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT: "discount/SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT",
  SET_FETCH_DISCOUNTS_LOADING: "discount/SET_FETCH_DISCOUNTS_LOADING",
  SET_FETCH_DISCOUNTS_SUCCESS: "discount/SET_FETCH_DISCOUNTS_SUCCESS",
  SET_FETCH_DISCOUNTS_FAILED: "discount/SET_FETCH_DISCOUNTS_FAILED",

  SET_FETCH_DISCOUNT_LOADING: "discount/SET_FETCH_DISCOUNT_LOADING",
  SET_FETCH_DISCOUNT_SUCCESS: "discount/SET_FETCH_DISCOUNT_SUCCESS",
  SET_FETCH_DISCOUNT_FAILED: "discount/SET_FETCH_DISCOUNT_FAILED",

  SET_VERIFY_COUPON_DISCOUNT_LOADING: "discount/SET_VERIFY_COUPON_DISCOUNT_LOADING",
  SET_VERIFY_COUPON_DISCOUNT_SUCCESS: "discount/SET_VERIFY_COUPON_DISCOUNT_SUCCESS",
  SET_VERIFY_COUPON_DISCOUNT_FAILED: "discount/SET_VERIFY_COUPON_DISCOUNT_FAILED",

  APPEND_DISCOUNTS: "discount/APPEND_DISCOUNTS",

  FETCH_DISCOUNTS_START: "discount/FETCH_DISCOUNTS_START",
  FETCH_DISCOUNT_START: "discount/FETCH_DISCOUNT_START",
  VERIFY_COUPON_DISCOUNT_START: "discount/VERIFY_COUPON_DISCOUNT_START",
};

export default DISCOUNT_ACTION_TYPES;
