import SCHEDULE_ACTION_TYPES from "./schedule.type";
import { createAction } from "../../utils/store.utils";

export const setSchedules = (schedules) => createAction(SCHEDULE_ACTION_TYPES.SET_SCHEDULES, schedules);

export const setFetchSchedulesIncludes = (fetchSchedulesIncludes) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_INCLUDES, fetchSchedulesIncludes);

export const setFetchSchedulesFilterMarketId = (fetchSchedulesFilterMarketId) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_MARKET_ID, fetchSchedulesFilterMarketId);

export const setFetchSchedulesFilterBranchId = (fetchSchedulesFilterBranchId) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_BRANCH_ID, fetchSchedulesFilterBranchId);

export const setFetchSchedulesLoading = (fetchSchedulesLoading) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_LOADING, fetchSchedulesLoading);

export const setFetchSchedulesSuccess = (fetchSchedulesSuccess) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_SUCCESS, fetchSchedulesSuccess);

export const setFetchSchedulesFailed = (fetchSchedulesFailed) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FAILED, fetchSchedulesFailed);

export const fetchSchedulesStart = () => createAction(SCHEDULE_ACTION_TYPES.FETCH_SCHEDULES_START);

export const resetScheduleReducer = () => createAction(SCHEDULE_ACTION_TYPES.RESET_SCHEDULE_REDUCER);
