import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ORDER_PRODUCT_RATING_ACTION_TYPES from "./order-product-rating.type";

import {
  appendOrderProductRatings,
  setCreateOrUpdateOrderProductRatingFailed,
  setCreateOrUpdateOrderProductRatingLoading,
  setCreateOrUpdateOrderProductRatingSuccess,
  setFetchOrderProductRatingsFailed,
  setFetchOrderProductRatingsLoading,
  setFetchOrderProductRatingsSuccess,
  setIsOrderProductRatingsHasMore,
  setOrderProductRatings,
} from "./order-product-rating.action";
import {
  getFetchOrderProductRatingsFilterIsShow,
  getFetchOrderProductRatingsFilterOrderId,
  getFetchOrderProductRatingsFilterProductId,
  getFetchOrderProductRatingsFilterRating,
  getFetchOrderProductRatingsIncludes,
  getFetchOrderProductRatingsPage,
  getFetchOrderProductRatingsPerPage,
  getFetchOrderProductRatingsSearch,
  getFetchOrderProductRatingsSort,
} from "./order-product-rating.selector";

import { createOrUpdateOrderProductRating, getOrderProductRatings } from "../../api/order-product-rating.api";

export function* _getOrderProductRatings() {
  try {
    yield put(setFetchOrderProductRatingsLoading(true));

    const search = yield select(getFetchOrderProductRatingsSearch);
    const sort = yield select(getFetchOrderProductRatingsSort);
    const page = yield select(getFetchOrderProductRatingsPage);
    const per_page = yield select(getFetchOrderProductRatingsPerPage);
    const includes = yield select(getFetchOrderProductRatingsIncludes);
    const order_id = yield select(getFetchOrderProductRatingsFilterOrderId);
    const product_id = yield select(getFetchOrderProductRatingsFilterProductId);
    const rating = yield select(getFetchOrderProductRatingsFilterRating);
    const is_show = yield select(getFetchOrderProductRatingsFilterIsShow);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        order_id,
        product_id,
        rating,
        is_show,
      },
    };

    const {
      meta: { message },
      data: { data: orderProductRatings },
    } = yield call(getOrderProductRatings, parameters);

    yield put(setIsOrderProductRatingsHasMore(orderProductRatings.length > 0));

    if (page > 1) {
      yield put(appendOrderProductRatings(orderProductRatings));
    } else {
      yield put(setOrderProductRatings(orderProductRatings));
    }

    yield put(setFetchOrderProductRatingsSuccess(message));
    yield put(setFetchOrderProductRatingsLoading(false));
  } catch (error) {
    yield put(setFetchOrderProductRatingsFailed(error));
    yield put(setFetchOrderProductRatingsLoading(false));
  }
}

export function* _createOrUpdateOrderProductRating({ payload: { orderId, orderProductId, request } }) {
  try {
    yield put(setCreateOrUpdateOrderProductRatingLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateOrderProductRating, orderId, orderProductId, request);

    yield put(setCreateOrUpdateOrderProductRatingSuccess(message));
    yield put(setCreateOrUpdateOrderProductRatingLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateOrderProductRatingFailed(error));
    yield put(setCreateOrUpdateOrderProductRatingLoading(false));
  }
}

export function* onFetchOrderProductRatingsStart() {
  yield takeLatest(ORDER_PRODUCT_RATING_ACTION_TYPES.FETCH_ORDER_PRODUCT_RATINGS_START, _getOrderProductRatings);
}

export function* onCreateOrUpdateOrderProductRatingStart() {
  yield takeLatest(
    ORDER_PRODUCT_RATING_ACTION_TYPES.CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_START,
    _createOrUpdateOrderProductRating
  );
}

export function* orderProductRatingSaga() {
  yield all([call(onFetchOrderProductRatingsStart), call(onCreateOrUpdateOrderProductRatingStart)]);
}
