const ORDER_ACTION_TYPES = {
  SET_CART_INPUT_VALUES: "order/SET_CART_INPUT_VALUES",

  SET_ORDERS: "order/SET_ORDERS",
  SET_ORDER: "order/SET_ORDER",
  SET_CREATE_ORDER: "order/SET_CREATE_ORDER",
  SET_UPDATE_ORDER: "order/SET_UPDATE_ORDER",
  SET_CALCULATE_ORDER: "order/SET_CALCULATE_ORDER",

  SET_IS_ORDERS_HAS_MORE: "order/SET_IS_ORDERS_HAS_MORE",

  SET_FETCH_ORDERS_SEARCH: "order/SET_FETCH_ORDERS_SEARCH",
  SET_FETCH_ORDERS_KEY_BY: "order/SET_FETCH_ORDERS_KEY_BY",
  SET_FETCH_ORDERS_PAGE: "order/SET_FETCH_ORDERS_PAGE",
  SET_FETCH_ORDERS_PER_PAGE: "order/SET_FETCH_ORDERS_PER_PAGE",
  SET_FETCH_ORDERS_INCLUDES: "order/SET_FETCH_ORDERS_INCLUDES",
  SET_FETCH_ORDERS_FILTER_MARKET_ID: "order/SET_FETCH_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_ORDERS_FILTER_BRANCH_ID: "order/SET_FETCH_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_ORDERS_FILTER_CUSTOMER_ID:
    "order/SET_FETCH_ORDERS_FILTER_CUSTOMER_ID",
  SET_FETCH_ORDERS_FILTER_SOURCES: "order/SET_FETCH_ORDERS_FILTER_SOURCES",
  SET_FETCH_ORDERS_FILTER_TYPES: "order/SET_FETCH_ORDERS_FILTER_TYPES",
  SET_FETCH_ORDERS_FILTER_STATUSES: "order/SET_FETCH_ORDERS_FILTER_STATUSES",
  SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_ORDERS_LOADING: "order/SET_FETCH_ORDERS_LOADING",
  SET_FETCH_ORDERS_SUCCESS: "order/SET_FETCH_ORDERS_SUCCESS",
  SET_FETCH_ORDERS_FAILED: "order/SET_FETCH_ORDERS_FAILED",

  SET_FETCH_ORDER_LOADING: "order/SET_FETCH_ORDER_LOADING",
  SET_FETCH_ORDER_SUCCESS: "order/SET_FETCH_ORDER_SUCCESS",
  SET_FETCH_ORDER_FAILED: "order/SET_FETCH_ORDER_FAILED",

  SET_CALCULATE_ORDER_LOADING: "order/SET_CALCULATE_ORDER_LOADING",
  SET_CALCULATE_ORDER_SUCCESS: "order/SET_CALCULATE_ORDER_SUCCESS",
  SET_CALCULATE_ORDER_FAILED: "order/SET_CALCULATE_ORDER_FAILED",

  SET_CREATE_ORDER_LOADING: "order/SET_CREATE_ORDER_LOADING",
  SET_CREATE_ORDER_SUCCESS: "order/SET_CREATE_ORDER_SUCCESS",
  SET_CREATE_ORDER_FAILED: "order/SET_CREATE_ORDER_FAILED",

  SET_UPDATE_ORDER_LOADING: "order/SET_UPDATE_ORDER_LOADING",
  SET_UPDATE_ORDER_SUCCESS: "order/SET_UPDATE_ORDER_SUCCESS",
  SET_UPDATE_ORDER_FAILED: "order/SET_UPDATE_ORDER_FAILED",

  APPEND_ORDERS: "order/APPEND_ORDERS",

  SET_IS_FETCH_ORDERS_HITTED: "order/SET_IS_FETCH_ORDERS_HITTED",
  SET_IS_FETCH_ORDER_HITTED: "order/SET_IS_FETCH_ORDER_HITTED",
  SET_IS_CALCULATE_ORDER_HITTED: "order/SET_IS_CALCULATE_ORDER_HITTED",
  SET_IS_CREATE_ORDER_HITTED: "order/SET_IS_CREATE_ORDER_HITTED",
  SET_IS_UPDATE_ORDER_HITTED: "order/SET_IS_UPDATE_ORDER_HITTED",

  FETCH_ORDERS_START: "order/FETCH_ORDERS_START",
  FETCH_ORDER_START: "order/FETCH_ORDER_START",
  CALCULATE_ORDER_START: "order/CALCULATE_ORDER_START",
  CREATE_ORDER_START: "order/CREATE_ORDER_START",
  UPDATE_ORDER_START: "order/UPDATE_ORDER_START",
};

export default ORDER_ACTION_TYPES;
