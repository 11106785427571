import { all, call } from "redux-saga/effects";

import { authenticationSaga } from "./authentication/authentication.saga";
import { branchSaga } from "./branch/branch.saga";
import { customerSaga } from "./customer/customer.saga";
import { discountSaga } from "./discount/discount.saga";
import { gatewaySaga } from "./gateway/gateway.saga";
import { marketSaga } from "./market/market.saga";
import { orderSaga } from "./order/order.saga";
import { orderProductRatingSaga } from "./order-product-rating/order-product-rating.saga";
import { shortUrlSaga } from "./short-url/short-url.saga";
import { summarySaga } from "./summary/summary.saga";
import { syncSaga } from "./synchronize/synchronize.saga";
import { tableSaga } from "./table/table.saga";
import { paymentMethodSettingSaga } from "./payment-method-setting/payment-method-setting.saga";
import { productCategorySaga } from "./product-category/product-category.saga";
import { productSaga } from "./product/product.saga";
import { scheduleSaga } from "./schedule/schedule.saga";
import { verificationSaga } from "./verification/verification.saga";
// import { tableSaga } from "./table/table.saga";
// import { termConditionSaga } from "./term-condition/term-condition.saga";

export function* rootSaga() {
  yield all([
    call(authenticationSaga),
    call(branchSaga),
    call(customerSaga),
    call(discountSaga),
    call(gatewaySaga),
    call(marketSaga),
    call(orderSaga),
    call(orderProductRatingSaga),
    call(shortUrlSaga),
    call(summarySaga),
    call(syncSaga),
    call(tableSaga),
    call(paymentMethodSettingSaga),
    call(productCategorySaga),
    call(productSaga),
    call(scheduleSaga),
    call(verificationSaga),
    // call(tableSaga),
    // call(termConditionSaga),
  ]);
}
