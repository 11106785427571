import { takeLatest, put, all, call } from "redux-saga/effects";

import GATEWAY_ACTION_TYPES from "./gateway.type";

import {
  setCheckoutGateway,
  setCheckoutGatewayFailed,
  setCheckoutGatewayLoading,
  setCheckoutGatewaySuccess,
  setCreateGateway,
  setCreateGatewayFailed,
  setCreateGatewayLoading,
  setCreateGatewaySuccess,
  setFetchGatewayFailed,
  setFetchGatewayLoading,
  setFetchGatewaySuccess,
  setGateway,
} from "./gateway.action";

import { getGateway, createGateway, checkoutGateway } from "../../api/gateway.api";

export function* _getGateway({ payload: id }) {
  try {
    yield put(setFetchGatewayLoading(true));

    const {
      meta: { message },
      data: gateway,
    } = yield call(getGateway, id);

    yield put(setGateway(gateway));

    yield put(setFetchGatewaySuccess(message));
    yield put(setFetchGatewayLoading(false));
  } catch (error) {
    yield put(setFetchGatewayFailed(error));
    yield put(setFetchGatewayLoading(false));
  }
}

export function* _createGateway({ payload: request }) {
  try {
    yield put(setCreateGatewayLoading(true));

    const {
      meta: { message },
      data: gateway,
    } = yield call(createGateway, request);

    yield put(setCreateGateway(gateway));

    yield put(setCreateGatewaySuccess(message));
    yield put(setCreateGatewayLoading(false));
  } catch (error) {
    yield put(setCreateGatewayFailed(error));
    yield put(setCreateGatewayLoading(false));
  }
}

export function* _checkoutGateway({ payload: { id, request } }) {
  try {
    yield put(setCheckoutGatewayLoading(true));

    const {
      meta: { message },
      data: gateway,
    } = yield call(checkoutGateway, id, request);

    yield put(setCheckoutGateway(gateway));

    yield put(setCheckoutGatewaySuccess(message));
    yield put(setCheckoutGatewayLoading(false));
  } catch (error) {
    yield put(setCheckoutGatewayFailed(error));
    yield put(setCheckoutGatewayLoading(false));
  }
}

export function* onFetchGatewayStart() {
  yield takeLatest(GATEWAY_ACTION_TYPES.FETCH_GATEWAY_START, _getGateway);
}

export function* onCreateGatewayStart() {
  yield takeLatest(GATEWAY_ACTION_TYPES.CREATE_GATEWAY_START, _createGateway);
}

export function* onCheckoutGatewayStart() {
  yield takeLatest(GATEWAY_ACTION_TYPES.CHECKOUT_GATEWAY_START, _checkoutGateway);
}

export function* gatewaySaga() {
  yield all([call(onFetchGatewayStart), call(onCreateGatewayStart), call(onCheckoutGatewayStart)]);
}
