import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";
import { createAction } from "../../utils/store.utils";

export const setProductCategories = (productCategories) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES, productCategories);

export const setProductCategory = (productCategory) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORY, productCategory);

export const setIsProductCategoriesHasMore = (isProductCategoriesHasMore) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE, isProductCategoriesHasMore);

export const setFetchProductCategoriesSearch = (fetchProductCategoriesSearch) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SEARCH, fetchProductCategoriesSearch);

export const setFetchProductCategoriesSort = (fetchProductCategoriesSort) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SORT, fetchProductCategoriesSort);

export const setFetchProductCategoriesPage = (fetchProductCategoriesPage) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PAGE, fetchProductCategoriesPage);

export const setFetchProductCategoriesPerPage = (fetchProductCategoriesPerPage) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE, fetchProductCategoriesPerPage);

export const setFetchProductCategoriesIncludes = (fetchProductCategoriesIncludes) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_INCLUDES, fetchProductCategoriesIncludes);

export const setFetchProductCategoriesFilterSectorId = (fetchProductCategoriesFilterSectorId) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID,
    fetchProductCategoriesFilterSectorId
  );

export const setFetchProductCategoriesFilterMarketId = (fetchProductCategoriesFilterMarketId) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    fetchProductCategoriesFilterMarketId
  );

export const setFetchProductCategoriesFilterBranchId = (fetchProductCategoriesFilterBranchId) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    fetchProductCategoriesFilterBranchId
  );

export const setFetchProductCategoriesLoading = (fetchProductCategoriesLoading) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING, fetchProductCategoriesLoading);

export const setFetchProductCategoriesSuccess = (fetchProductCategoriesSuccess) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS, fetchProductCategoriesSuccess);

export const setFetchProductCategoriesFailed = (fetchProductCategoriesFailed) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED, fetchProductCategoriesFailed);

export const setFetchProductCategoryLoading = (fetchProductCategoryLoading) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_LOADING, fetchProductCategoryLoading);

export const setFetchProductCategorySuccess = (fetchProductCategorySuccess) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_SUCCESS, fetchProductCategorySuccess);

export const setFetchProductCategoryFailed = (fetchProductCategoryFailed) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_FAILED, fetchProductCategoryFailed);

export const appendProductCategories = (productCategories) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES, productCategories);

export const fetchProductCategoriesStart = () =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START);

export const fetchProductCategoryStart = (id) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORY_START, id);

export const resetProductCategoryReducer = () =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.RESET_PRODUCT_CATEGORY_REDUCER);
