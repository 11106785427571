import { createSelector } from "reselect";

const marketSelector = ({ market }) => market;

export const getCountries = createSelector([marketSelector], ({ countries }) => countries);
export const getCountriesByCurrency = createSelector(
  [marketSelector],
  ({ countriesByCurrency }) => countriesByCurrency
);
export const getCountriesByPhonePrefix = createSelector(
  [marketSelector],
  ({ countriesByPhonePrefix }) => countriesByPhonePrefix
);

export const getIsModalAutoClose = createSelector([marketSelector], ({ isModalAutoClose }) => isModalAutoClose);

export const getMarkets = createSelector([marketSelector], ({ markets }) => markets);
export const getMarket = createSelector([marketSelector], ({ market }) => market);

export const getIsMarketsHasMore = createSelector([marketSelector], ({ isMarketsHasMore }) => isMarketsHasMore);

export const getFetchMarketsSearch = createSelector([marketSelector], ({ fetchMarketsSearch }) => fetchMarketsSearch);
export const getFetchMarketsPage = createSelector([marketSelector], ({ fetchMarketsPage }) => fetchMarketsPage);
export const getFetchMarketsPerPage = createSelector(
  [marketSelector],
  ({ fetchMarketsPerPage }) => fetchMarketsPerPage
);
export const getFetchMarketsIncludes = createSelector(
  [marketSelector],
  ({ fetchMarketsIncludes }) => fetchMarketsIncludes
);
export const getFetchMarketsFilterMarketId = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterMarketId }) => fetchMarketsFilterMarketId
);
export const getFetchMarketsLoading = createSelector(
  [marketSelector],
  ({ fetchMarketsLoading }) => fetchMarketsLoading
);
export const getFetchMarketsSuccess = createSelector(
  [marketSelector],
  ({ fetchMarketsSuccess }) => fetchMarketsSuccess
);
export const getFetchMarketsFailed = createSelector([marketSelector], ({ fetchMarketsFailed }) => fetchMarketsFailed);

export const getFetchMarketLoading = createSelector([marketSelector], ({ fetchMarketLoading }) => fetchMarketLoading);
export const getFetchMarketSuccess = createSelector([marketSelector], ({ fetchMarketSuccess }) => fetchMarketSuccess);
export const getFetchMarketFailed = createSelector([marketSelector], ({ fetchMarketFailed }) => fetchMarketFailed);
