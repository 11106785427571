import { APP_KEY } from "../constants/common.constant";

export const getAuthToken = () => localStorage.getItem(`${APP_KEY}:authToken`);
export const setAuthToken = (token) => {
  localStorage.setItem(`${APP_KEY}:authToken`, token);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearAuthToken = () => {
  localStorage.removeItem(`${APP_KEY}:authToken`);
  window.dispatchEvent(new Event("localStorage"));
};

export const getLazyRetry = () =>
  sessionStorage.getItem(`${APP_KEY}:lazyRetry`);
export const setLazyRetry = (lazyRetry) => {
  sessionStorage.setItem(`${APP_KEY}:lazyRetry`, lazyRetry);
  document.dispatchEvent(new Event("localStorage"));
};
export const clearLazyRetry = () => {
  sessionStorage.removeItem(`${APP_KEY}:lazyRetry`);
  window.dispatchEvent(new Event("localStorage"));
};

export const getItem = (key) => localStorage.getItem(`${APP_KEY}:${key}`);
export const setItem = (key, value) => {
  localStorage.setItem(`${APP_KEY}:${key}`, value);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearItem = (key) => {
  localStorage.removeItem(`${APP_KEY}:${key}`);
  window.dispatchEvent(new Event("localStorage"));
};
