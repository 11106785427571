import GLOBAL_ACTION_TYPES from "./global.type";

export const GLOBAL_INITIAL_STATE = {
  isRightToLeft: false,
};

export const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT:
      return { ...state, isRightToLeft: payload };

    case GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER:
      return GLOBAL_INITIAL_STATE;
    default:
      return state;
  }
};
