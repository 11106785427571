import { combineReducers } from "redux";

import { authenticationReducer } from "./authentication/authentication.reducer";
import { branchReducer } from "./branch/branch.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { componentReducer } from "./component/component.reducer";
import { customerReducer } from "./customer/customer.reducer";
import { discountReducer } from "./discount/discount.reducer";
import { gatewayReducer } from "./gateway/gateway.reducer";
import { globalReducer } from "./global/global.reducer";
import { marketReducer } from "./market/market.reducer";
import { orderReducer } from "./order/order.reducer";
import { orderProductRatingReducer } from "./order-product-rating/order-product-rating.reducer";
import { shortUrlReducer } from "./short-url/short-url.reducer";
import { summaryReducer } from "./summary/summary.reducer";
import { syncReducer } from "./synchronize/synchronize.reducer";
import { paymentMethodSettingReducer } from "./payment-method-setting/payment-method-setting.reducer";
import { productCategoryReducer } from "./product-category/product-category.reducer";
import { productReducer } from "./product/product.reducer";
import { scheduleReducer } from "./schedule/schedule.reducer";
import { tableReducer } from "./table/table.reducer";
import { verificationReducer } from "./verification/verification.reducer";
// import { tableReducer } from "./table/table.reducer";
// import { termConditionReducer } from "./term-condition/term-condition.reducer";

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  branch: branchReducer,
  cart: cartReducer,
  component: componentReducer,
  customer: customerReducer,
  discount: discountReducer,
  gateway: gatewayReducer,
  global: globalReducer,
  market: marketReducer,
  order: orderReducer,
  orderProductRating: orderProductRatingReducer,
  shortUrl: shortUrlReducer,
  summary: summaryReducer,
  sync: syncReducer,
  paymentMethodSetting: paymentMethodSettingReducer,
  productCategory: productCategoryReducer,
  product: productReducer,
  schedule: scheduleReducer,
  table: tableReducer,
  verification: verificationReducer,
  // table: tableReducer,
  // termCondition: termConditionReducer,
});
