import { SpinnerContainer } from "./spinner.style";

export const SPINNER_COLORS = {
  DARK: "DARK",
  WHITE: "WHITE",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  PRIMARY: "PRIMARY",
};

export const SPINNER_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const Spinner = ({ spinnerColor = SPINNER_COLORS.DARK, spinnerSize = SPINNER_SIZES.NORMAL, ...otherProps }) => {
  otherProps.spinnerColor = spinnerColor;
  otherProps.spinnerSize = spinnerSize;

  return <SpinnerContainer {...otherProps} />;
};

export default Spinner;
