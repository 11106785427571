import moment from "moment";

import { store } from "../store/store";

import { parseToFloat } from "./parser.utils";
import { isEmpty, isNumberInvalid } from "./validation.utils";

import { CURRENCY_SHORT } from "../constants/common.constant";

export const getFormattedValues = (inputValues = {}) => {
  const getFormattedValue = (value) => {
    const isArray = Array.isArray(value);
    const isFile = value instanceof File;
    const isObject = typeof value === "object" && !isArray && value !== null && !isFile;
    const isBoolean = typeof value === "boolean";

    if (isArray) {
      value = value.length === 0 ? null : value.map((val) => getFormattedValue(val));
    } else if (isObject) {
      value =
        Object.keys(value).length === 0
          ? null
          : Object.entries(value).reduce((newVal, [key, val]) => {
              return { ...newVal, [key]: getFormattedValue(val) };
            }, {});
    } else if (isBoolean) {
      value = value === true ? 1 : 0;
    } else {
      value = value === "" ? null : value;
    }

    return value;
  };

  return Object.keys(inputValues).reduce((newInputValues, field) => {
    return { ...newInputValues, [field]: getFormattedValue(inputValues[field]) };
  }, {});
};

export const getCurrencyFormat = (nominal) => {
  const state = store.getState();
  const currency = state?.market?.market?.setting?.currency;

  let nominalParsed = parseToFloat(nominal);

  const regExp = new RegExp("^-?\\d+(?:.\\d{0,2})?");
  nominalParsed = (`${nominalParsed}`.match(regExp)?.[0] ?? "").replace(/(\d)(?=(\d{3})+\.)/g, "$1,");

  return `${nominalParsed} ${currency ?? CURRENCY_SHORT}`;
};

export const getNumberFormat = (number) => {
  let numberParsed = parseFloat(number);
  if (isNumberInvalid(numberParsed)) numberParsed = 0;

  return `${numberParsed}`.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";
};

export const getDateTimeFormat = (milliseconds, format = "DD MMM YYYY, h:mm A", locale) =>
  ["", null, undefined].includes(milliseconds)
    ? "-"
    : ["", null, undefined].includes(locale)
    ? moment(milliseconds, "x").format(format)
    : moment(milliseconds, "x").locale(locale).format(format);

export const getSeparatedStringFormat = (string, number) => {
  if (["", null, undefined].includes(string)) return;

  let formatted = "";
  let count = 0;
  for (let i = 0; i < string.length; i++) {
    formatted += count === number ? ` ${string[i]}` : string[i];
    if (count === number) count = 0;
    count++;
  }

  return formatted;
};

export const getQueryParamsString = (params, prefix) => {
  const queries = [];
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      const paramName = prefix ? `${prefix}[${key}]` : key;
      if (value !== null && typeof value === "object") {
        queries.push(getQueryParamsString(value, paramName));
      } else {
        queries.push(`${encodeURIComponent(paramName)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return queries.join("&");
};

export const getFormattedPhone = (countries, phonePrefix, phone) => {
  const { start, max } = countries?.[phonePrefix] ?? {};

  const prefixRegex = new RegExp(`${start}`.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  const phoneNumber = `${start ?? ""}${phone.replace(prefixRegex, "")}`;
  const maxLength = !isEmpty(max) ? max + `${start ?? ""}`.length : phoneNumber.length;

  return phoneNumber.slice(0, maxLength);
};
