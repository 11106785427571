import {
  ORDER_SERVICE_TYPES,
  ORDER_SOURCES,
  ORDER_STATUSES,
} from "../../constants/order.constant";

import { getNumberFormat } from "../../utils/formatter.utils";
import { isEmpty } from "../../utils/validation.utils";

import CART_ACTION_TYPES from "./cart.type";

export const CART_INITIAL_STATE = {
  cartMarketId: "",
  cartBranchId: "",
  cartSource: ORDER_SOURCES.LANDING_WEB,
  cartType: ORDER_SERVICE_TYPES.TAKE_AWAY,
  cartStatus: ORDER_STATUSES.WAITING,
  cartTableId: "",
  cartCustomerId: "",
  cartDiscountId: "",
  cartNotes: "",
  cartProducts: [],
  cartIsIgnoreIngredient: 0,
};

const handleIncreaseUnique = (modifier) => {
  const { product_modifier_id, amount, weight } = modifier ?? {};

  let modifierUnique = `${product_modifier_id ?? ""}`;
  if (!isEmpty(amount)) modifierUnique += `-${getNumberFormat(amount)}`;
  if (!isEmpty(weight)) modifierUnique += `-${getNumberFormat(weight)}`;

  return modifierUnique;
};

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CART_ACTION_TYPES.SET_CART_MARKET_ID:
      return { ...state, cartMarketId: payload };
    case CART_ACTION_TYPES.SET_CART_BRANCH_ID:
      return { ...state, cartBranchId: payload };
    case CART_ACTION_TYPES.SET_CART_SOURCE:
      return { ...state, cartSource: payload };
    case CART_ACTION_TYPES.SET_CART_TYPE:
      return { ...state, cartType: payload };
    case CART_ACTION_TYPES.SET_CART_STATUS:
      return { ...state, cartStatus: payload };
    case CART_ACTION_TYPES.SET_CART_TABLE_ID:
      return { ...state, cartTableId: payload };
    case CART_ACTION_TYPES.SET_CART_CUSTOMER_ID:
      return { ...state, cartCustomerId: payload };
    case CART_ACTION_TYPES.SET_CART_DISCOUNT_ID:
      return { ...state, cartDiscountId: payload };
    case CART_ACTION_TYPES.SET_CART_NOTES:
      return { ...state, cartNotes: payload };
    case CART_ACTION_TYPES.SET_CART_PRODUCTS:
      return { ...state, cartProducts: payload };
    case CART_ACTION_TYPES.SET_CART_IS_IGNORE_INGREDIENT:
      return { ...state, cartIsIgnoreIngredient: payload };

    case CART_ACTION_TYPES.INCREASE_PRODUCT_QTY:
      const index = state.cartProducts.findIndex((product) => {
        const payloadUnique = payload?.modifiers?.map(handleIncreaseUnique);
        const productUnique = product?.modifiers?.map(handleIncreaseUnique);

        const payloadSorted = (payloadUnique ?? []).sort().join("");
        const productSorted = (productUnique ?? []).sort().join("");

        return (
          payload?.product_id === product?.product_id &&
          payloadSorted === productSorted
        );
      });

      const increaseProducts = [...state.cartProducts];
      if (index < 0) {
        increaseProducts.push({ ...payload, qty: 1 });
      } else {
        increaseProducts[index].qty++;
      }

      return { ...state, cartProducts: increaseProducts };
    case CART_ACTION_TYPES.DECREASE_PRODUCT_QTY:
      const decreaseProducts = [...state.cartProducts];
      if (decreaseProducts[payload].qty > 1) {
        decreaseProducts[payload].qty--;
      } else {
        decreaseProducts.splice(payload, 1);
      }

      return { ...state, cartProducts: decreaseProducts };

    case CART_ACTION_TYPES.RESET_CART_REDUCER:
      return CART_INITIAL_STATE;
    default:
      return state;
  }
};
