const GATEWAY_ACTION_TYPES = {
  RESET_GATEWAY_REDUCER: "gateway/RESET_GATEWAY_REDUCER",

  SET_GATEWAY: "gateway/SET_GATEWAY",
  SET_CREATE_GATEWAY: "gateway/SET_CREATE_GATEWAY",
  SET_CHECKOUT_GATEWAY: "gateway/SET_CHECKOUT_GATEWAY",

  SET_FETCH_GATEWAY_LOADING: "gateway/SET_FETCH_GATEWAY_LOADING",
  SET_FETCH_GATEWAY_SUCCESS: "gateway/SET_FETCH_GATEWAY_SUCCESS",
  SET_FETCH_GATEWAY_FAILED: "gateway/SET_FETCH_GATEWAY_FAILED",

  SET_CREATE_GATEWAY_LOADING: "gateway/SET_CREATE_GATEWAY_LOADING",
  SET_CREATE_GATEWAY_SUCCESS: "gateway/SET_CREATE_GATEWAY_SUCCESS",
  SET_CREATE_GATEWAY_FAILED: "gateway/SET_CREATE_GATEWAY_FAILED",

  SET_CHECKOUT_GATEWAY_LOADING: "gateway/SET_CHECKOUT_GATEWAY_LOADING",
  SET_CHECKOUT_GATEWAY_SUCCESS: "gateway/SET_CHECKOUT_GATEWAY_SUCCESS",
  SET_CHECKOUT_GATEWAY_FAILED: "gateway/SET_CHECKOUT_GATEWAY_FAILED",

  FETCH_GATEWAY_START: "gateway/FETCH_GATEWAY_START",
  CREATE_GATEWAY_START: "gateway/CREATE_GATEWAY_START",
  CHECKOUT_GATEWAY_START: "gateway/CHECKOUT_GATEWAY_START",
};

export default GATEWAY_ACTION_TYPES;
