const SCHEDULE_ACTION_TYPES = {
  RESET_SCHEDULE_REDUCER: "schedule/RESET_SCHEDULE_REDUCER",

  SET_SCHEDULES: "schedule/SET_SCHEDULES",

  SET_FETCH_SCHEDULES_INCLUDES: "schedule/SET_FETCH_SCHEDULES_INCLUDES",
  SET_FETCH_SCHEDULES_FILTER_MARKET_ID: "schedule/SET_FETCH_SCHEDULES_FILTER_MARKET_ID",
  SET_FETCH_SCHEDULES_FILTER_BRANCH_ID: "schedule/SET_FETCH_SCHEDULES_FILTER_BRANCH_ID",
  SET_FETCH_SCHEDULES_LOADING: "schedule/SET_FETCH_SCHEDULES_LOADING",
  SET_FETCH_SCHEDULES_SUCCESS: "schedule/SET_FETCH_SCHEDULES_SUCCESS",
  SET_FETCH_SCHEDULES_FAILED: "schedule/SET_FETCH_SCHEDULES_FAILED",

  FETCH_SCHEDULES_START: "schedule/FETCH_SCHEDULES_START",
};

export default SCHEDULE_ACTION_TYPES;
