import { createSelector } from "reselect";

const discountSelector = ({ discount }) => discount;

export const getDiscountTypes = createSelector([discountSelector], ({ discountTypes }) => discountTypes);

export const getDiscountValueTypes = createSelector([discountSelector], ({ discountValueTypes }) => discountValueTypes);

export const getDiscountTab = createSelector([discountSelector], ({ discountTab }) => discountTab);

export const getDiscounts = createSelector([discountSelector], ({ discounts }) => discounts);

export const getDiscount = createSelector([discountSelector], ({ discount }) => discount);

export const getVerifyCouponDiscount = createSelector(
  [discountSelector],
  ({ verifyCouponDiscount }) => verifyCouponDiscount
);

export const getIsDiscountsHasMore = createSelector([discountSelector], ({ isDiscountsHasMore }) => isDiscountsHasMore);

export const getFetchDiscountsSearch = createSelector(
  [discountSelector],
  ({ fetchDiscountsSearch }) => fetchDiscountsSearch
);

export const getFetchDiscountsPage = createSelector([discountSelector], ({ fetchDiscountsPage }) => fetchDiscountsPage);

export const getFetchDiscountsPerPage = createSelector(
  [discountSelector],
  ({ fetchDiscountsPerPage }) => fetchDiscountsPerPage
);

export const getFetchDiscountsIncludes = createSelector(
  [discountSelector],
  ({ fetchDiscountsIncludes }) => fetchDiscountsIncludes
);

export const getFetchDiscountsFilterCustomerId = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterCustomerId }) => fetchDiscountsFilterCustomerId
);

export const getFetchDiscountsFilterTypes = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterTypes }) => fetchDiscountsFilterTypes
);

export const getFetchDiscountsFilterExpiredAt = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterExpiredAt }) => fetchDiscountsFilterExpiredAt
);

export const getFetchDiscountsLoading = createSelector(
  [discountSelector],
  ({ fetchDiscountsLoading }) => fetchDiscountsLoading
);

export const getFetchDiscountsSuccess = createSelector(
  [discountSelector],
  ({ fetchDiscountsSuccess }) => fetchDiscountsSuccess
);

export const getFetchDiscountsFailed = createSelector(
  [discountSelector],
  ({ fetchDiscountsFailed }) => fetchDiscountsFailed
);

export const getFetchDiscountLoading = createSelector(
  [discountSelector],
  ({ fetchDiscountLoading }) => fetchDiscountLoading
);

export const getFetchDiscountSuccess = createSelector(
  [discountSelector],
  ({ fetchDiscountSuccess }) => fetchDiscountSuccess
);

export const getFetchDiscountFailed = createSelector(
  [discountSelector],
  ({ fetchDiscountFailed }) => fetchDiscountFailed
);

export const getVerifyCouponDiscountLoading = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountLoading }) => verifyCouponDiscountLoading
);

export const getVerifyCouponDiscountSuccess = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountSuccess }) => verifyCouponDiscountSuccess
);

export const getVerifyCouponDiscountFailed = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountFailed }) => verifyCouponDiscountFailed
);
