import moment from "moment";
import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "moment/locale/ar";

import { lazyRetry } from "./utils/common.utils";
import { isEmpty } from "./utils/validation.utils";

import { USER_ROLES } from "./constants/user.constant";

import {
  getAuthCheckLoading,
  getSignInSuccess,
  getSignOutSuccess,
  getSignUpSuccess,
  getUser,
} from "./store/authentication/authentication.selector";

import Loading from "./routes/loading/loading.route";

const ChangeProfile = lazy(() =>
  lazyRetry(() => import("./widgets/change-profile/change-profile.widget"))
);
const Dashboard = lazy(() =>
  lazyRetry(() => import("./routes/dashboard/dashboard.route"))
);
const MessagesPopup = lazy(() =>
  lazyRetry(() => import("./widgets/messages-popup/messages-popup.widget"))
);
const NotFound = lazy(() =>
  lazyRetry(() => import("./routes/not-found/not-found.route"))
);
const Order = lazy(() => lazyRetry(() => import("./routes/order/order.route")));
const GatewayRedirect = lazy(() =>
  lazyRetry(() => import("./routes/gateway-redirect/gateway-redirect.widget"))
);
const GlobalHandler = lazy(() =>
  lazyRetry(() => import("./widgets/global-handler/global-handler.widget"))
);
const GatewayCheckout = lazy(() =>
  lazyRetry(() => import("./routes/gateway-checkout/gateway-checkout.widget"))
);
const ShortUrl = lazy(() =>
  lazyRetry(() => import("./routes/short-url/short-url.route"))
);
const SignIn = lazy(() =>
  lazyRetry(() => import("./routes/sign-in/sign-in.route"))
);
const SignUp = lazy(() =>
  lazyRetry(() => import("./routes/sign-up/sign-up.route"))
);
const Toast = lazy(() =>
  lazyRetry(() => import("./components/toast/toast.component"))
);

const App = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const authenticationUser = useSelector(getUser);
  const authCheckLoading = useSelector(getAuthCheckLoading);
  const signInSuccess = useSelector(getSignInSuccess);
  const signUpSuccess = useSelector(getSignUpSuccess);
  const signOutSuccess = useSelector(getSignOutSuccess);

  const { role, lang } = authenticationUser ?? {};

  document.body.dir = i18n.dir();
  moment.locale(i18n.language);

  useEffect(() => {
    if (
      signInSuccess !== null ||
      signUpSuccess !== null ||
      signOutSuccess !== null
    ) {
      navigate(signOutSuccess !== null ? "/" : redirect ?? "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInSuccess, signUpSuccess, signOutSuccess]);

  useEffect(() => {
    if (!isEmpty(lang)) i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Suspense fallback={<Loading isRedirect={false} />}>
      <Toast />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <GlobalHandler />
              <MessagesPopup />
              <ChangeProfile />
            </>
          }
        >
          <Route index element={<Loading />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="short/:shortUrlKey" element={<ShortUrl />} />
          <Route
            path="market/:marketId/branch/:branchId/order"
            element={<Order />}
          />
          <Route
            path="payment/redirect/:gatewayId"
            element={<GatewayRedirect />}
          />
          <Route
            path="payment/checkout/:gatewayId"
            element={<GatewayCheckout />}
          />
          {authenticationUser && role === USER_ROLES.USER_CUSTOMER && (
            <Route path="dashboard" element={<Dashboard />} />
          )}
          <Route
            path="*"
            element={authCheckLoading ? <Loading /> : <NotFound />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
