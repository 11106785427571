import { takeLatest, put, all, call } from "redux-saga/effects";

import SHORT_URL_ACTION_TYPES from "./short-url.type";

import {
  setCreateShortUrl,
  setCreateShortUrlFailed,
  setCreateShortUrlLoading,
  setCreateShortUrlSuccess,
  setFetchShortUrlFailed,
  setFetchShortUrlLoading,
  setFetchShortUrlSuccess,
  setShortUrl,
} from "./short-url.action";

import { createShortUrl, getShortUrl } from "../../api/short-url.api";

export function* _getShortUrl({ payload: key }) {
  try {
    yield put(setFetchShortUrlLoading(true));

    const {
      meta: { message },
      data: shortUrl,
    } = yield call(getShortUrl, key);

    yield put(setShortUrl(shortUrl));

    yield put(setFetchShortUrlSuccess(message));
    yield put(setFetchShortUrlLoading(false));
  } catch (error) {
    yield put(setFetchShortUrlFailed(error));
    yield put(setFetchShortUrlLoading(false));
  }
}

export function* _createShortUrl({ payload: request }) {
  try {
    yield put(setCreateShortUrlLoading(true));

    const {
      meta: { message },
      data: shortUrl,
    } = yield call(createShortUrl, request);

    yield put(setCreateShortUrl(shortUrl));

    yield put(setCreateShortUrlSuccess(message));
    yield put(setCreateShortUrlLoading(false));
  } catch (error) {
    yield put(setCreateShortUrlFailed(error));
    yield put(setCreateShortUrlLoading(false));
  }
}

export function* onFetchShortUrlStart() {
  yield takeLatest(SHORT_URL_ACTION_TYPES.FETCH_SHORT_URL_START, _getShortUrl);
}

export function* onCreateShortUrlStart() {
  yield takeLatest(SHORT_URL_ACTION_TYPES.CREATE_SHORT_URL_START, _createShortUrl);
}

export function* shortUrlSaga() {
  yield all([call(onFetchShortUrlStart), call(onCreateShortUrlStart)]);
}
