import SHORT_URL_ACTION_TYPES from "./short-url.type";

export const SHORT_URL_INITIAL_STATE = {
  shortUrl: null,
  createShortUrl: null,

  fetchShortUrlLoading: false,
  fetchShortUrlSuccess: null,
  fetchShortUrlFailed: null,

  createShortUrlLoading: false,
  createShortUrlSuccess: null,
  createShortUrlFailed: null,
};

export const shortUrlReducer = (state = SHORT_URL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHORT_URL_ACTION_TYPES.SET_SHORT_URL:
      return { ...state, shortUrl: payload };
    case SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL:
      return { ...state, createShortUrl: payload };

    case SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_LOADING:
      return { ...state, fetchShortUrlLoading: payload };
    case SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_SUCCESS:
      return { ...state, fetchShortUrlSuccess: payload };
    case SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_FAILED:
      return { ...state, fetchShortUrlFailed: payload };

    case SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_LOADING:
      return { ...state, createShortUrlLoading: payload };
    case SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_SUCCESS:
      return { ...state, createShortUrlSuccess: payload };
    case SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_FAILED:
      return { ...state, createShortUrlFailed: payload };

    default:
      return state;
  }
};
