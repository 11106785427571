import VERIFICATION_ACTION_TYPES from "./verification.type";
import { createAction } from "../../utils/store.utils";

export const setSendVerificationResult = (sendVerificationResult) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_RESULT, sendVerificationResult);
export const setVerifyVerificationResult = (verifyVerificationResult) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_RESULT, verifyVerificationResult);

export const setSendVerificationLoading = (sendVerificationLoading) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_LOADING, sendVerificationLoading);
export const setSendVerificationSuccess = (sendVerificationSuccess) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_SUCCESS, sendVerificationSuccess);
export const setSendVerificationFailed = (sendVerificationFailed) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_SEND_VERIFICATION_FAILED, sendVerificationFailed);

export const setVerifyVerificationLoading = (verifyVerificationLoading) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_LOADING, verifyVerificationLoading);
export const setVerifyVerificationSuccess = (verifyVerificationSuccess) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_SUCCESS, verifyVerificationSuccess);
export const setVerifyVerificationFailed = (verifyVerificationFailed) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_VERIFY_VERIFICATION_FAILED, verifyVerificationFailed);

export const sendVerificationStart = (request) =>
  createAction(VERIFICATION_ACTION_TYPES.SEND_VERIFICATION_START, request);
export const verifyVerificationStart = (request) =>
  createAction(VERIFICATION_ACTION_TYPES.VERIFY_VERIFICATION_START, request);

export const setIsSendVerificationHitted = (isSendVerificationHitted) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_IS_SEND_VERIFICATION_HITTED, isSendVerificationHitted);
export const setIsVerifyVerificationHitted = (isVerifyVerificationHitted) =>
  createAction(VERIFICATION_ACTION_TYPES.SET_IS_VERIFY_VERIFICATION_HITTED, isVerifyVerificationHitted);

export const resetVerificationReducer = () => createAction(VERIFICATION_ACTION_TYPES.RESET_VERIFICATION_REDUCER);
