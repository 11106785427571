import { createSelector } from "reselect";

const paymentMethodSettingSelector = ({ paymentMethodSetting }) =>
  paymentMethodSetting;

export const getPaymentMethodSettings = createSelector(
  [paymentMethodSettingSelector],
  ({ paymentMethodSettings }) => paymentMethodSettings
);

export const getIsPaymentMethodSettingsHasMore = createSelector(
  [paymentMethodSettingSelector],
  ({ isPaymentMethodSettingsHasMore }) => isPaymentMethodSettingsHasMore
);

export const getFetchPaymentMethodSettingsSearch = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsSearch }) => fetchPaymentMethodSettingsSearch
);
export const getFetchPaymentMethodSettingsSort = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsSort }) => fetchPaymentMethodSettingsSort
);
export const getFetchPaymentMethodSettingsKeyBy = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsKeyBy }) => fetchPaymentMethodSettingsKeyBy
);
export const getFetchPaymentMethodSettingsPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsPage }) => fetchPaymentMethodSettingsPage
);
export const getFetchPaymentMethodSettingsPerPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsPerPage }) => fetchPaymentMethodSettingsPerPage
);
export const getFetchPaymentMethodSettingsIncludes = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsIncludes }) => fetchPaymentMethodSettingsIncludes
);
export const getFetchPaymentMethodSettingsFilterMarketId = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFilterMarketId }) =>
    fetchPaymentMethodSettingsFilterMarketId
);
export const getFetchPaymentMethodSettingsFilterPaymentMethodKeys =
  createSelector(
    [paymentMethodSettingSelector],
    ({ fetchPaymentMethodSettingsFilterPaymentMethodKeys }) =>
      fetchPaymentMethodSettingsFilterPaymentMethodKeys
  );
export const getFetchPaymentMethodSettingsFilterIsActive = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFilterIsActive }) =>
    fetchPaymentMethodSettingsFilterIsActive
);
export const getFetchPaymentMethodSettingsFilterIsPriority = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFilterIsPriority }) =>
    fetchPaymentMethodSettingsFilterIsPriority
);
export const getFetchPaymentMethodSettingsLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsLoading }) => fetchPaymentMethodSettingsLoading
);
export const getFetchPaymentMethodSettingsSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsSuccess }) => fetchPaymentMethodSettingsSuccess
);
export const getFetchPaymentMethodSettingsFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFailed }) => fetchPaymentMethodSettingsFailed
);
