import enJson from "../locales/en.json";
import arJson from "../locales/ar.json";

export const APP_KEY = "FLAVOURS_ORDER";

// * LOCAL ENV
// export const BASE_API_URL = "http://localhost:8000/api";
// export const TAP_PAYMENT_SECRET_KEY = "sk_test_TrMzepjXOv1nwsUqhDN0QFCV";
// export const TAP_PAYMENT_PUBLIC_KEY = "pk_test_qgDb7PL2THJi5YKS3s6ZWwyx";
// export const TAP_PAYMENT_MERCHANT_ID = "23573117";
// export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order-dev.flavours.sa";

// * DEV ENV
// export const BASE_API_URL = "https://api.flavours.sa/dev/api";
export const BASE_API_URL = "https://dev.flavours.sa/api";
export const TAP_PAYMENT_SECRET_KEY = "sk_test_TrMzepjXOv1nwsUqhDN0QFCV";
export const TAP_PAYMENT_PUBLIC_KEY = "pk_test_qgDb7PL2THJi5YKS3s6ZWwyx";
export const TAP_PAYMENT_MERCHANT_ID = "23573117";
export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order-dev.flavours.sa";

// * PROD ENV
// export const BASE_API_URL = "https://api.flavours.sa/pos/api";
// export const TAP_PAYMENT_SECRET_KEY = "sk_live_FKYghL1kWTD6pmoAIdjVGuyv";
// export const TAP_PAYMENT_PUBLIC_KEY = "pk_live_JMfaud12B7kIWDlxQSR0U5Tr";
// export const TAP_PAYMENT_MERCHANT_ID = "23573117";
// export const TAP_PAYMENT_MERCHANT_DOMAIN = "https://order.flavours.sa";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZmxhdm91cnMiLCJhIjoiY2xhNWlzbjR3MDh5cTN2cHRlaTl1dzB5YSJ9.l_e8qGdkNwO2TVQNre90lg";

export const CURRENCY_SHORT = "SR";

export const LANGUAGES = { EN: "en", AR: "ar" };

export const LANGUAGE_RESOURCES = [
  {
    code: LANGUAGES.EN,
    name: "English",
    json: enJson,
  },
  {
    code: LANGUAGES.AR,
    name: "العربية",
    json: arJson,
  },
];

export const CARD_MASK = {
  OTHERCARDS: [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  AMERICANEXPRESS: [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  EXPIRYDATE: [/[0-9]/, /\d/, "/", /\d/, /\d/],
  CVV: [/[0-9]/, /\d/, /\d/, /\d/],
};

export const SCREEN_BREAKPOINTS = {
  SMALL: 576,
  MEDIUM: 768,
  LARGE: 992,
  EXTRA_LARGE: 1200,
  EXTRA_EXTRA_LARGE: 1400,
};
