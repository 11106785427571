import BRANCH_ACTION_TYPES from "./branch.type";
import { createAction } from "../../utils/store.utils";

export const setBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCHES, branches);
export const setBranch = (branch) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCH, branch);

export const setIsBranchesHasMore = (isBranchesHasMore) =>
  createAction(BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE, isBranchesHasMore);

export const setFetchBranchesSearch = (fetchBranchesSearch) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH,
    fetchBranchesSearch
  );
export const setFetchBranchesKeyBy = (fetchBranchesKeyBy) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_KEY_BY,
    fetchBranchesKeyBy
  );
export const setFetchBranchesPage = (fetchBranchesPage) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE, fetchBranchesPage);
export const setFetchBranchesPerPage = (fetchBranchesPerPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE,
    fetchBranchesPerPage
  );
export const setFetchBranchesIncludes = (fetchBranchesIncludes) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES,
    fetchBranchesIncludes
  );
export const setFetchBranchesFilterMarketId = (fetchBranchesFilterMarketId) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID,
    fetchBranchesFilterMarketId
  );
export const setFetchBranchesFilterIsActive = (fetchBranchesFilterIsActive) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE,
    fetchBranchesFilterIsActive
  );
export const setFetchBranchesLoading = (fetchBranchesLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING,
    fetchBranchesLoading
  );
export const setFetchBranchesSuccess = (fetchBranchesSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS,
    fetchBranchesSuccess
  );
export const setFetchBranchesFailed = (fetchBranchesFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED,
    fetchBranchesFailed
  );

export const setFetchBranchLoading = (fetchBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING,
    fetchBranchLoading
  );
export const setFetchBranchSuccess = (fetchBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS,
    fetchBranchSuccess
  );
export const setFetchBranchFailed = (fetchBranchFailed) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED, fetchBranchFailed);

export const appendBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.APPEND_BRANCHES, branches);

export const fetchBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START);
export const fetchBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, branchId);

export const setIsFetchBranchesHitted = (isFetchBranchesHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED,
    isFetchBranchesHitted
  );
export const setIsFetchBranchHitted = (isFetchBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED,
    isFetchBranchHitted
  );

export const resetBranchReducer = () =>
  createAction(BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER);
