const COMPONENT_ACTION_TYPES = {
  SET_SCREEN_WIDTH: "component/SET_SCREEN_WIDTH",
  SET_TOPNAV_HEIGHT: "component/SET_TOPNAV_HEIGHT",

  SET_IS_SIDENAV_OPEN: "component/SET_IS_SIDENAV_OPEN",
  SET_IS_MODAL_OPEN: "component/SET_IS_MODAL_OPEN",
  SET_CURRENT_MODAL: "component/SET_CURRENT_MODAL",
};

export default COMPONENT_ACTION_TYPES;
