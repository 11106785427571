import SUMMARY_ACTION_TYPES from "./summary.type";
import { createAction } from "../../utils/store.utils";

export const setSummaryFinancialTotal = (summaryFinancialTotal) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_FINANCIAL_TOTAL, summaryFinancialTotal);

export const setFetchSummaryFinancialTotalFilterPeriodType = (fetchSummaryFinancialTotalFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE,
    fetchSummaryFinancialTotalFilterPeriodType
  );

export const setFetchSummaryFinancialTotalFilterStartAt = (fetchSummaryFinancialTotalFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT,
    fetchSummaryFinancialTotalFilterStartAt
  );

export const setFetchSummaryFinancialTotalFilterEndAt = (fetchSummaryFinancialTotalFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT,
    fetchSummaryFinancialTotalFilterEndAt
  );

export const setFetchSummaryFinancialTotalFilterCustomerId = (fetchSummaryFinancialTotalFilterCustomerId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CUSTOMER_ID,
    fetchSummaryFinancialTotalFilterCustomerId
  );

export const setFetchSummaryFinancialTotalFilterPaymentMethodKey = (fetchSummaryFinancialTotalFilterPaymentMethodKey) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY,
    fetchSummaryFinancialTotalFilterPaymentMethodKey
  );

export const setFetchSummaryFinancialTotalFilterCashierId = (fetchSummaryFinancialTotalFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID,
    fetchSummaryFinancialTotalFilterCashierId
  );

export const setFetchSummaryFinancialTotalLoading = (fetchSummaryFinancialTotalLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_LOADING, fetchSummaryFinancialTotalLoading);

export const setFetchSummaryFinancialTotalSuccess = (fetchSummaryFinancialTotalSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_SUCCESS, fetchSummaryFinancialTotalSuccess);

export const setFetchSummaryFinancialTotalFailed = (fetchSummaryFinancialTotalFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FAILED, fetchSummaryFinancialTotalFailed);

export const fetchSummaryFinancialTotalStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_FINANCIAL_TOTAL_START);

export const resetSummaryReducer = () => createAction(SUMMARY_ACTION_TYPES.RESET_SUMMARY_REDUCER);
