import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { USER_ROLES } from "../../constants/user.constant";

import {
  authCheckStart,
  setAuthCheckFailed,
  setAuthCheckSuccess,
} from "../../store/authentication/authentication.action";
import {
  getAuthCheckFailed,
  getAuthCheckSuccess,
  getUser,
} from "../../store/authentication/authentication.selector";

import Spinner, {
  SPINNER_COLORS,
} from "../../components/spinner/spinner.component";

import { LoadingContainer, LoadingLogo } from "./loading.style";

import LogoImage from "../../assets/images/logos/flavours-qr.png";

const Loading = ({ isRedirect = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authenticationUser = useSelector(getUser);
  const authCheckSuccess = useSelector(getAuthCheckSuccess);
  const authCheckFailed = useSelector(getAuthCheckFailed);

  const getTargetRoute = () => {
    const unauthenticateRoutes = ["/sign-in", "/sign-up"];
    const currentRoute = location.pathname;

    if (
      authenticationUser === null ||
      authenticationUser?.role !== USER_ROLES.USER_CUSTOMER
    ) {
      return unauthenticateRoutes.includes(currentRoute)
        ? currentRoute
        : "/sign-in";
    }

    return currentRoute === "/" ? "/dashboard" : currentRoute;
  };

  useEffect(() => {
    dispatch(authCheckStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRedirect) {
      if (authCheckSuccess !== null || authCheckFailed !== null) {
        if (authCheckSuccess !== null) dispatch(setAuthCheckSuccess(null));
        if (authCheckFailed !== null) dispatch(setAuthCheckFailed(null));
        navigate(getTargetRoute());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCheckSuccess, authCheckFailed]);

  return (
    <LoadingContainer>
      <LoadingLogo src={LogoImage} />
      <Spinner spinnerColor={SPINNER_COLORS.DARK} />
    </LoadingContainer>
  );
};

export default Loading;
