import PRODUCT_ACTION_TYPES from "./product.type";

export const PRODUCT_INITIAL_STATE = {
  products: [],
  product: null,

  isProductsHasMore: true,

  fetchProductsSearch: null,
  fetchProductsKeyBy: null,
  fetchProductsSort: null,
  fetchProductsPage: 1,
  fetchProductsPerPage: null,
  fetchProductsIncludes: null,
  fetchProductsFilterMarketId: null,
  fetchProductsFilterBranchId: null,
  fetchProductsFilterProductCategoryId: null,
  fetchProductsFilterProductsIds: null,
  fetchProductsFilterIsActive: null,
  fetchProductsLoading: false,
  fetchProductsSuccess: null,
  fetchProductsFailed: null,

  fetchProductLoading: false,
  fetchProductSuccess: null,
  fetchProductFailed: null,

  isFetchProductsHitted: false,
  isFetchProductHitted: false,
};

export const productReducer = (state = PRODUCT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_ACTION_TYPES.SET_PRODUCTS:
      return { ...state, products: payload };
    case PRODUCT_ACTION_TYPES.SET_PRODUCT:
      return { ...state, product: payload };

    case PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE:
      return { ...state, isProductsHasMore: payload };

    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SEARCH:
      return { ...state, fetchProductsSearch: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_KEY_BY:
      return { ...state, fetchProductsKeyBy: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SORT:
      return { ...state, fetchProductsSort: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PAGE:
      return { ...state, fetchProductsPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PER_PAGE:
      return { ...state, fetchProductsPerPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_INCLUDES:
      return { ...state, fetchProductsIncludes: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_MARKET_ID:
      return { ...state, fetchProductsFilterMarketId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_BRANCH_ID:
      return { ...state, fetchProductsFilterBranchId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
      return { ...state, fetchProductsFilterProductCategoryId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS:
      return { ...state, fetchProductsFilterProductsIds: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE:
      return { ...state, fetchProductsFilterIsActive: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING:
      return { ...state, fetchProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS:
      return { ...state, fetchProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED:
      return { ...state, fetchProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_LOADING:
      return { ...state, fetchProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_SUCCESS:
      return { ...state, fetchProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_FAILED:
      return { ...state, fetchProductFailed: payload };

    case PRODUCT_ACTION_TYPES.APPEND_PRODUCTS:
      return { ...state, products: [...state.products, ...payload] };

    case PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED:
      return { ...state, isFetchProductsHitted: payload };
    case PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCT_HITTED:
      return { ...state, isFetchProductHitted: payload };

    case PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER:
      return PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
