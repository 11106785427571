import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";

export const PAYMENT_METHOD_SETTING_INITIAL_STATE = {
  paymentMethodSettings: {},

  isPaymentMethodSettingsHasMore: true,

  fetchPaymentMethodSettingsSearch: null,
  fetchPaymentMethodSettingsSort: null,
  fetchPaymentMethodSettingsKeyBy: null,
  fetchPaymentMethodSettingsPage: 1,
  fetchPaymentMethodSettingsPerPage: null,
  fetchPaymentMethodSettingsIncludes: null,
  fetchPaymentMethodSettingsFilterMarketId: null,
  fetchPaymentMethodSettingsFilterPaymentMethodKeys: null,
  fetchPaymentMethodSettingsFilterIsActive: null,
  fetchPaymentMethodSettingsFilterIsPriority: null,
  fetchPaymentMethodSettingsLoading: false,
  fetchPaymentMethodSettingsSuccess: null,
  fetchPaymentMethodSettingsFailed: null,
};

export const paymentMethodSettingReducer = (
  state = PAYMENT_METHOD_SETTING_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTINGS:
      return { ...state, paymentMethodSettings: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE:
      return { ...state, isPaymentMethodSettingsHasMore: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SEARCH:
      return { ...state, fetchPaymentMethodSettingsSearch: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT:
      return { ...state, fetchPaymentMethodSettingsSort: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_KEY_BY:
      return { ...state, fetchPaymentMethodSettingsKeyBy: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE:
      return { ...state, fetchPaymentMethodSettingsPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE:
      return { ...state, fetchPaymentMethodSettingsPerPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES:
      return { ...state, fetchPaymentMethodSettingsIncludes: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
      return { ...state, fetchPaymentMethodSettingsFilterMarketId: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_PAYMENT_METHOD_KEYS:
      return {
        ...state,
        fetchPaymentMethodSettingsFilterPaymentMethodKeys: payload,
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
      return { ...state, fetchPaymentMethodSettingsFilterIsActive: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_PRIORITY:
      return { ...state, fetchPaymentMethodSettingsFilterIsPriority: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING:
      return { ...state, fetchPaymentMethodSettingsLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS:
      return { ...state, fetchPaymentMethodSettingsSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED:
      return { ...state, fetchPaymentMethodSettingsFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_PAYMENT_METHOD_SETTINGS:
      return {
        ...state,
        paymentMethodSettings: { ...state.paymentMethodSettings, ...payload },
      };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.RESET_PAYMENT_METHOD_SETTING_REDUCER:
      return PAYMENT_METHOD_SETTING_INITIAL_STATE;
    default:
      return state;
  }
};
