import BRANCH_ACTION_TYPES from "./branch.type";

export const BRANCH_INITIAL_STATE = {
  branches: [],
  branch: null,

  isBranchesHasMore: true,

  fetchBranchesSearch: null,
  fetchBranchesKeyBy: null,
  fetchBranchesPage: 1,
  fetchBranchesPerPage: null,
  fetchBranchesIncludes: null,
  fetchBranchesFilterMarketId: null,
  fetchBranchesFilterIsActive: null,
  fetchBranchesLoading: false,
  fetchBranchesSuccess: null,
  fetchBranchesFailed: null,

  fetchBranchLoading: false,
  fetchBranchSuccess: null,
  fetchBranchFailed: null,

  isFetchBranchesHitted: false,
  isFetchBranchHitted: false,
};

export const branchReducer = (state = BRANCH_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case BRANCH_ACTION_TYPES.SET_BRANCHES:
      return { ...state, branches: payload };
    case BRANCH_ACTION_TYPES.SET_BRANCH:
      return { ...state, branch: payload };

    case BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE:
      return { ...state, isBranchesHasMore: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH:
      return { ...state, fetchBranchesSearch: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_KEY_BY:
      return { ...state, fetchBranchesKeyBy: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE:
      return { ...state, fetchBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE:
      return { ...state, fetchBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES:
      return { ...state, fetchBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING:
      return { ...state, fetchBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS:
      return { ...state, fetchBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED:
      return { ...state, fetchBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING:
      return { ...state, fetchBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS:
      return { ...state, fetchBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED:
      return { ...state, fetchBranchFailed: payload };

    case BRANCH_ACTION_TYPES.APPEND_BRANCHES:
      return { ...state, branches: [...state.branches, ...payload] };

    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED:
      return { ...state, isFetchBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED:
      return { ...state, isFetchBranchHitted: payload };

    case BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER:
      return BRANCH_INITIAL_STATE;
    default:
      return state;
  }
};
