import styled, { css, keyframes } from "styled-components";
import { SPINNER_COLORS, SPINNER_SIZES } from "./spinner.component";

const spinnerAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid #000;
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${spinnerAnimation} 0.75s linear infinite;

  ${({ spinnerSize }) =>
    spinnerSize === SPINNER_SIZES.SMALL &&
    css`
      width: 1rem;
      height: 1rem;
      border-width: 0.15rem;
    `}

  ${({ spinnerColor }) =>
    spinnerColor === SPINNER_COLORS.WHITE &&
    css`
      border-color: white;
      border-right-color: transparent;
    `}

  ${({ spinnerColor }) =>
    spinnerColor === SPINNER_COLORS.DARK &&
    css`
      border-color: #0e072f;
      border-right-color: transparent;
    `}

  ${({ spinnerColor }) =>
    spinnerColor === SPINNER_COLORS.SUCCESS &&
    css`
      border-color: #008d0e;
      border-right-color: transparent;
    `}

  ${({ spinnerColor }) =>
    spinnerColor === SPINNER_COLORS.DANGER &&
    css`
      border-color: #f02c2c;
      border-right-color: transparent;
    `}

  ${({ spinnerColor }) =>
    spinnerColor === SPINNER_COLORS.PRIMARY &&
    css`
      border-color: #2900ee;
      border-right-color: transparent;
    `}
`;
