import MARKET_ACTION_TYPES from "./market.type";

export const MARKET_INITIAL_STATE = {
  countries: [
    {
      flag: "🇸🇦",
      country: "Saudi Arabia",
      phone_prefix: "966",
      start: "5",
      max: 8,
      currency: "SAR",
    },
    {
      flag: "🇦🇪",
      country: "United Arab Emirates",
      phone_prefix: "971",
      currency: "AED",
    },
    {
      flag: "🇴🇲",
      country: "Oman",
      phone_prefix: "968",
      currency: "OMR",
    },
    {
      flag: "🇰🇼",
      country: "Kuwait",
      phone_prefix: "965",
      currency: "KWD",
    },
    {
      flag: "🇶🇦",
      country: "Qatar",
      phone_prefix: "974",
      currency: "QAR",
    },
    {
      flag: "🇧🇭",
      country: "Bahrain",
      phone_prefix: "973",
      currency: "BHD",
    },
    {
      flag: "🇪🇬",
      country: "Egypt",
      phone_prefix: "20",
      currency: "EGP",
    },
    {
      flag: "🇾🇪",
      country: "Yemen",
      phone_prefix: "967",
      currency: "YER",
    },
    {
      flag: "🇮🇩",
      country: "Indonesia",
      phone_prefix: "62",
      currency: "IDR",
    },
  ],
  countriesByCurrency: {
    SAR: {
      flag: "🇸🇦",
      country: "Saudi Arabia",
      phone_prefix: "966",
      start: "5",
      max: 8,
      currency: "SAR",
    },
    AED: {
      flag: "🇦🇪",
      country: "United Arab Emirates",
      phone_prefix: "971",
      currency: "AED",
    },
    OMR: {
      flag: "🇴🇲",
      country: "Oman",
      phone_prefix: "968",
      currency: "OMR",
    },
    KWD: {
      flag: "🇰🇼",
      country: "Kuwait",
      phone_prefix: "965",
      currency: "KWD",
    },
    QAR: {
      flag: "🇶🇦",
      country: "Qatar",
      phone_prefix: "974",
      currency: "QAR",
    },
    BHD: {
      flag: "🇧🇭",
      country: "Bahrain",
      phone_prefix: "973",
      currency: "BHD",
    },
    EGP: {
      flag: "🇪🇬",
      country: "Egypt",
      phone_prefix: "20",
      currency: "EGP",
    },
    YER: {
      flag: "🇾🇪",
      country: "Yemen",
      phone_prefix: "967",
      currency: "YER",
    },
    IDR: {
      flag: "🇮🇩",
      country: "Indonesia",
      phone_prefix: "62",
      currency: "IDR",
    },
  },
  countriesByPhonePrefix: {
    966: {
      flag: "🇸🇦",
      country: "Saudi Arabia",
      phone_prefix: "966",
      start: "5",
      max: 8,
      currency: "SAR",
    },
    971: {
      flag: "🇦🇪",
      country: "United Arab Emirates",
      phone_prefix: "971",
      currency: "AED",
    },
    968: {
      flag: "🇴🇲",
      country: "Oman",
      phone_prefix: "968",
      currency: "OMR",
    },
    965: {
      flag: "🇰🇼",
      country: "Kuwait",
      phone_prefix: "965",
      currency: "KWD",
    },
    974: {
      flag: "🇶🇦",
      country: "Qatar",
      phone_prefix: "974",
      currency: "QAR",
    },
    973: {
      flag: "🇧🇭",
      country: "Bahrain",
      phone_prefix: "973",
      currency: "BHD",
    },
    20: {
      flag: "🇪🇬",
      country: "Egypt",
      phone_prefix: "20",
      currency: "EGP",
    },
    967: {
      flag: "🇾🇪",
      country: "Yemen",
      phone_prefix: "967",
      currency: "YER",
    },
    62: {
      flag: "🇮🇩",
      country: "Indonesia",
      phone_prefix: "62",
      currency: "IDR",
    },
  },
  isModalAutoClose: true,
  markets: [],
  market: null,
  isMarketsHasMore: true,
  fetchMarketsSearch: null,
  fetchMarketsPage: 1,
  fetchMarketsPerPage: null,
  fetchMarketsIncludes: null,
  fetchMarketsFilterMarketId: null,
  fetchMarketsLoading: false,
  fetchMarketsSuccess: null,
  fetchMarketsFailed: null,
  fetchMarketLoading: false,
  fetchMarketSuccess: null,
  fetchMarketFailed: null,
};

export const marketReducer = (state = MARKET_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MARKET_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };
    case MARKET_ACTION_TYPES.SET_MARKETS:
      return { ...state, markets: payload };
    case MARKET_ACTION_TYPES.SET_MARKET:
      return { ...state, market: payload };
    case MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE:
      return { ...state, isMarketsHasMore: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SEARCH:
      return { ...state, fetchMarketsSearch: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PAGE:
      return { ...state, fetchMarketsPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PER_PAGE:
      return { ...state, fetchMarketsPerPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_INCLUDES:
      return { ...state, fetchMarketsIncludes: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MARKET_ID:
      return { ...state, fetchMarketsFilterMarketId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING:
      return { ...state, fetchMarketsLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS:
      return { ...state, fetchMarketsSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED:
      return { ...state, fetchMarketsFailed: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_LOADING:
      return { ...state, fetchMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_SUCCESS:
      return { ...state, fetchMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_FAILED:
      return { ...state, fetchMarketFailed: payload };
    case MARKET_ACTION_TYPES.APPEND_MARKETS:
      return { ...state, markets: [...state.markets, ...payload] };
    case MARKET_ACTION_TYPES.RESET_MARKET_REDUCER:
      return MARKET_INITIAL_STATE;
    default:
      return state;
  }
};
