import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const LoadingLogo = styled.img`
  width: 10rem;
  object-fit: cover;
  aspect-ratio: auto;
  margin-bottom: 1.5rem;
`;
