import { createAction } from "../../utils/store.utils";

import TABLE_ACTION_TYPES from "./table.type";

export const setTable = (table) =>
  createAction(TABLE_ACTION_TYPES.SET_TABLE, table);
export const setTableByCode = (tableByCode) =>
  createAction(TABLE_ACTION_TYPES.SET_TABLE_BY_CODE, tableByCode);

export const setFetchTableLoading = (fetchTableLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_LOADING, fetchTableLoading);
export const setFetchTableSuccess = (fetchTableSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_SUCCESS, fetchTableSuccess);
export const setFetchTableFailed = (fetchTableFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_FAILED, fetchTableFailed);

export const setFetchTableByCodeMarketId = (fetchTableByCodeMarketId) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_MARKET_ID,
    fetchTableByCodeMarketId
  );
export const setFetchTableByCodeBranchId = (fetchTableByCodeBranchId) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_BRANCH_ID,
    fetchTableByCodeBranchId
  );
export const setFetchTableByCodeLoading = (fetchTableByCodeLoading) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_LOADING,
    fetchTableByCodeLoading
  );
export const setFetchTableByCodeSuccess = (fetchTableByCodeSuccess) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_SUCCESS,
    fetchTableByCodeSuccess
  );
export const setFetchTableByCodeFailed = (fetchTableByCodeFailed) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_FAILED,
    fetchTableByCodeFailed
  );

export const fetchTableStart = (tableId) =>
  createAction(TABLE_ACTION_TYPES.FETCH_TABLE_START, tableId);
export const fetchTableByCodeStart = (tableCode) =>
  createAction(TABLE_ACTION_TYPES.FETCH_TABLE_BY_CODE_START, tableCode);

export const setIsFetchTableHitted = (isFetchTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_HITTED,
    isFetchTableHitted
  );
export const setIsFetchTableByCodeHitted = (isFetchTableByCodeHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_BY_CODE_HITTED,
    isFetchTableByCodeHitted
  );

export const resetTableReducer = () =>
  createAction(TABLE_ACTION_TYPES.RESET_TABLE_REDUCER);
