import ORDER_ACTION_TYPES from "./order.type";
import { createAction } from "../../utils/store.utils";

export const setCartInputValues = (cartInputValues) =>
  createAction(ORDER_ACTION_TYPES.SET_CART_INPUT_VALUES, cartInputValues);

export const setOrders = (orders) =>
  createAction(ORDER_ACTION_TYPES.SET_ORDERS, orders);
export const setOrder = (order) =>
  createAction(ORDER_ACTION_TYPES.SET_ORDER, order);
export const setCreateOrder = (createOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER, createOrder);
export const setUpdateOrder = (updateOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER, updateOrder);
export const setCalculateOrder = (calculateOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_ORDER, calculateOrder);

export const setIsOrdersHasMore = (isOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_ORDERS_HAS_MORE, isOrdersHasMore);

export const setFetchOrdersSearch = (fetchOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SEARCH, fetchOrdersSearch);
export const setFetchOrdersKeyBy = (fetchOrdersKeyBy) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_KEY_BY, fetchOrdersKeyBy);
export const setFetchOrdersPage = (fetchOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PAGE, fetchOrdersPage);
export const setFetchOrdersPerPage = (fetchOrdersPerPage) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PER_PAGE,
    fetchOrdersPerPage
  );
export const setFetchOrdersIncludes = (fetchOrdersIncludes) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_INCLUDES,
    fetchOrdersIncludes
  );
export const setFetchOrdersFilterMarketId = (fetchOrdersFilterMarketId) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_MARKET_ID,
    fetchOrdersFilterMarketId
  );
export const setFetchOrdersFilterBranchId = (fetchOrdersFilterBranchId) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_BRANCH_ID,
    fetchOrdersFilterBranchId
  );
export const setFetchOrdersFilterCustomerId = (fetchOrdersFilterCustomerId) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CUSTOMER_ID,
    fetchOrdersFilterCustomerId
  );
export const setFetchOrdersFilterSources = (fetchOrdersFilterSources) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_SOURCES,
    fetchOrdersFilterSources
  );
export const setFetchOrdersFilterTypes = (fetchOrdersFilterTypes) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TYPES,
    fetchOrdersFilterTypes
  );
export const setFetchOrdersFilterStatuses = (fetchOrdersFilterStatuses) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_STATUSES,
    fetchOrdersFilterStatuses
  );
export const setFetchOrdersFilterCreatedAtBefore = (
  fetchOrdersFilterCreatedAtBefore
) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchOrdersFilterCreatedAtBefore
  );
export const setFetchOrdersFilterCreatedAtAfter = (
  fetchOrdersFilterCreatedAtAfter
) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchOrdersFilterCreatedAtAfter
  );
export const setFetchOrdersLoading = (fetchOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_LOADING, fetchOrdersLoading);
export const setFetchOrdersSuccess = (fetchOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SUCCESS, fetchOrdersSuccess);
export const setFetchOrdersFailed = (fetchOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FAILED, fetchOrdersFailed);

export const setFetchOrderLoading = (fetchOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_LOADING, fetchOrderLoading);
export const setFetchOrderSuccess = (fetchOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_SUCCESS, fetchOrderSuccess);
export const setFetchOrderFailed = (fetchOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_FAILED, fetchOrderFailed);

export const setCalculateOrderLoading = (calculateOrderLoading) =>
  createAction(
    ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_LOADING,
    calculateOrderLoading
  );
export const setCalculateOrderSuccess = (calculateOrderSuccess) =>
  createAction(
    ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_SUCCESS,
    calculateOrderSuccess
  );
export const setCalculateOrderFailed = (calculateOrderFailed) =>
  createAction(
    ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_FAILED,
    calculateOrderFailed
  );

export const setCreateOrderLoading = (createOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_LOADING, createOrderLoading);
export const setCreateOrderSuccess = (createOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_SUCCESS, createOrderSuccess);
export const setCreateOrderFailed = (createOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_FAILED, createOrderFailed);

export const setUpdateOrderLoading = (updateOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_LOADING, updateOrderLoading);
export const setUpdateOrderSuccess = (updateOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_SUCCESS, updateOrderSuccess);
export const setUpdateOrderFailed = (updateOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_FAILED, updateOrderFailed);

export const appendOrders = (orders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_ORDERS, orders);

export const fetchOrdersStart = () =>
  createAction(ORDER_ACTION_TYPES.FETCH_ORDERS_START);
export const fetchOrderStart = (orderId) =>
  createAction(ORDER_ACTION_TYPES.FETCH_ORDER_START, orderId);
export const calculateOrderStart = (request) =>
  createAction(ORDER_ACTION_TYPES.CALCULATE_ORDER_START, request);
export const createOrderStart = (request) =>
  createAction(ORDER_ACTION_TYPES.CREATE_ORDER_START, request);
export const updateOrderStart = (orderId, request) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_ORDER_START, { orderId, request });

export const setIsFetchOrdersHitted = (isFetchOrdersHitted) =>
  createAction(
    ORDER_ACTION_TYPES.SET_IS_FETCH_ORDERS_HITTED,
    isFetchOrdersHitted
  );
export const setIsFetchOrderHitted = (isFetchOrderHitted) =>
  createAction(
    ORDER_ACTION_TYPES.SET_IS_FETCH_ORDER_HITTED,
    isFetchOrderHitted
  );
export const setIsCalculateOrderHitted = (isCalculateOrderHitted) =>
  createAction(
    ORDER_ACTION_TYPES.SET_IS_CALCULATE_ORDER_HITTED,
    isCalculateOrderHitted
  );
export const setIsCreateOrderHitted = (isCreateOrderHitted) =>
  createAction(
    ORDER_ACTION_TYPES.SET_IS_CREATE_ORDER_HITTED,
    isCreateOrderHitted
  );
export const setIsUpdateOrderHitted = (isUpdateOrderHitted) =>
  createAction(
    ORDER_ACTION_TYPES.SET_IS_UPDATE_ORDER_HITTED,
    isUpdateOrderHitted
  );
