export const DISCOUNT_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  MODIFIERS: "modifiers",
  MODIFIERS_COUNT: "modifiersCount",
  REQUIRE_MODIFIERS: "requireModifiers",
  OBTAIN_MODIFIERS: "obtainModifiers",
  FEES: "fees",
};

export const DISCOUNT_TYPES = {
  COUPON: "COUPON",
  OFFER: "OFFER",
  PROMOTION: "PROMOTION",
};

export const DISCOUNT_VALUE_TYPES = {
  PERCENT: "PERCENT",
  NOMINAL: "NOMINAL",
};
