import SHORT_URL_ACTION_TYPES from "./short-url.type";
import { createAction } from "../../utils/store.utils";

export const setShortUrl = (shortUrl) => createAction(SHORT_URL_ACTION_TYPES.SET_SHORT_URL, shortUrl);

export const setCreateShortUrl = (createShortUrl) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL, createShortUrl);

export const setFetchShortUrlLoading = (fetchShortUrlLoading) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_LOADING, fetchShortUrlLoading);

export const setFetchShortUrlSuccess = (fetchShortUrlSuccess) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_SUCCESS, fetchShortUrlSuccess);

export const setFetchShortUrlFailed = (fetchShortUrlFailed) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_FETCH_SHORT_URL_FAILED, fetchShortUrlFailed);

export const setCreateShortUrlLoading = (createShortUrlLoading) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_LOADING, createShortUrlLoading);

export const setCreateShortUrlSuccess = (createShortUrlSuccess) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_SUCCESS, createShortUrlSuccess);

export const setCreateShortUrlFailed = (createShortUrlFailed) =>
  createAction(SHORT_URL_ACTION_TYPES.SET_CREATE_SHORT_URL_FAILED, createShortUrlFailed);

export const fetchShortUrlStart = (key) => createAction(SHORT_URL_ACTION_TYPES.FETCH_SHORT_URL_START, key);

export const createShortUrlStart = (request) => createAction(SHORT_URL_ACTION_TYPES.CREATE_SHORT_URL_START, request);
