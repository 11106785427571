import ORDER_PRODUCT_RATING_ACTION_TYPES from "./order-product-rating.type";
import { createAction } from "../../utils/store.utils";

export const setOrderProductRatings = (orderProductRatings) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.SET_ORDER_PRODUCT_RATINGS, orderProductRatings);

export const setIsOrderProductRatingsHasMore = (isOrderProductRatingsHasMore) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.SET_IS_ORDER_PRODUCT_RATINGS_HAS_MORE, isOrderProductRatingsHasMore);

export const setFetchOrderProductRatingsSearch = (fetchOrderProductRatingsSearch) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SEARCH,
    fetchOrderProductRatingsSearch
  );

export const setFetchOrderProductRatingsSort = (fetchOrderProductRatingsSort) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SORT, fetchOrderProductRatingsSort);

export const setFetchOrderProductRatingsPage = (fetchOrderProductRatingsPage) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_PAGE, fetchOrderProductRatingsPage);

export const setFetchOrderProductRatingsPerPage = (fetchOrderProductRatingsPerPage) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_PER_PAGE,
    fetchOrderProductRatingsPerPage
  );

export const setFetchOrderProductRatingsIncludes = (fetchOrderProductRatingsIncludes) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_INCLUDES,
    fetchOrderProductRatingsIncludes
  );

export const setFetchOrderProductRatingsFilterOrderId = (fetchOrderProductRatingsFilterOrderId) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_ORDER_ID,
    fetchOrderProductRatingsFilterOrderId
  );

export const setFetchOrderProductRatingsFilterProductId = (fetchOrderProductRatingsFilterProductId) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_PRODUCT_ID,
    fetchOrderProductRatingsFilterProductId
  );

export const setFetchOrderProductRatingsFilterRating = (fetchOrderProductRatingsFilterRating) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_RATING,
    fetchOrderProductRatingsFilterRating
  );

export const setFetchOrderProductRatingsFilterIsShow = (fetchOrderProductRatingsFilterIsShow) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FILTER_IS_SHOW,
    fetchOrderProductRatingsFilterIsShow
  );

export const setFetchOrderProductRatingsLoading = (fetchOrderProductRatingsLoading) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_LOADING,
    fetchOrderProductRatingsLoading
  );

export const setFetchOrderProductRatingsSuccess = (fetchOrderProductRatingsSuccess) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_SUCCESS,
    fetchOrderProductRatingsSuccess
  );

export const setFetchOrderProductRatingsFailed = (fetchOrderProductRatingsFailed) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_RATINGS_FAILED,
    fetchOrderProductRatingsFailed
  );

export const setCreateOrUpdateOrderProductRatingLoading = (createOrUpdateOrderProductRatingLoading) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_LOADING,
    createOrUpdateOrderProductRatingLoading
  );

export const setCreateOrUpdateOrderProductRatingSuccess = (createOrUpdateOrderProductRatingSuccess) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_SUCCESS,
    createOrUpdateOrderProductRatingSuccess
  );

export const setCreateOrUpdateOrderProductRatingFailed = (createOrUpdateOrderProductRatingFailed) =>
  createAction(
    ORDER_PRODUCT_RATING_ACTION_TYPES.SET_CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_FAILED,
    createOrUpdateOrderProductRatingFailed
  );

export const appendOrderProductRatings = (orderProductRatings) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.APPEND_ORDER_PRODUCT_RATINGS, orderProductRatings);

export const fetchOrderProductRatingsStart = () =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.FETCH_ORDER_PRODUCT_RATINGS_START);

export const createOrUpdateOrderProductRatingStart = (orderId, orderProductId, request) =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.CREATE_OR_UPDATE_ORDER_PRODUCT_RATING_START, {
    orderId,
    orderProductId,
    request,
  });

export const resetOrderProductRatingReducer = () =>
  createAction(ORDER_PRODUCT_RATING_ACTION_TYPES.RESET_ORDER_PRODUCT_RATING_REDUCER);
