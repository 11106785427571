import apiService from "./api";

export const doSignIn = async (request) =>
  (await apiService.post("/v2/public/authentications/sign-in/otp", request))
    .data;
export const doSignUp = async (request) =>
  (
    await apiService.post(
      "/v2/public/authentications/sign-up/customer",
      request
    )
  ).data;

export const getMe = async () => (await apiService.get("/me")).data;
export const updateMe = async (request) =>
  (await apiService.post("/me", request)).data;
