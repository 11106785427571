import SUMMARY_ACTION_TYPES from "./summary.type";

export const SUMMARY_INITIAL_STATE = {
  summaryFinancialTotal: null,

  fetchSummaryFinancialTotalFilterPeriodType: null,
  fetchSummaryFinancialTotalFilterStartAt: null,
  fetchSummaryFinancialTotalFilterEndAt: null,
  fetchSummaryFinancialTotalFilterCustomerId: null,
  fetchSummaryFinancialTotalFilterPaymentMethodKey: null,
  fetchSummaryFinancialTotalFilterCashierId: null,
  fetchSummaryFinancialTotalLoading: false,
  fetchSummaryFinancialTotalSuccess: null,
  fetchSummaryFinancialTotalFailed: null,
};

export const summaryReducer = (state = SUMMARY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_FINANCIAL_TOTAL:
      return { ...state, summaryFinancialTotal: payload };

    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryFinancialTotalFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CUSTOMER_ID:
      return { ...state, fetchSummaryFinancialTotalFilterCustomerId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummaryFinancialTotalFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
      return { ...state, fetchSummaryFinancialTotalFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
      return { ...state, fetchSummaryFinancialTotalFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
      return { ...state, fetchSummaryFinancialTotalFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_LOADING:
      return { ...state, fetchSummaryFinancialTotalLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_SUCCESS:
      return { ...state, fetchSummaryFinancialTotalSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_TOTAL_FAILED:
      return { ...state, fetchSummaryFinancialTotalFailed: payload };

    case SUMMARY_ACTION_TYPES.RESET_SUMMARY_REDUCER:
      return SUMMARY_INITIAL_STATE;
    default:
      return state;
  }
};
