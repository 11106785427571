import { createSelector } from "reselect";

const orderProductRatingSelector = ({ orderProductRating }) => orderProductRating;

export const getOrderProductRatings = createSelector(
  [orderProductRatingSelector],
  ({ orderProductRatings }) => orderProductRatings
);

export const getIsOrderProductRatingsHasMore = createSelector(
  [orderProductRatingSelector],
  ({ isOrderProductRatingsHasMore }) => isOrderProductRatingsHasMore
);

export const getFetchOrderProductRatingsSearch = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsSearch }) => fetchOrderProductRatingsSearch
);

export const getFetchOrderProductRatingsSort = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsSort }) => fetchOrderProductRatingsSort
);

export const getFetchOrderProductRatingsPage = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsPage }) => fetchOrderProductRatingsPage
);

export const getFetchOrderProductRatingsPerPage = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsPerPage }) => fetchOrderProductRatingsPerPage
);

export const getFetchOrderProductRatingsIncludes = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsIncludes }) => fetchOrderProductRatingsIncludes
);

export const getFetchOrderProductRatingsFilterOrderId = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsFilterOrderId }) => fetchOrderProductRatingsFilterOrderId
);

export const getFetchOrderProductRatingsFilterProductId = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsFilterProductId }) => fetchOrderProductRatingsFilterProductId
);

export const getFetchOrderProductRatingsFilterRating = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsFilterRating }) => fetchOrderProductRatingsFilterRating
);

export const getFetchOrderProductRatingsFilterIsShow = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsFilterIsShow }) => fetchOrderProductRatingsFilterIsShow
);

export const getFetchOrderProductRatingsLoading = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsLoading }) => fetchOrderProductRatingsLoading
);

export const getFetchOrderProductRatingsSuccess = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsSuccess }) => fetchOrderProductRatingsSuccess
);

export const getFetchOrderProductRatingsFailed = createSelector(
  [orderProductRatingSelector],
  ({ fetchOrderProductRatingsFailed }) => fetchOrderProductRatingsFailed
);

export const getCreateOrUpdateOrderProductRatingLoading = createSelector(
  [orderProductRatingSelector],
  ({ createOrUpdateOrderProductRatingLoading }) => createOrUpdateOrderProductRatingLoading
);

export const getCreateOrUpdateOrderProductRatingSuccess = createSelector(
  [orderProductRatingSelector],
  ({ createOrUpdateOrderProductRatingSuccess }) => createOrUpdateOrderProductRatingSuccess
);

export const getCreateOrUpdateOrderProductRatingFailed = createSelector(
  [orderProductRatingSelector],
  ({ createOrUpdateOrderProductRatingFailed }) => createOrUpdateOrderProductRatingFailed
);
