import SCHEDULE_ACTION_TYPES from "./schedule.type";

export const SCHEDULE_INITIAL_STATE = {
  schedules: [],

  fetchSchedulesIncludes: null,
  fetchSchedulesFilterMarketId: null,
  fetchSchedulesFilterBranchId: null,
  fetchSchedulesLoading: false,
  fetchSchedulesSuccess: null,
  fetchSchedulesFailed: null,
};

export const scheduleReducer = (state = SCHEDULE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SCHEDULE_ACTION_TYPES.SET_SCHEDULES:
      return { ...state, schedules: payload };

    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_INCLUDES:
      return { ...state, fetchSchedulesIncludes: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_MARKET_ID:
      return { ...state, fetchSchedulesFilterMarketId: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_BRANCH_ID:
      return { ...state, fetchSchedulesFilterBranchId: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_LOADING:
      return { ...state, fetchSchedulesLoading: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_SUCCESS:
      return { ...state, fetchSchedulesSuccess: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FAILED:
      return { ...state, fetchSchedulesFailed: payload };

    case SCHEDULE_ACTION_TYPES.RESET_SCHEDULE_REDUCER:
      return SCHEDULE_INITIAL_STATE;
    default:
      return state;
  }
};
