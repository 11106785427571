import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";
import { createAction } from "../../utils/store.utils";

export const setPaymentMethodSettings = (paymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTINGS,
    paymentMethodSettings
  );

export const setIsPaymentMethodSettingsHasMore = (
  isPaymentMethodSettingsHasMore
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE,
    isPaymentMethodSettingsHasMore
  );

export const setFetchPaymentMethodSettingsSearch = (
  fetchPaymentMethodSettingsSearch
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SEARCH,
    fetchPaymentMethodSettingsSearch
  );
export const setFetchPaymentMethodSettingsSort = (
  fetchPaymentMethodSettingsSort
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT,
    fetchPaymentMethodSettingsSort
  );
export const setFetchPaymentMethodSettingsKeyBy = (
  fetchPaymentMethodSettingsKeyBy
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_KEY_BY,
    fetchPaymentMethodSettingsKeyBy
  );
export const setFetchPaymentMethodSettingsPage = (
  fetchPaymentMethodSettingsPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE,
    fetchPaymentMethodSettingsPage
  );
export const setFetchPaymentMethodSettingsPerPage = (
  fetchPaymentMethodSettingsPerPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE,
    fetchPaymentMethodSettingsPerPage
  );
export const setFetchPaymentMethodSettingsIncludes = (
  fetchPaymentMethodSettingsIncludes
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES,
    fetchPaymentMethodSettingsIncludes
  );
export const setFetchPaymentMethodSettingsFilterMarketId = (
  fetchPaymentMethodSettingsFilterMarketId
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID,
    fetchPaymentMethodSettingsFilterMarketId
  );
export const setFetchPaymentMethodSettingsFilterPaymentMethodKeys = (
  fetchPaymentMethodSettingsFilterPaymentMethodKeys
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_PAYMENT_METHOD_KEYS,
    fetchPaymentMethodSettingsFilterPaymentMethodKeys
  );
export const setFetchPaymentMethodSettingsFilterIsActive = (
  fetchPaymentMethodSettingsFilterIsActive
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE,
    fetchPaymentMethodSettingsFilterIsActive
  );
export const setFetchPaymentMethodSettingsFilterIsPriority = (
  fetchPaymentMethodSettingsFilterIsPriority
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_PRIORITY,
    fetchPaymentMethodSettingsFilterIsPriority
  );
export const setFetchPaymentMethodSettingsLoading = (
  fetchPaymentMethodSettingsLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING,
    fetchPaymentMethodSettingsLoading
  );
export const setFetchPaymentMethodSettingsSuccess = (
  fetchPaymentMethodSettingsSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS,
    fetchPaymentMethodSettingsSuccess
  );
export const setFetchPaymentMethodSettingsFailed = (
  fetchPaymentMethodSettingsFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED,
    fetchPaymentMethodSettingsFailed
  );

export const appendPaymentMethodSettings = (paymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_PAYMENT_METHOD_SETTINGS,
    paymentMethodSettings
  );
export const fetchPaymentMethodSettingsStart = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTINGS_START
  );

export const resetPaymentMethodSettingReducer = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.RESET_PAYMENT_METHOD_SETTING_REDUCER
  );
