import TABLE_ACTION_TYPES from "./table.type";

export const TABLE_INITIAL_STATE = {
  table: null,
  tableByCode: null,

  fetchTableLoading: false,
  fetchTableSuccess: null,
  fetchTableFailed: null,

  fetchTableByCodeMarketId: null,
  fetchTableByCodeBranchId: null,
  fetchTableByCodeLoading: false,
  fetchTableByCodeSuccess: null,
  fetchTableByCodeFailed: null,

  isFetchTableHitted: false,
  isFetchTableByCodeHitted: false,
};

export const tableReducer = (state = TABLE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TABLE_ACTION_TYPES.SET_TABLE:
      return { ...state, table: payload };
    case TABLE_ACTION_TYPES.SET_TABLE_BY_CODE:
      return { ...state, tableByCode: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_LOADING:
      return { ...state, fetchTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_SUCCESS:
      return { ...state, fetchTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_FAILED:
      return { ...state, fetchTableFailed: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_MARKET_ID:
      return { ...state, fetchTableByCodeMarketId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_BRANCH_ID:
      return { ...state, fetchTableByCodeBranchId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_LOADING:
      return { ...state, fetchTableByCodeLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_SUCCESS:
      return { ...state, fetchTableByCodeSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_BY_CODE_FAILED:
      return { ...state, fetchTableByCodeFailed: payload };

    case TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_HITTED:
      return { ...state, isFetchTableHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_BY_CODE_HITTED:
      return { ...state, isFetchTableByCodeHitted: payload };

    case TABLE_ACTION_TYPES.RESET_TABLE_REDUCER:
      return TABLE_INITIAL_STATE;
    default:
      return state;
  }
};
